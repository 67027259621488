import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";


class StartConversation extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {

        /// URL-Params ===  :catId/:consultantId/:type

        window.kbh_selected_catId = "0";
        window.kbh_selected_consultantId = "0";
        window.kbh_selected_type = "";


        let _catId = this.props.match.params.catId; // catId is string
        if (_catId === undefined || _catId === "undefined" || _catId === "0") {
            window.kbh_selected_catId = "0";
        }
        else {
            window.kbh_selected_catId = _catId;
        }

        let _consultantId = this.props.match.params.consultantId; // consultantId is string
        if (_consultantId === undefined || _consultantId === "undefined" || _consultantId === "0") {
            window.kbh_selected_consultantId = "0";
            alert("هیچ روانشناسی انتخاب نشده و در نتیجه روند شروع جلسه کنسل میشود");
            this.props.history.push("/");
        }
        else {
            window.kbh_selected_consultantId = _consultantId;
        }

        let _type = this.props.match.params.type; // type is string
        if (_type === undefined || _type === "undefined" || _type === "0") {
            window.kbh_selected_type = "";
            alert("هیچ نوع جلسه ای انتخاب نشده و در نتیجه روند شروع جلسه کنسل میشود");
            this.props.history.push("/");
        }
        else {
            window.kbh_selected_type = _type;
        }



        if (isNullOrWhiteSpaceOrEmpty(currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings())) {
            this.props.history.push("/");
        }

        if (!this.props.accountState.currentUser) {
            this.props.history.push("/");
        }

        // start session -OR- reserve session :
        this.props.history.push("/consultant-detail/" + _consultantId + "/" + _type + "/" + _catId + "");


    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer,
});

const map_DispatchAction_to_Props = {
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(StartConversation));

