import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import * as tourService from "../../services/profile/tourService";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



import * as consultantService from "../../services/consultant/consultantService";
import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";
import { Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";



import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';

class ConsultantReserve extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            consultant: [],
            nid: "0",
            ncat: "0",
            meetingType: "-1", // 0 == message
            meetingDay: "",
            meetingHour: "",
            meetingMessage: "",
            daySteps: [],
            hourSteps: [],
            mustPay: "-1",
            isOpen: false
        };
    }


    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });



    // load ajax and get data from server
    async componentDidMount() {



        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0")
            this.props.history.push("/categories");
        this.setState({ nid: nid });


        let ncat = this.props.match.params.ncat; // ncat is string
        if (ncat === undefined || ncat === "undefined" || ncat === "") // || ncat === "0")
            this.props.history.push("/categories");
        this.setState({ ncat: ncat });


        let mtt = this.props.match.params.mtt; // mtt is string
        if (mtt === undefined || mtt === "undefined" || mtt === "-1")
            this.props.history.push("/categories");
        this.setState({ meetingType: mtt });




        store.dispatch({
            type: menuActionTypes.ConsultantResarve
        });

        this.props.startLoadingAction();


        let _showTour = false;
        console.info("1");


        const reqData = { Id: nid + "" }; // Id is string
        const _obj = await consultantService.getConsultantById(reqData);
        if (_obj.success === true) {
            if (_obj.data !== null) {
                this.setState({ consultant: _obj.data });
                console.info("1 done");
            }
            else {
                toast.error("اطلاعات روان شناس وجود ندارد");
                this.props.finishLoadingAction();
                _showTour = false;

                this.props.history.push("/categories");
            }
        }
        else {
            toast.error("اطلاعات روان شناس وجود ندارد");
            this.props.finishLoadingAction();
            _showTour = false;

            this.props.history.push("/categories");
        }


        console.info("2");


        // reserveInfo :
        const reqInfo = { SupplyId: _obj.data.userId + "", };
        const _Info = await meetingResavationService.getReserveInfo(reqInfo);
        if (_Info.success === true) {
            if (_Info.data !== null) {
                console.info("2 done");

                if (_Info.message === "9876543210") {

                    // "کاربر گرامی جهت ارسال پیام ها، لطفا آدرس ایمیل و شماره موبایل خود را در سامانه ثبت نمایید"


                    const MySwal564652 = withReactContent(Swal)
                    MySwal564652.fire({
                        title: 'ثبت آدرس ایمیل و شماره موبایل',
                        text: "کاربر گرامی جهت ارسال پیام ها، لطفا آدرس ایمیل و شماره موبایل خود را در سامانه ثبت نمایید",
                        showCancelButton: false,
                        iconColor: '#ffce46',
                        confirmButtonColor: '#489fa4',
                        cancelButtonColor: '#f44336',
                        confirmButtonText: 'ادامه میدم',
                        cancelButtonText: 'نمیپذیرم'
                    })
                        .then((result) => {
                            if (result.isConfirmed) {

                                this.props.history.push("/profile");
                                //this.props.history.push("/meetings-reserve-detail/" + _res.data.id);
                            }
                        })



                    this.props.finishLoadingAction();
                    _showTour = false;
                    return;

                }
                else {
                    this.setState({ daySteps: _Info.data.daySteps });
                    this.setState({ hourSteps: _Info.data.hourSteps });
                    this.props.finishLoadingAction();
                    _showTour = true;
                }

            }
            else {
                toast.error("اطلاعات جلسه رزرو وجود ندارد");
                this.props.finishLoadingAction();
                _showTour = false;

                this.props.history.push("/categories");
            }
        }
        else {
            toast.error("اطلاعات جلسه رزرو وجود ندارد");
            this.props.finishLoadingAction();
            _showTour = false;

            this.props.history.push("/categories");
        }

        this.props.finishLoadingAction();



        console.info("3");


        // -----------------------------
        // #TourGuid:

        //if (_showTour) {

            const reqTourData = { Id: "3", token: Math.random() }; // Id is string
            const _tourObj = await tourService.isSeenTour(reqTourData);
            if (_tourObj.success === true) {
                if (_tourObj.data !== null) {
                    console.info("3 done");
                    if (_tourObj.data.isSeen === false) {
                        this.props.openTourThree();
                    }
                }
                else {
                    this.props.openTourThree();
                }
            }
            else {
                this.props.openTourThree();
            }

        //}


        console.info("4 finish all");

        // -----------------------------



    }

    changeInputsHandle = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    creatNewMeetingHandle = async e => {
        e.preventDefault();

        if (isNullOrWhiteSpaceOrEmpty(this.state.meetingDay)) {
            toast.error("لطفا روز جلسه را انتخاب نمایید");
            return;
        }

        if (isNullOrWhiteSpaceOrEmpty(this.state.meetingHour)) {
            toast.error("لطفا ساعت جلسه را انتخاب نمایید");
            return;
        }

        if (this.state.meetingType === "-1") {
            toast.error("لطفا نوع جلسه را انتخاب نمایید");
            return;
        }

        this.props.startLoadingAction();


        const reqData = {
            NCat: this.state.ncat + "",
            SupplyId: this.state.consultant.userId + "",
            MeetingTypeId: this.state.meetingType + "",
            MeetingDay: this.state.meetingDay + "",
            MeetingHour: this.state.meetingHour + "",
            MeetingMessage: this.state.meetingMessage + "",
        };
        const _res = await meetingResavationService.creatNewResavation(reqData);
        if (_res.success === true) {

            if (_res.data.mustPay) {
                toast.error(_res.message);
                this.setState({ mustPay: _res.data.mustPay });
            }
            else {
                this.props.finishLoadingAction();



                const MySwal2 = withReactContent(Swal)
                MySwal2.fire({
                    title: 'راهنمای شروع جلسه رزرو شده',
                    text: "•	دقت فرمایید که برای برقراری تماس، دسترسی های مجاز microphone و یا camera مرورگر به حالت Allow باشد. "
                        + "\n\r"
                        + "•	رزرو جلسه شما باید به تایید روانشناس مربوطه برسد و درصورت تایید روانشناس، به دو شیوه SMS و Notification‌ اطلاع رسانی می‌شود. در صورت رد جلسه توسط روانشناس، می‌توانید جلسه دیگری را رزرو نمایید. همچنین پشتیبانی یاری‌وی برای جلسه دیگر با شما ارتباط می‌گیرد.  "
                        + "\n\r"
                        + "•	بعد از تایید روانشناس به لیست جلسات رزرو شده یا آنلاین در منو بروید و جلسه مورد نظر بالای صفحه آماده شروع از سمت شما یا روانشناس می‌باشد. "
                        + "\n\r"
                        + "•	اگر روانشناس جلسه را شروع کرد، صفحه شما زنگ می‌خورد که در صورت زدن دکمه Accept توسط شما جلسه شروع می‌شود. "
                        + "\n\r",
                    showCancelButton: false,
                    iconColor: '#ffce46',
                    confirmButtonColor: '#489fa4',
                    cancelButtonColor: '#f44336',
                    confirmButtonText: 'متوجه شدم',
                    cancelButtonText: 'نمیپذیرم'
                })
                    .then((result) => {
                        if (result.isConfirmed) {

                            this.props.history.push("/meetings-reserve-archive");
                            //this.props.history.push("/meetings-reserve-detail/" + _res.data.id);
                        }
                    })

            }

        }
        else {
            toast.error(_res.message);
        }

        this.props.finishLoadingAction();

    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>



                <div class="col-md-6 mx-auto p-0">

                    <LoadingPage />


                    <div class="setting-wrapper p-0">
                        <div class="doctor-widget in-reserve m-0 shadow-none ">
                            {/*<div class="rate-teaser">
                                <i class="fas fa-star"></i>
                                <span>
                                    {this.state.consultant.count_Like}
                                    از
                                    5
                                    امتیاز
                                </span>
                            </div>
                            <a href="#">
                                <div class="cm-teaser">
                                    <i class="fas fa-comment"></i>
                                    نظرات کاربران
                                </div>
                            </a>*/}
                            <div class="doctor-widge-wrapp">
                                <div class="row p-0 m-0">
                                    <div class="col-12 p-0">
                                        <div class="doctor-image mx-auto" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(this.state.consultant.avatar) + ")" }} >
                                        </div>
                                        <h2>
                                            {!isNullOrWhiteSpaceOrEmpty(this.state.consultant.title) &&
                                                <span>
                                                    {this.state.consultant.title + " "}
                                                </span>
                                            }
                                            {this.state.consultant.firstName + " "}
                                            {this.state.consultant.lastName + " "}
                                        </h2>
                                        <div class="parvane-no">
                                            <p>شماره پروانه :
                                                <span>
                                                    {this.state.consultant.licenseNumber}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="doctor-widget__body w-100">
                                            <p>
                                                {this.state.consultant.summary}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form class="p-3 in-reserve" onSubmit={this.creatNewMeetingHandle}>
                            <div class="form-group">
                                <label for="slct3">نوع جلسه انتخاب شده : &nbsp;</label>
                                {this.state.meetingType === "0" &&
                                    <span>
                                        چت
                                    </span>
                                }
                                {this.state.meetingType === "1" &&
                                    <span>
                                        تماس صوتی
                                    </span>
                                }
                                {this.state.meetingType === "2" &&
                                    <span>
                                        تماس تصویری
                                    </span>
                                }
                                <hr />
                                <label for="exampleFormControlTextarea1">سوال :</label>
                                <textarea data-tour="step-8" class="form-control" id="meetingMessage" name="meetingMessage" rows="3" placeholder="پرکردن این فیلد اختیاری است" value={this.state.meetingMessage} onChange={this.changeInputsHandle}></textarea>
                            </div>
                            <div class="form-group">
                                <label for="slct1">
                                    انتخاب روز :

                                    <div style={{ background: "#bbdbde", borderRadius: "4px", color: "rgb(9 34 36)", textAlign: "center", fontSize: "11px", padding: "10px 5px", margin: "5px 0", }}>
                                        (توجه!! روز بر اساس منطقه زمانی خودتان هنگام ثبت نام توسط شما می باشد)
                                    </div>
                                </label>
                                <select data-tour="step-9" class="form-control" id="meetingDay" name="meetingDay" onChange={this.changeInputsHandle}>
                                    <option key="-1" value="">
                                        انتخاب کنید ...
                                    </option>
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                    {this.state.daySteps.map(item => (
                                        <option className=" miladi-date" key={item.id} value={item.id}>
                                            {item.value}
                                        </option>
                                    ))}
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="slct2">
                                    <div className="d-flex justify-content-between">
                                        <span>
                                            انتخاب ساعت :

                                        </span>
                                        <span>
                                            <>

                                                <Button data-tour="step-6" onClick={this.openModal}
                                                    className="btn bg-transparent border-0 outline-0 btn-sm btn-info-tooltip p-0 m-0 rounded text-dark">
                                                    ویدیو راهنما
                                                    <i className="fas fa-question-circle"></i>
                                                </Button>
                                                <Modal show={this.state.isOpen} onHide={this.closeModal} centered>
                                                    <Modal.Body className="d-flex justify-content-center p-0">
                                                        <iframe height="300px" width="100%" classNAme="rounded-lg shadow-xl w-100" src="https://www.youtube.com/embed/RLQB66qNfVs" allowfullscreen></iframe>
                                                    </Modal.Body>
                                                </Modal>
                                            </>
                                        </span>
                                        {/* <OverlayTrigger
                                        overlay={<Tooltip id="tooltip-info">
                                            شما یکی از بازه های ۳ ساعته را به روانشناس جهت رزرو جلسه ارائه می دهید تا روانشناس از آن ۳ ساعت یک ساعت را انتخاب کند. برای مثال شما بازه ۶ تا ۹ را انتخاب کردین و روانشناس ۳ گزینه برای تایید رزرو دارد، ساعت ۶ یا ۷ یا ۸ و به مدت ۴۵ دقیقه
                                        </Tooltip>}>
                                                                                <span>
                                                                                    <button style={{ pointerEvents: 'none' }} data-tour="step-6"
                                                                                            className="btn btn-sm btn-info-tooltip p-0 m-0 rounded">
                                            راهنما
                                                                                    <i className="fas fa-question-circle"></i>
                                                                                    </button>
                                                                                </span>
                                    </OverlayTrigger> */}
                                    </div>
                                    <div style={{ background: "#bbdbde", borderRadius: "4px", color: "rgb(9 34 36)", textAlign: "center", fontSize: "11px", padding: "10px 5px", margin: "5px 0", }}>
                                        (توجه!! ساعت بر اساس منطقه زمانی خودتان هنگام ثبت نام توسط شما می باشد)
                                    </div>
                                </label>
                                <select data-tour="step-10" class="form-control" id="meetingHour" name="meetingHour" onChange={this.changeInputsHandle}>
                                    <option key="-1" value="">
                                        انتخاب کنید ...
                                    </option>
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                    {this.state.hourSteps.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {item.value}
                                        </option>
                                    ))}
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                </select>
                            </div>
                            { /* <div class="form-group">
                                <label for="slct3"> انتخاب نوع جلسه </label>
                                 <select class="form-control" id="meetingType" name="meetingType" onChange={this.changeInputsHandle}  value={this.state.meetingType} >
                                    <option value="-1">
                                        انتخاب کنید ...
                                    </option>
                                    <option value="0">
                                        چت
                                    </option>
                                    <option value="1">
                                        تماس صوتی
                                    </option>
                                    <option value="2">
                                        تماس تصویری
                                    </option>
                                </select> 
                            </div> */}


                            {(this.state.mustPay !== "-1") &&
                                < div className="alert alert-light" role="alert" style={{ backgroundColor: "#ffdbdb" }}>
                                    برای تشکیل جلسه مورد نظر، مبلغ '{this.state.mustPay}' دلار کم دارید که باید حساب خود را شارژ کنید
                                    <Link className="btn btn-primary w-100 rounded" to={"/transaction/" + this.state.mustPay} >پرداخت</Link>
                                </div>
                            }


                            <button class="btn btn-start-consult rounded">
                                ثبت رزرو
                            </button>

                        </form>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(ConsultantReserve));
