import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import * as profileService from "../../services/profile/profileService";
import { refreshTokenAction } from "../../redux/actions/accountActions";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import * as transactionService from "../../services/transaction/transactionService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";


import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";
import { Button, Modal } from "react-bootstrap";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';


class Transaction extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            packeges: [],
            transactions: [],
            pageSize: 10,
            currentPage: 1,
            userCredit: "-",
            realAmount: 0,
            packageName: "-",
            selectedPackageId: 0,
            isLoading: false,
            mpay: "",
            mpayBackup: "",
            finalPayAmount: 0,
            discountCode: "",
            infoDialog: false,
            displayArr: "none",
            oppppDisplayArr: "block",
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        // ----------------------------------------------------------------------------
        let mpay = this.props.match.params.mpay; // mpay is string
        if (mpay === undefined || mpay === "undefined" || mpay.trim() === "" || mpay === "0") {
            this.setState({
                finalPayAmount: 0,
                mpayBackup: "",
                mpay: "",
                realAmount: 0,
                selectedPackageId: 0
            });
        }
        else {
            this.setState({
                finalPayAmount: mpay,
                mpayBackup: mpay,
                mpay: mpay,
                realAmount: mpay,
                selectedPackageId: 0
            });
        }
        // ----------------------------------------------------------------------------


        if (this.props.accountState.currentUser) {
            this.setState({ userCredit: this.props.accountState.currentUser.UserPriceCredit });
        }


        store.dispatch({
            type: menuActionTypes.Transaction
        });


        this.props.startLoadingAction();


        const p_List = await transactionService.getPackageList();
        if (p_List.success === true)
            if (p_List.data !== null)
                this.setState({ packeges: p_List.data });


        const t_List = await transactionService.getTransactionList();
        if (t_List.success === true)
            if (t_List.data !== null) {

                if (t_List.message === "9876543210") {

                    // "کاربر گرامی جهت ارسال پیام ها، لطفا آدرس ایمیل و شماره موبایل خود را در سامانه ثبت نمایید"


                    const MySwal56AS52 = withReactContent(Swal)
                    MySwal56AS52.fire({
                        title: 'ثبت آدرس ایمیل و شماره موبایل',
                        text: "کاربر گرامی جهت ارسال پیام ها، لطفا آدرس ایمیل و شماره موبایل خود را در سامانه ثبت نمایید",
                        showCancelButton: false,
                        iconColor: '#ffce46',
                        confirmButtonColor: '#489fa4',
                        cancelButtonColor: '#f44336',
                        confirmButtonText: 'ادامه میدم',
                        cancelButtonText: 'نمیپذیرم'
                    })
                        .then((result) => {
                            if (result.isConfirmed) {

                                this.props.history.push("/profile");
                                //this.props.history.push("/meetings-reserve-detail/" + _res.data.id);
                            }
                        })

                    this.props.finishLoadingAction();
                    //return;

                }
                else {

                    this.setState({ transactions: t_List.data });

                }
            }

        this.props.finishLoadingAction();


    }


    changePackageHandle = async (packageId, packageRealAmount, packageShowAmount) => {
        this.setState({
            mpay: "",
            realAmount: packageShowAmount,
            finalPayAmount: packageRealAmount,
            selectedPackageId: packageId,
            discountCode: ""
        });
    };


    onSubmitPaymentHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        this.setState({ isLoading: true });

        let isPaid = false;
        let newUptCurrUser = null;
        const reqData = {
            Amount: this.state.realAmount + "",
            FinalAmount: this.state.finalPayAmount + "",
            PackageId: this.state.selectedPackageId,
            PayType: 10558044, //SandBox
            DiscountCode: this.state.discountCode + "",
        };

        const _paiyed = await transactionService.payAmount(reqData);
        if (_paiyed.success === true) {
            isPaid = await this.props.refreshTokenAction();
            if (isPaid) {
                newUptCurrUser = currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
                if (newUptCurrUser !== null) {
                    this.props.finishLoadingAction();

                    window.location.href = _paiyed.data.approveLink;
                }
            }
            //this.props.history.push("/payment-succeed");
        }
        else {
            toast.error(_paiyed.message);
        }

        this.setState({ isLoading: false });
        this.props.finishLoadingAction();

    }



    //checkDiscountCode
    checkDiscountCode = async () => {
        this.props.startLoadingAction();

        const reqData = {
            Amount: this.state.realAmount + "",
            DiscountCode: this.state.discountCode + "",
        };

        const _checked = await transactionService.checkDiscountCode(reqData);
        if (_checked.success === true) {
            this.setState({
                finalPayAmount: _checked.data.finalPayAmount
            });
        }
        else {
            toast.error(_checked.message);
        }

        this.props.finishLoadingAction();

    }

    openModal = () => this.setState({ infoDialog: true });
    closeModal = () => this.setState({ infoDialog: false });

    keysHandle = e => {
        this.setState({
            mpay: "",
            selectedPackageId: 0,
            discountCode: ""
        });
        if (this.state.mpayBackup === e.target.value) {
            this.setState({
                mpay: e.target.value
            });
        }
        //alert("keysHandle");
    }

    changeInputsHandle = e => {
        this.setState({
            realAmount: e.target.value,
            finalPayAmount: e.target.value,
            discountCode: "",
            mpay: ""
        });
        if (this.state.mpayBackup === e.target.value) {
            this.setState({
                mpay: e.target.value
            });
        }
        //alert("changeInputsHandle");
    }


    changeInputsHandle2 = e => {
        this.setState({
            discountCode: e.target.value,
        });
        //alert("changeInputsHandle2");
    }





    showAllListTrn = () => {
        this.setState({ displayArr: "block" });
        this.setState({ oppppDisplayArr: "none" });
    }




    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div className="doctor-single-page-wrapper w-100">
                    <LoadingPage />

                    <div class="wallet-page">
                        <div class="row p-0 m-0">
                            <div class="col-md-6 px-2 mb-2">

                                <div class="pay-card">
                                    <div class="w-100">
                                        <div class="current-money">
                                            <p>اعتبار شما</p>
                                            <h4 style={{ fontFamily: "iranyekan" }}>
                                                {parseFloat(this.state.userCredit).toFixed(2)}
                                                &nbsp;
                                                دلار
                                            </h4>
                                        </div>
                                        <div class="quickLinksWrap w-100">
                                            {/* <form class="quickPayLinks" action="https://www.paypal.com/us/cgi-bin/webscr" method="post"> */}
                                            <form class="quickPayLinks w-100" onSubmit={this.onSubmitPaymentHandle}>

                                                <div className="doctor-single-page-plans h-100 p-0 m-0" style={{ boxShadow: "0" }}>

                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                    {this.state.packeges.map(item => (
                                                        <div
                                                            className={this.state.selectedPackageId === item.id ? "consult-plans active anlytc-package-id-" + item.id + "" : "consult-plans"}
                                                            data-name="{item.id}"
                                                            onClick={() => this.changePackageHandle(item.id, item.realAmount, item.showAmount)}
                                                            style={{ cursor: "pointer" }}
                                                            key={item.id}
                                                        >

                                                            <div className="plan-type">
                                                                <i className="fas fa-check"></i>
                                                                <span>
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                            <div className="plan-price">
                                                                <span style={{ color: "red", textDecoration: "line-through", fontFamily: "iranyekan", direction: "rtl" }}>
                                                                    {item.showAmount} دلار
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <span style={{ color: "green", fontWeight: "500", fontFamily: "iranyekan", direction: "rtl" }}>
                                                                    {item.realAmount} دلار
                                                                </span>
                                                            </div>
                                                            <p>
                                                                {item.description}
                                                            </p>

                                                        </div>
                                                    ))}
                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}

                                                </div>


                                                <p>مبلغ افزایش شارژ :</p>
                                                <input type="text" class="wallet-form" onChange={this.changeInputsHandle} onKeyPress={this.keysHandle} onKeyDown={this.keysHandle} onKeyUp={this.keysHandle} value={this.state.realAmount} placeholder="" />

                                                <div className="d-flex justify-content-center" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                                                    {(this.state.selectedPackageId === 0)
                                                        ?
                                                        <div className="w-100">
                                                            {/* {(this.state.mpay !== "")
                                                                ?
                                                                <> */}
                                                            <div className="w-100 position-relative col-12 px-0">
                                                                <p style={{ fontSize: "11px" }}>
                                                                    در صورت داشتن کد تخفیف آن را در کادر مربوطه وارد کنید.
                                                                </p>
                                                            </div>
                                                            <div className="row p-0 m-0 takhfif-holder w-100">
                                                                <div className="col-12 pr-0 pl-2">
                                                                    <input
                                                                        type="text"
                                                                        className="wallet-form test-right"
                                                                        onChange={this.changeInputsHandle2}
                                                                        value={this.state.discountCode}
                                                                        placeholder="کد تخفیف"
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.checkDiscountCode}
                                                                        className="btn btn-reserve-offline rounded mt-0"
                                                                        style={{ width: "70px" }}
                                                                    >
                                                                        اعمال
                                                                    </button>
                                                                </div>
                                                                {/*<div className="col-6 pl-0 pr-2 blur-it">*/}
                                                                {/*    <input*/}
                                                                {/*        type="text"*/}
                                                                {/*        className="wallet-form test-right"*/}
                                                                {/*        placeholder="کد معرف"*/}
                                                                {/*    />*/}
                                                                {/*    <button type="button"*/}
                                                                {/*        className="btn btn-reserve-offline rounded mt-0"*/}
                                                                {/*        style={{ width: "70px" }}*/}
                                                                {/*    >*/}
                                                                {/*        اعمال*/}
                                                                {/*    </button>*/}
                                                                {/*</div>*/}
                                                            </div>


                                                            {/* </>
                                                                :
                                                                <>
                                                                    <input type="text" class="wallet-form" placeholder="" value={this.state.discountCode} disabled />
                                                                    <button type="button" class="btn btn-reserve-offline rounded mt-0" style={{ width: "70px" }} disabled >
                                                                        اعمال
                                                                    </button>
                                                                </>
                                                            } */}
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="w-100">
                                                                {/* {(this.state.mpay !== "")
                                                                ?
                                                                <> */}
                                                                <div className="w-100 position-relative col-12 px-0">
                                                                    <p style={{ fontSize: "11px" }}>
                                                                        در صورت داشتن کد تخفیف یا معرف آن را در کادر مربوطه وارد کنید.
                                                                    </p>
                                                                </div>
                                                                <div className="row p-0 m-0 takhfif-holder w-100">
                                                                    <div className="col-6 pr-0 pl-2 blur-it">
                                                                        <input
                                                                            type="text"
                                                                            className="wallet-form test-right"
                                                                            placeholder="کد تخفیف"
                                                                            disabled
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-reserve-offline rounded mt-0"
                                                                            style={{ width: "70px" }}
                                                                            disabled
                                                                        >
                                                                            اعمال
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-6 pl-0 pr-2 blur-it">
                                                                        <input
                                                                            type="text"
                                                                            className="wallet-form test-right"
                                                                            placeholder="کد معرف"
                                                                            disabled
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-reserve-offline rounded mt-0"
                                                                            style={{ width: "70px" }}
                                                                            disabled
                                                                        >
                                                                            اعمال
                                                                        </button>
                                                                    </div>
                                                                </div>


                                                                {/* </>
                                                                :
                                                                <>
                                                                    <input type="text" class="wallet-form" placeholder="" value={this.state.discountCode} disabled />
                                                                    <button type="button" class="btn btn-reserve-offline rounded mt-0" style={{ width: "70px" }} disabled >
                                                                        اعمال
                                                                    </button>
                                                                </>
                                                            } */}
                                                            </div>

                                                        </>
                                                    }

                                                </div>

                                                <Modal show={this.state.infoDialog} onHide={this.closeModal} centered>
                                                    <Modal.Body className="d-flex justify-content-center p-0">
                                                        <div className="row flex-column info-modal">
                                                            <i className="fas fa-info"></i>
                                                            <p>شما به پرتال پی پال شرکت رویال بازار( پلتفرم یاری وی) واقع در کشور ژاپن متصل می شوید.</p>
                                                            <button onClick={this.onSubmitPaymentHandle}
                                                                className="btn btn-start-consult rounded mt-2  anlytc-final-pay">
                                                                <span> پرداخت نهایی به مبلغ &nbsp; {this.state.finalPayAmount} دلار </span>
                                                            </button>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>

                                                <span onClick={this.openModal} class="btn btn-start-consult rounded mt-2  anlytc-final-pay">
                                                    <span> پرداخت نهایی به مبلغ &nbsp; {this.state.finalPayAmount} دلار </span>
                                                </span>

                                                {(this.state.isLoading)
                                                    ?
                                                    (<div>
                                                        <br />
                                                        در حال اتصال به درگاه PayPal هستید، لطفا شکیبا باشید ...
                                                    </div>)
                                                    :
                                                    (<div></div>)
                                                }

                                            </form>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 px-2 mb-2">

                                <div class="transaction-wrapper">

                                    <p class="transaction-head">
                                    </p>




                                    {this.state.transactions.length === 0
                                        ?
                                        (
                                            <>
                                                <div className="col-12 mx-auto">
                                                    <br />
                                                    <center > هیچ رکورد مالی وجود ندارد </center>
                                                    <br />
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            <>

                                                <ul class="transaction-list">

                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                    {this.state.transactions.map((item, index) => (
                                                        <>
                                                            {index < 5
                                                                ?
                                                                <li key={item.id}>
                                                                    <p>
                                                                        {item.description}

                                                                        {
                                                                            (item.isCharge)
                                                                                ?
                                                                                (
                                                                                    <span class="added" style={{ fontFamily: "iranyekan", direction: "rtl" }}>
                                                                                        {item.amount}+ دلار
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <span class="minus" style={{ fontFamily: "iranyekan", direction: "rtl" }}>
                                                                                        {item.amount}- دلار
                                                                                    </span>
                                                                                )
                                                                        }
                                                                        {!item.peymentType_Status_FinallyPaiyed &&
                                                                            <>
                                                                                <span className="btn btn-danger btn-sm" style={{ fontSize: "10px", margin: "0 4px" }}>
                                                                                    تراکنش ناموفق
                                                                                </span>
                                                                            </>
                                                                        }
                                                                    </p>
                                                                    <div class="transaction-date">
                                                                        {item.tranactionDateTime_String_Miladi}
                                                                    </div>
                                                                </li>
                                                                :
                                                                <li key={item.id} style={{ display: this.state.displayArr }} >
                                                                    <p>
                                                                        {item.description}

                                                                        {
                                                                            (item.isCharge)
                                                                                ?
                                                                                (
                                                                                    <span class="added" style={{ fontFamily: "iranyekan", direction: "rtl" }}>
                                                                                        {item.amount}+ دلار
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <span class="minus" style={{ fontFamily: "iranyekan", direction: "rtl" }}>
                                                                                        {item.amount}- دلار
                                                                                    </span>
                                                                                )
                                                                        }
                                                                        {!item.peymentType_Status_FinallyPaiyed &&
                                                                            <>
                                                                                <span className="btn btn-danger btn-sm" style={{ fontSize: "10px", margin: "0 4px" }}>
                                                                                    تراکنش ناموفق
                                                                                </span>
                                                                            </>
                                                                        }
                                                                    </p>
                                                                    <div class="transaction-date">
                                                                        {item.tranactionDateTime_String_Miladi}
                                                                    </div>
                                                                </li>
                                                            }
                                                        </>
                                                    ))}
                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}

                                                </ul>

                                                <apan className="text-center mt-3" style={{ cursor: "pointer", display: this.state.oppppDisplayArr }} onClick={this.showAllListTrn}>نمایش لیست کامل</apan>

                                            </>
                                        )
                                    }




                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    loadingState: state.loadingReducer

});

// this.props.refreshTokenAction()
const map_DispatchAction_to_Props = {
    refreshTokenAction,
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Transaction));

