import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import loadingPic from "../../theme/assets/images/loading-gif.gif";

class loadingPage extends Component {
    constructor(props) {
        super(props);
    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        return (
            <React.Fragment>

                {this.props.loadingState.isLoading &&
                    <div className="spinner-wrapper w-100 d-flex flex-column align-items-center justify-content-center" style={{ height: "calc( 100vh - 60px )", position: "absolute", backgroundColor: "#ddddddcc", zIndex: "999999999" }}>
                        <img src={loadingPic} />
                    </div>
                }

            </React.Fragment>
        );
    }
}

// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(loadingPage);
