import axios from "axios";

export async function HttpGet(url, params) {

    try {
        const { data: response } = await axios({
            method: 'get',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            //baseURL: 'baseURL',
            url: url,
            //data: { data1: 222,  data2: 'aaa' },
            params: params, // { param1: 222, param2: 'aaa' }
            responseType: 'json', // default == 'json' ------ other response types: 'arraybuffer', 'document', 'json', 'text', 'stream', 'blob'
            responseEncoding: 'utf8', // default == 'utf8'
            withCredentials: true, // default == false
            //auth: {
            //    username: 'username',
            //    password: 'password'
            //},
            //proxy: {
            //    host: '127.0.0.1',
            //    port: 9000,
            //    auth: {
            //        username: 'username',
            //        password: 'password'
            //    }
            //},
            //httpAgent: new http.Agent({ keepAlive: true }),
            //httpsAgent: new https.Agent({ keepAlive: true }),
        });

        if (response.success)
            return { data: response.data, success: true, message: response.resultMessage };

        return { data: null, success: false, message: response.errorMessage };
    }
    catch (error) {
        return { data: null, success: false, message: error.message };
    }
}

export async function HttpPost(url, data) {

    try {
        const { data: response } = await axios({
            method: 'post',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            //baseURL: 'baseURL',
            url: url,
            data: data, // { data1: 222,  data2: 'aaa' }
            //params: { param1: 222, param2: 'aaa' },
            responseType: 'json', // default == 'json' ------ other response types: 'arraybuffer', 'document', 'json', 'text', 'stream', 'blob'
            responseEncoding: 'utf8', // default == 'utf8'
            withCredentials: true, // default == false
            //auth: {
            //    username: 'username',
            //    password: 'password'
            //},
            //proxy: {
            //    host: '127.0.0.1',
            //    port: 9000,
            //    auth: {
            //        username: 'username',
            //        password: 'password'
            //    }
            //},
            //httpAgent: new http.Agent({ keepAlive: true }),
            //httpsAgent: new https.Agent({ keepAlive: true }),
        });

        if (response.success)
            return { data: response.data, success: true, message: response.resultMessage };

        return { data: null, success: false, message: response.errorMessage };
    }
    catch (error) {
        return { data: null, success: false, message: error.message };
    }
}

export async function HttpPost_MultiPartContent(url, data) {

    try {
        const { data: response } = await axios({
            method: 'post',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
            },
            //baseURL: 'baseURL',
            url: url,
            data: data, // { data1: 222,  data2: 'aaa' }
            //params: { param1: 222, param2: 'aaa' },
            responseType: 'json', // default == 'json' ------ other response types: 'arraybuffer', 'document', 'json', 'text', 'stream', 'blob'
            responseEncoding: 'utf8', // default == 'utf8'
            withCredentials: true, // default == false
            //auth: {
            //    username: 'username',
            //    password: 'password'
            //},
            //proxy: {
            //    host: '127.0.0.1',
            //    port: 9000,
            //    auth: {
            //        username: 'username',
            //        password: 'password'
            //    }
            //},
            //httpAgent: new http.Agent({ keepAlive: true }),
            //httpsAgent: new https.Agent({ keepAlive: true }),
        });

        if (response.success)
            return { data: response.data, success: true, message: response.resultMessage };

        return { data: null, success: false, message: response.errorMessage };
    }
    catch (error) {
        return { data: null, success: false, message: error.message };
    }
}
