import * as baseUrlAddress from "./baseUrlAddress";

export const meetingList = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/GetList";
export const meetingOnlineList = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/GetOnlineList";
export const meetingCreatNew = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/CreatNew";
export const meetingUpdateCCId = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/UpdateCCId";
export const meetingUpdateCurrent = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/UpdateCurrent";
export const checkIsMeeting = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/CheckIsMeeting";
export const checkAllIsMeeting = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/CheckAllIsMeeting";
export const deleteMetting = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/DeleteMetting";
export const hasSurvey = baseUrlAddress.getApiBaseUrl() + "/DemandMeetings/HasSurvey";



export const meetingResavationGetList = baseUrlAddress.getApiBaseUrl() + "/DemandMeetingResavations/GetList";
export const evaluationList = baseUrlAddress.getApiBaseUrl() + "/DemandMeetingResavations/GetEvaluationList";
export const meetingResavationGetListByStartId = baseUrlAddress.getApiBaseUrl() + "/DemandMeetingResavations/GetListByStartId";
export const meetingResavationGetReserveInfo = baseUrlAddress.getApiBaseUrl() + "/DemandMeetingResavations/GetReserveInfo";
export const meetingResavationCreateNew = baseUrlAddress.getApiBaseUrl() + "/DemandMeetingResavations/CreateNew";
export const deleteResvedMetting = baseUrlAddress.getApiBaseUrl() + "/DemandMeetingResavations/DeleteReserve";
