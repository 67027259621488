import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import * as accountService from "../../services/account/accountService";
import headerPic from "../../theme/assets/images/logo.png";

//import ccAuthService from '../../services/cc-message/auth-service';

import 'react-phone-number-input/style.css';
// 1 : all complete component :
//import PhoneInput_All from 'react-phone-number-input';
// 2 : only numberPhone input :
//import PhoneInput from 'react-phone-number-input/input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
//import labelsEN from 'react-phone-number-input/locale/en.json';

// better : https://www.npmjs.com/package/react-phone-input-2

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingAccount from "./loadingAccount";


import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';
import { node } from "prop-types";


class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            userMobile: "",
            userEmail: "",
            isEmailOrMobile: "Email", // "Mobile"
            password: "",
            firstname: "",
            lastname: "",
            countryNumber: "",
            countryName: "",
            country: "",
            isAcceptedRules: false,
            timeZoneName: "",
            isShowTimezone: false,
            Fa_TimeZones: [],
            Fa_labels: [],
            All_labels: [],
            introducerCode: "",
        };

        this.firstname = React.createRef();
        this.lastname = React.createRef();
        this.username = React.createRef();
        this.password = React.createRef();
        this.introducerCode = React.createRef();
        this.userEmail = React.createRef();
        //this.isAcceptedRules = React.createRef();
        //this.countryNumber = React.createRef();

    }


    // load ajax and get data from server
    async componentDidMount() {

        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0") {
            this.setState({ introducerCode: "" });
        }
        else {
            this.setState({ introducerCode: nid });
        }

        this.props.startLoadingAction();

        const _List = await accountService.userCountries();
        if (_List.success === true)
            if (_List.data !== null) {
                this.setState({ Fa_labels: _List.data });
                this.setState({ All_labels: getCountries() });
            }

        //console.warn("====================================");
        //console.warn(getCountries());
        //console.warn("====================================");
        //console.warn(_List.data);
        //console.warn("====================================");

        this.props.finishLoadingAction();

    }



    accRule = e => {
        e.preventDefault();

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'توافقنامه شرایط و ضوابط استفاده از سامانه مشاوره روانشناسی آنلاین یاری‌وی',
            text: "متن زیر توافقنامه بین سامانه مشاوره روانشناسی آنلاین یاری‌وی و شما به عنوان استفاده کننده از خدمات این سامانه می‌باشد. لطفا جهت استفاده بهینه از خدمات و سرویس‌های سامانه مشاوره روانشناسی آنلاین یاری‌وی آن را به دقت مطالعه نمایید."
                + "\n\r"
                + "استفاده از سامانه یاری‌وی، عضویت در سامانه، ثبت مشخصات و ورود به محیط سامانه یاری‌وی، استفاده از محیط کاری، درخواست مشاوره یا انجام هرگونه معامله، تعامل و یا تراکنش مالی و به طور کلی هرگونه فعالیت در سامانه یاری‌وی به معنای رویت و مطالعه دقیق این توافقنامه، آگاه بودن و پذیرفتن شرایط و اعلام موافقت خود با تمام بندهای این توافقنامه می‌باشد."
                + "\n\r"
                + "توافقنامه پیش رو حقوق متقابل و الزامات سامانه یاری‌وی و”شما“ به عنوان کاربر را در هنگام عضویت و استفاده از خدمات سامانه تعیین می‎‌کند. "
                + "\n\r"
                + "در صورتی که شما با تمام و یا بخشی از این شرایط و قوانین استفاده از سامانه موافق نیستید از سامانه یاری‌وی خارج شوید و از آن استفاده ننمایید."
                + "\n\r"
                + "حریم خصوصی"
                + "\n\r"
                + "سامانه یاری‌وی به اطلاعات خصوصی اشخاصى که از خدمات این سامانه استفاده می‌کنند، احترام گذاشته و از آن محافظت می‏‌کند."
                + "\n\r"
                + "کاربر با اطلاع از اینکه هنگام استفاده از سامانه یاری‌وی، ممکن است اطلاعاتی شخصی نظیر شماره تلفن، جنسیت، نشانی پست الکترونیکی و ... ارائه کرده و رضایت می‌دهد سامانه یاری‌وی تنها اطلاعات هویتی را در اختیار مشاورین قرار می‌دهد. اطلاعات تماس شما تنها در اختیار تیم پشتیبانی است تا بتوانند درباره درخواستی که دارید، با شما تماس بگیرند و شماره تلفن شما صرفا برای هماهنگی جلسات با شما و راهنمایی شما می‌باشد."
                + "\n\r"
                + "لازمه ارائه خدمات توسط مشا.ور، داشتن اطلاعات شما (مانند تلفن، مشخصات فردی و…) است. این سرویس‌دهندگان به اطلاعات شخصی شما دسترسی نخواهند داشت و تنها در سامانه خدمات مورد نیاز را فراهم می‌کنند. "
                + "\n\r"
                + "سامانه یاری‌وی به هیچ عنوان اطلاعات ورود کاربران را به محیط سامانه را از طریق پیامک یا ایمیل از آنها مطالبه نخواهد نمود. "
                + "\n\r"
                + "شناسه کاربر و رمز عبور برای ورود به محیط سامانه می‌باشد و مسئولیت حفظ آن بر عهده استفاده کننده می‌باشد. در صورت مشاهده هرگونه فعالیت مشکوک در حساب کاربری خود، سریعا جهت تغییر رمز عبور خود اقدام فرمایید و سامانه یاری‌وی را از این فعالیت‌های مشکوک مطلع نمایید."
                + "\n\r"
                + "به روزرسانی سامانه"
                + "\n\r"
                + "سامانه یاری‌وی برای بهبود عملکرد خود هر زمان که صلاح بداند می‌تواند در عملکرد سامانه تغییراتی اعمال نماید. این اعمال تغییر ممکن است منجر به اختلال در عملکرد سامانه یا غیر فعال شدن سامانه برای بازه زمانی کوتاه یا بلندی شود. مشاور و کاربر با علم به امکان توقف عملکرد سامانه از خدمات این سامانه استفاده می‌نمایند و حق هرگونه اعتراض یا جبران خسارت را نسبت به آن از خود ساقط می‌نمایند."
                + "\n\r"
                + "لینک به خارج از سامانه"
                + "\n\r"
                + "در صورتی که لینکی درون هر یک از بخش‌های سامانه یاری‌وی وجود داشته باشد به معنی تایید فعالیت‌ها و محتوی آن سایت نمی‌باشد. هرگونه تراکنش مالی یا تعهدی احتمالی از سوی آن سایت بر عهده پدید آورندگان آن سایت می‌باشد و مسئولیت استفاده از خدمات آن سایت تنها متوجه شخص کاربر است."
                + "\n\r"
                + "بندهای عمومی"
                + "\n\r"
                + "سامانه مشاوره روانشناسی آنلاین یاری‌وی در محیط وب اپلیکیشن و شبکه اجتماعی در حوزه سلامت روان، بستری میان روانشناسان متخصص و کاربران مهیا کرده است تا هرچه بیشتر این ارتباط را تسهیل نماید و جایگزین مراجعه حضوری به روانشناس نمی‌باشد.  "
                + "\n\r"
                + "کاربران گرامی تنها مجاز به داشتن یک حساب کاربری با شماره تلفن همراه شخصی خود هستند."
                + "\n\r"
                + "هرگونه اهانت به اشخاص، مذاهب، ملیت‌ها، قومیت‌ها و غیره و هرگونه صحبت خارج از مشاوره روانشناسی ممنوع است."
                + "\n\r"
                + "سامانه یاری‌وی تنها نظرات دارای بی‌احترامی به اشخاص، مذاهب، ملیت‌ها، قومیت‌ها و غیره و صحبت‌های خارج از حیطه روانشناسی و مواردی که طبق قانون جرم می باشد و مواردی که خارج از حیطه کاری سامانه است را حذف می‌کند."
                + "\n\r"
                + "هرگونه استفاده از اطلاعات مندرج در وب سایت به طور مستقیم یا غیر مستقیم بدون کسب اجازه کتبی از مدیر سایت ممنوع بوده و پیگیرد قانونی به همراه دارد."
                + "\n\r"
                + "تمام مسئولیت تبلیغات یا متن‌های داخل سایت که از محصول، خدمات یا شرکتی غیر از یاری‌وی برعهده ارائه دهنده آن محصول و خدمات و شرکت است و یاری‌وی هیچ گونه مسئولیتی در قبال آن ندارد. طبیعتاً هرگونه خسارت‌های مالی و جانی احتمالی ناشی از استفاده از آنها برعهده ارائه دهنده آن محصول، خدمات و شرکت است."
                + "\n\r"
                + "کاربر در زمان برگزاری جلسات ویدئویی ملزم به رعایت شئونات اخلاقی و پوششی مشاوره حضوری می‌باشند."
                + "\n\r"
                + "بندهای مرتبط به کاربران"
                + "\n\r"
                + "کاربران یاری‌وی می‌بایست صداقت در ارائه اطلاعات داشته باشند. اصل محرمانگی اسرار در یاری‌وی به طور کامل رعایت می‌شود. فقط با اجازه از خود کاربر اطلاعات محرمانه کاربر اعم از نام، تصاویر به مشاورانی که در ارائه مشاوره به وی دخیل هستند، نمایش داده می‌شود .شماره تماس شما برای هیچ مشاوری نمایش داده نمی‌شود بلکه فقط دسترسی به تماس داخل محیط سایت داده خواهد ‌شد."
                + "\n\r"
                + "کاربران باید توجه داشته باشند تمام موارد مشاوره در جلسات مختص همان کاربر است و به هیچ عنوان برای فرد دیگری قابل استفاده نمی‌باشد مگر آنکه فرد ثالث از خود مشاور مذکور مشاوره دریافت نموده باشد."
                + "\n\r"
                + "این سامانه برای مواردی که مراجع مشکلات حاد نداشته باشد و تنها با مشاوره گرفتن به صورت غیرحضوری قابل حل باشد، قابل استفاده است. لذا یاری‌وی برای افرادی با مشکلات عمیقی که منجر به آسیب زدن به خود یا فرد دیگری یا موارد مشابه می‌شوند، قابل استفاده نیست. "
                + "\n\r"
                + "در صورتی که یاری‌وی تشخیص دهد نظری مبتنی بر واقعیت و بیانگر تجربه شخصی کاربران نیست، می‌تواند نسبت به حذف آن نظر اقدام کند. "
                + "\n\r"
                + "به هیچ عنوان سوابق مشاوره شما با مشاور به صورت ویدئویی در سرورهای یاری‌وی به منظور محرمانگی اسرار شما کاربران ذخیره نمی‌گردد."
                + "\n\r"
                + "یاری‌وی تنها برای مشاوره روانشناسی بوده و به هیچ عنوان امکان تجویز یا توصیه دارو میسر نمی‌باشد."
                + "\n\r"
                + "تمامی موارد ذکر شده در جلسات بر پایه صحبت‌های کاربر بوده و صحت صدق اظهارات با وی می‌باشد. مشاور بر اساس اطلاعات ارائه شده از طرف کاربر اقدام به ارائه خدمات می نماید لذا در صورت ارائه اطلاعات ناقص یا نادرست هیچ تقصیری متوجه مشاور یا سامانه  نمی‌باشد. "
                + "\n\r"
                + "سامانه یاری‌وی صرفا وظیفه برقراری هرچه بهتر ارتباط کاربر با مشاور را دارد و با توجه به اصل محرمانگی اسرار در این سامانه، امکان نظارت بر جزئیات محتوای جلسات مشاوره از طرف سامانه میسر نیست و سامانه یاری‌وی در این رابطه هیچ گونه مسئولیتی برعهده نمی‌گیرد. کیفیت ارائه خدمات مشاوران در سامانه بر اساس نظرات کاربران سنجیده می‌شود و برای کاربران قابل رویت است."
                + "\n\r"
                + "ضبط جلسات بدون کسب اجازه از مشاور به هر شیوه و طریقی خلاف قانون بوده و در صورت تخلف از طرف کاربر تمام سرویس‌های یاری‌وی برای فرد خاطی قطع خواهد شد. "
                + "\n\r"
                + "انتشار جلسات مشاوره به هر شیوه‌ای حتی درصورت موافقت مشاور ممنوع است. "
                + "\n\r"
                + "تغییر شرایط و ضوابط"
                + "\n\r"
                + "سامانه یاری‌وی می‌تواند در هر زمان تمام یا بخشی از شرایط و قوانین استفاده از سامانه را تغییر دهد تغییرات ایجاد شده فورا در همین صفحه منتشر و به‌روزرسانی می‌شود و به کاربران و مشاوران اطلاع داده می‌شود. ادامه استفاده از سامانه یاری‌وی به منزله مطالعه این صفحه، آگاهی از شرایط جدید و قبول آن می‌باشد."
                + "\n\r"
                + "شرایط و قوانین موجود در این صفحه آخرین تغییرات مربوط به توافقنامه استفاده از سامانه یاری‌وی بوده و جایگزین کلیه توافقات قبلی می‌باشد سامانه یاری‌وی پیشنهاد می‌کند یک نسخه چاپی یا الکترونیکی از این توافق نامه را برای ارجاعات بعدی نزد خود نگه دارید."
                + "\n\r"
                + "",
            showCancelButton: false,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'بستن بخش قوانین',
            cancelButtonText: 'بستن بخش قوانین'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    // .....
                }
            })

    };




    setCountryName = value => {
        let _elm = document.getElementsByName("countryNumberCountry");
        let _elmTxt = _elm[0].options[_elm[0].selectedIndex].text;
        this.setState({ countryName: _elmTxt + " (" + value + ")" });
    }


    setCountryNumber = value => {
        try {
            if (value.indexOf('+') > -1) {
                this.setState({ countryNumber: value.replace('+', '') });
            }
            else {
                this.setState({ countryNumber: value });
            }
            //console.log(value.replace('+', ''));
        }
        catch (e) {
            // Nothing ...
        }
    }


    setCountry = async (val) => {


        if (val === "00000") {
            toast.error("کشور خود را انتخاب کنید");
            return;
        }


        this.setState({ country: val });
        this.setCountryNumber(getCountryCallingCode(val));
        this.setCountryName(val);


        this.props.startLoadingAction();

        let data = {
            code: val,
        };
        const _List = await accountService.farsiCountries(data);
        if (_List.success === true)
            if (_List.data !== null) {
                this.setState({ Fa_TimeZones: _List.data });

                if (_List.data.length > 1) {
                    this.setState({ isShowTimezone: true });
                    this.setState({ timeZoneName: "" });
                }
                else {
                    this.setState({ isShowTimezone: false });
                    this.setState({ timeZoneName: _List.data[0].timeZone_Id__Value });
                }
            }
        this.props.finishLoadingAction();

    }


    onChange = e => {

        this.setState({ [e.target.name]: e.target.value });

    };


    onChangeAcceptedRules = () => {
        this.setState({
            isAcceptedRules: !this.state.isAcceptedRules,
        });
    }




    onSubmit = async (e) => {
        e.preventDefault();

        window._userEmail = "-";
        window._userMobile = "-";


        let userData = {};

        if (!this.state.isAcceptedRules) {
            alert("ابتدا قوانین و مقررات سایت را قبول کنید");
            return;
        }


        if (this.state.isEmailOrMobile === "Mobile") {

            if (this.state.username.length < 6) {
                alert("تعداد کاراکتر های شماره موبایل مجاز نمی باشد");
                return;
            }

            const _regx = /^[0-9\b]+$/; // only number
            if (!_regx.test(this.state.username)) {
                alert("فیلد شماره موبایل فقط میتواند کاراکتر عددی باشد");
                return;
            }


            window._userMobile = "00" + this.state.countryNumber + this.state.username;
            this.setState({ userMobile: window._userMobile });

            userData = {
                UserName: this.state.username,
                Password: this.state.password,
                IntroducerCode: this.state.introducerCode,
                FirstName: this.state.firstname,
                LastName: this.state.lastname,
                TimeZoneName: this.state.timeZoneName,
                CountryNumber: this.state.countryNumber,
                CountryName: this.state.countryName,
                IsEmailOrMobile: this.state.isEmailOrMobile,
                Email: window._userEmail,
                Mobile: window._userMobile,
            };

        }
        else {
            window._userMobile = "-";
            this.setState({ userMobile: "-" });
        }


        if (this.state.isEmailOrMobile === "Email") {

            window._userEmail = this.state.userEmail;

            userData = {
                UserName: window._userEmail,
                Password: this.state.password,
                IntroducerCode: this.state.introducerCode,
                FirstName: this.state.firstname,
                LastName: this.state.lastname,
                TimeZoneName: this.state.timeZoneName,
                CountryNumber: this.state.countryNumber,
                CountryName: this.state.countryName,
                IsEmailOrMobile: this.state.isEmailOrMobile,
                Email: window._userEmail,
                Mobile: window._userMobile,
            };

        }
        else {
            window._userEmail = "-";
            this.setState({ userEmail: "-" });
        }




        this.props.startLoadingAction();

        try {
            const _result = await accountService.userRegister(userData);
            if (_result.success) {
                toast.success("ثبت نام با موفقیت انجام شد");
                //await this.CC_Register({ login: userData.UserName, password: userData.UserName, full_name: userData.UserName });

                this.props.finishLoadingAction();

                this.props.history.push("/verify-code/" + _result.data.uuid + "/" + encodeURIComponent(_result.data.mobileEmail));
            }
            else {
                toast.error(_result.message);
            }
        }
        catch (error) {
            toast.error(error.message);
        }


        this.props.finishLoadingAction();

    };


    //CC_Register = async (dataUser) => {
    //    try {
    //        await ccAuthService.initConfig_WithoutLogin();
    //        const ccUserId = await ccAuthService.signUp(dataUser)
    //            .then(() => {
    //                //toast.success("CC_Register Successfully");
    //                //return true;
    //            })
    //            .catch(error => {
    //                toast.error("ثبت نام در سرور مشاوره با خطا مواجه شد، مجددا تلاش نمایید");
    //                console.log(JSON.stringify(error));
    //                //return false;
    //            });
    //    }
    //    catch (error) {
    //        toast.error(error.message);
    //    }
    //}



    onChangeTimeZoneHandle = async e => {
        if (e.target.value !== "") {
            this.setState({ [e.target.name]: e.target.value });
        }
        else {
            toast.error("منطقه زمانی خود را انتخاب کنید");
        }
    };


    changeEmailOrMobile = e => {
        this.setState({ [e.target.name]: e.target.value });
    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :


        //let labelsENVar = this.state.labelsEN;
        //console.log("**************************");
        //console.log(labelsENVar);
        //console.log("**************************");


        return (
            <React.Fragment>

                <LoadingAccount />


                <div className="login-page d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center">
                        <div className="bg-logo">
                            <img src={headerPic} className="logo-login" alt="yariway" width="70px" />
                        </div>
                    </div>
                    <div className="content col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto p-0">
                        <div className="login-container">
                            <form className="w-100" onSubmit={this.onSubmit} autoComplete="off" autocomplete="off">
                                <div className="row p-0 m-0" style={{ display: "none" }}>
                                    <div className="form-group col-md-6 px-0 pl-md-1">
                                        <div className="cap-focus mb-0">
                                            <input ref={this.firstname} type="text" id="firstname" name="firstname" defaultValue={this.state.firstname} onChange={this.onChange} className="form-control login-input" placeholder="نام" />
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6 px-0 pr-md-1">
                                        <div className="cap-focus mb-0">
                                            <input ref={this.lastname} type="text" id="lastname" name="lastname" defaultValue={this.state.lastname} onChange={this.onChange} className="form-control login-input" placeholder="نام خانوادگی" />
                                        </div>
                                    </div>
                                </div>



                                <div className="row p-0 m-0">
                                    <div className="form-group col-md-12 px-0 pl-md-1">
                                        <div className="cap-focus mb-0">
                                            <select
                                                className="form-control classic"
                                                id="isEmailOrMobile"
                                                name="isEmailOrMobile"
                                                onChange={this.changeEmailOrMobile}
                                                value={this.state.isEmailOrMobile}
                                            >
                                                <option value="Email">ثبت نام با ایمیل</option>
                                                <option value="Mobile">ثبت نام با موبایل</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.isEmailOrMobile === "Email"
                                        ?

                                        <div className="row p-0 m-0">
                                            <div className="form-group col-md-12 px-0 pl-md-1">
                                                <div className="cap-focus mb-0">
                                                    <input
                                                        ref={this.userEmail}
                                                        type="email"
                                                        autoComplete="email"
                                                        id="userEmail"
                                                        name="userEmail"
                                                        defaultValue={this.state.userEmail}
                                                        onChange={this.onChange}
                                                        className="form-control login-input"
                                                        placeholder="ایمیل"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        :

                                        <>
                                            <div className="form-group phone-input-cont">
                                                <div className="cap-focus d-flex">


                                                    <input
                                                        ref={this.username}
                                                        type="tel"
                                                        autoComplete="tel"
                                                        id="username"
                                                        name="username"
                                                        defaultValue={this.state.username}
                                                        onChange={this.onChange}
                                                        className="form-control login-input have-cont-code"
                                                        placeholder="شماره موبایل بدون کد کشور و بدون کاراکتر 0 ابتدای موبایل"
                                                    />

                                                    {/*<PhoneInput_All*/}
                                                    {/*    id="countryNumber"*/}
                                                    {/*    type="text"*/}
                                                    {/*    name="countryNumber"*/}
                                                    {/*    international*/}
                                                    {/*    countryCallingCodeEditable={false}*/}
                                                    {/*    countryCodeEditable={false}*/}
                                                    {/*    value=""*/}
                                                    {/*    smartCaret={false}*/}
                                                    {/*    onChange={this.setCountryNumber}*/}
                                                    {/*    onCountryChange={this.setCountryName}*/}
                                                    {/*    placeholder="کد کشور"*/}
                                                    {/*    style={{ color: "#474747", fontWeight: "400", fontSize: "13px" }}*/}
                                                    {/*/>*/}


                                                    <div
                                                        class="PhoneInput"
                                                        style={{ color: "rgb(71, 71, 71)", fontWeight: "400", fontSize: "13px" }}
                                                    >
                                                        <div class="PhoneInputCountry">
                                                            <select
                                                                name="countryNumberCountry"
                                                                aria-label="Phone number country"
                                                                class="PhoneInputCountrySelect"
                                                                value={this.state.country}
                                                                onChange={event => this.setCountry(event.target.value || undefined)}
                                                            >
                                                                <option value="00000">
                                                                    کشور خود را انتخاب کنید ...
                                                                </option>
                                                                {this.state.Fa_labels.map((countryItem) => (
                                                                    <option key={countryItem.code} value={countryItem.code}>
                                                                        {countryItem.name_FA}: {getCountryCallingCode(countryItem.code)}+
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <div class="PhoneInputCountryIcon PhoneInputCountryIcon--border">
                                                                {this.state.country === ""
                                                                    ?
                                                                    (
                                                                        <svg class="PhoneInputCountryIconImg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 50"><title></title><g class="PhoneInputInternationalIconGlobe" stroke="currentColor" fill="none" stroke-width="2" stroke-miterlimit="10"><path stroke-linecap="round" d="M47.2,36.1C48.1,36,49,36,50,36c7.4,0,14,1.7,18.5,4.3"></path><path d="M68.6,9.6C64.2,12.3,57.5,14,50,14c-7.4,0-14-1.7-18.5-4.3"></path><line x1="26" y1="25" x2="74" y2="25"></line><line x1="50" y1="1" x2="50" y2="49"></line><path stroke-linecap="round" d="M46.3,48.7c1.2,0.2,2.5,0.3,3.7,0.3c13.3,0,24-10.7,24-24S63.3,1,50,1S26,11.7,26,25c0,2,0.3,3.9,0.7,5.8"></path><path stroke-linecap="round" d="M46.8,48.2c1,0.6,2.1,0.8,3.2,0.8c6.6,0,12-10.7,12-24S56.6,1,50,1S38,11.7,38,25c0,1.4,0.1,2.7,0.2,4c0,0.1,0,0.2,0,0.2"></path></g><path class="PhoneInputInternationalIconPhone" stroke="none" fill="currentColor" d="M12.4,17.9c2.9-2.9,5.4-4.8,0.3-11.2S4.1,5.2,1.3,8.1C-2,11.4,1.1,23.5,13.1,35.6s24.3,15.2,27.5,11.9c2.8-2.8,7.8-6.3,1.4-11.5s-8.3-2.6-11.2,0.3c-2,2-7.2-2.2-11.7-6.7S10.4,19.9,12.4,17.9z"></path></svg>
                                                                    )
                                                                    :
                                                                    (
                                                                        <img
                                                                            class="PhoneInputCountryIconImg"
                                                                            alt="CountryIcon"
                                                                            src={"https://purecatamphetamine.github.io/country-flag-icons/3x2/" + this.state.country + ".svg"}
                                                                        />
                                                                    )
                                                                }
                                                                <div className="PhoneInputCountrySelectArrow"></div>
                                                                <span className="CountryNumberPlusClass">+</span>
                                                                <input
                                                                    type="text"
                                                                    autoComplete="tel"
                                                                    id="countryNumber"
                                                                    placeholder="کد کشور"
                                                                    name="countryNumber"
                                                                    className="PhoneInputInput"
                                                                    value={this.state.countryNumber}
                                                                    onChange={this.setCountryNumber}
                                                                    readOnly />
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>


                                            {this.state.isShowTimezone &&
                                                <div className="form-group">
                                                    <div className="cap-focus">
                                                        <select className="form-control timezone-selector w-100" id="timeZoneName" name="timeZoneName" value={this.state.timeZoneName} onChange={this.onChangeTimeZoneHandle}>
                                                            <option value="">
                                                                موقعیت زمانی محل زندگی خود را انتخاب کنید ...
                                                            </option>
                                                            {this.state.Fa_TimeZones.map((itm) => (
                                                                <option key={itm.id} value={itm.timeZone_Id__Value}>
                                                                    {itm.timeZone_DisplayName_Show_Farsi}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            }


                                        </>

                                }



                                <div className="form-group mb-2">
                                    <div className="cap-focus">
                                        <input ref={this.password} type="password" id="password" name="password" defaultValue={this.state.password} onChange={this.onChange} className="form-control login-input" autoComplete="new-password" autocomplete="new-password" placeholder="رمز عبور" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="isAcceptedRules" name="isAcceptedRules" defaultChecked={this.state.isAcceptedRules} onChange={this.onChangeAcceptedRules} />
                                        <label className="custom-control-label" htmlFor="isAcceptedRules">
                                            <button onClick={(e) => this.accRule(e)} className="btn btn-link px-1 py-0 m-0">
                                                <span style={{ color: "#4eb2b8" }}>
                                                    قوانین و مقررات سایت
                                                </span>
                                            </button>
                                            را مطالعه کردم و میپذیرم.
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group mb-2" style={{ display: "none" }}>
                                    <div className="cap-focus">
                                        <span style={{ fontSize: "12px", color: "#868686" }} >
                                            با وارد کردن کدمعرف، 5 دلار شارژ هدیه دریافت کنید:
                                        </span>
                                        <input ref={this.introducerCode} type="text" id="introducerCode" name="introducerCode" defaultValue={this.state.introducerCode} onChange={this.onChange} className="form-control login-input" placeholder="کد معرف (فیلد اختیاری)" />
                                    </div>
                                </div>


                                <button type="submit" className="btn btn-load-effect btn-sign-in">
                                    ثبت نام
                                </button>

                                <div className="d-flex w-100 justify-content-center">
                                    <Link to={"/login"} className="btn btn-link" type="link">
                                        قبلا ثبت نام کردید؟
                                        <span>
                                            ورود
                                        </span>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer
});

// this.props.userLoginAction()
// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(Register);
