import { RESSET_STORE } from '../../actions/cc-message-Deprecated/userLogOut'

export default (state = {}, action) => {
    switch (action.type) {
        case RESSET_STORE: {
            return {};
        }
        default:
            return state;
    }
}