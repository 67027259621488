import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as accountUrlAddress from "../../utils/urlAddress/accountUrlAddress";

export async function userLogin(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.loginUser, user);
}

export async function userLogout(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.logoutUser, user);
}

export async function refreshToken(tokenData) {
    return await axiosRequest.HttpPost(accountUrlAddress.refreshToken, tokenData);
}

export async function userRegister(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.RegisterUser, user);
}

export async function demandUserCCEdit(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.ccEditDemand, user);
}

export async function checkOTP(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.checkOTP, user);
}

export async function userForgotPass(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.forgotPassUser, user);
}

export async function userChangePass(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.changePassUser, user);
}

export async function userChangePassInForgot(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.changePassUserInForgot, user);
}


export async function userCountries() {
    return await axiosRequest.HttpPost(accountUrlAddress.userCountries, null);
}


export async function farsiCountries(data) {
    return await axiosRequest.HttpPost(accountUrlAddress.farsiCountries, data);
}




export async function repeatCode(user) {
    return await axiosRequest.HttpPost(accountUrlAddress.repeatCode, user);
}
