import * as baseUrlAddress from "./baseUrlAddress";

export const consultantTypeList = baseUrlAddress.getApiBaseUrl() + "/DemandConsultantTypes/GetList";
export const consultantList = baseUrlAddress.getApiBaseUrl() + "/DemandConsultants/GetConsultantByTypeId";
export const getFavoriteList = baseUrlAddress.getApiBaseUrl() + "/DemandConsultants/GetFavoriteList";
export const setFavorite = baseUrlAddress.getApiBaseUrl() + "/DemandConsultants/SetFavorite";

export const consultantDetail = baseUrlAddress.getApiBaseUrl() + "/DemandConsultants/GetConsultantById";
export const getSetting = baseUrlAddress.getApiBaseUrl() + "/DemandConsultants/GetSetting";

export const getNCats = baseUrlAddress.getApiBaseUrl() + "/DemandConsultants/GetNCats";

