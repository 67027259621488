import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as surveyUrlAddress from "../../utils/urlAddress/surveyUrlAddress";

export async function surveyList(reqData) {
    return await axiosRequest.HttpPost(surveyUrlAddress.surveyList, reqData);
}

export async function surveySave(reqData) {
    return await axiosRequest.HttpPost(surveyUrlAddress.surveySave, reqData);
}
