import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import * as accountService from "../../services/account/accountService";
import headerPic from "../../theme/assets/images/logo.png";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingAccount from "./loadingAccount";


class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            isSentToMobile: false,
            oldPass: "",
            newPass: "",
            newComfPass: "",
        };
    }

    onChangeHndle = e => {

        //const _regx = /^[0-9\b]+$/; // only number

        //if (e.target.name === "username") {
        //    if (e.target.value === '' || _regx.test(e.target.value)) {
        //        this.setState({ [e.target.name]: e.target.value });
        //    }
        //    else {
        //        e.preventDefault();
        //        alert("فیلد شماره موبایل فقط میتواند کاراکتر عددی باشد");
        //        this.setState({ [e.target.name]: "" });
        //    }
        //}
        //else {

        this.setState({ [e.target.name]: e.target.value });

        //}

    };

    onSubmitHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        let userData = {
            UserName: this.state.username
        };

        try {
            const _result = await accountService.userForgotPass(userData);
            if (_result.success) {
                toast.success(_result.message);
                this.props.finishLoadingAction();

                //this.props.history.push("/login");
                this.setState({ isSentToMobile: true });
            }
            else {
                toast.error(_result.message);
                this.setState({ isSentToMobile: false });
            }
        }
        catch (error) {
            toast.error(error.message);
            this.setState({ isSentToMobile: false });
        }

        this.props.finishLoadingAction();


    };






    onChangeHandle_2 = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmitHandle_2 = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();

        const passData = {
            UserName: this.state.username,
            NewPassword: this.state.newPass,
            ConfirmPassword: this.state.newComfPass,
            OldPassword: this.state.oldPass
        };

        const _result = await accountService.userChangePassInForgot(passData);
        if (_result.success) {
            toast.success("رمز کاربر تغییر کرد، با رمز جدید میتوانید وارد سامانه شوید");
            this.props.finishLoadingAction();
            this.props.history.push("/");
        }
        else
            toast.error(_result.message);

        this.props.finishLoadingAction();

    };







    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        return (
            <React.Fragment>

                <LoadingAccount />


                <div className="login-page d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center">
                        <div className="bg-logo">
                            <img src={headerPic} className="logo-login" width="70px" />
                        </div>
                    </div>
                    <div className="content col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto p-0">
                        <div className="login-container">
                            {this.state.isSentToMobile === false
                                ?
                                <>
                                    <form className="w-100" onSubmit={this.onSubmitHandle}>
                                        <div className="form-group">
                                            <div className="cap-focus">
                                                <input onChange={this.onChangeHndle} value={this.state.username} id="username" name="username" type="text" className="form-control login-input" placeholder="نام کاربری" />
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-load-effect btn-sign-in">
                                            ارسال رمز موقت
                                        </button>

                                        <div className="d-flex w-100 justify-content-center">
                                            <Link to={"/login"} className="btn btn-link" type="link">
                                                صفحه ورود
                                            </Link>
                                        </div>
                                    </form>

                                </>
                                :
                                <>
                                    <div className="profile-edit">

                                        <form className="profile-edit-form" onSubmit={this.onSubmitHandle_2}>
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="oldPass" name="oldPass" value={this.state.oldPass} onChange={this.onChangeHandle_2} placeholder="پسورد یکبار مصرف" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="newPass" name="newPass" value={this.state.newPass} onChange={this.onChangeHandle_2} placeholder="پسورد جدید" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="newComfPass" name="newComfPass" value={this.state.newComfPass} onChange={this.onChangeHandle_2} placeholder="تکرار پسورد جدید" />
                                            </div>

                                            <input
                                                type="submit"
                                                className="btn btn-start-consult rounded "
                                                value="تغییر پسورد"
                                            />
                                        </form>

                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer
});

// this.props.userLoginAction()
// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(ForgotPassword);

