import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import * as consultantTypeService from "../../services/consultant/consultantTypeService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



import m001 from "../../theme/assets/images/m1.jpg";
import m002 from "../../theme/assets/images/m2.jpg";
import m003 from "../../theme/assets/images/m3.jpg";
import m004 from "../../theme/assets/images/m4.jpg";

class CategoryList extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            categories: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        const _List = await consultantTypeService.getConsultantTypes();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ categories: _List.data });

    }



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div class="row p-0 m-0">

                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                    {this.state.categories.slice(0,3).map(item => (
                        <div class="col-12 col-sm-6 px-sm-2 px-md-0 col-md-12 px-0" key={item.id}>
                            <Link to={"/consultants/" + item.id + ""}>
                                <div className="consult-cat-widget mt-0 mb-2">
                                    <div className="cat-image" style={{ backgroundImage: "linear-gradient(90deg, #fff 0%, rgba(0,0,0,0) 100%),url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.thumbnail) + ")" }}></div>
                                    <div className="consult-cat-widget__body w-100">
                                        <h2>{item.name}</h2>
                                        <div className="dr-icons">

                                            <div className="dr-icon" style={{ backgroundImage: "url(" + m004 + ")" }}>
                                            </div>
                                            <div className="dr-icon" style={{ backgroundImage: "url(" + m001 + ")" }}>
                                            </div>
                                            <div className="dr-icon" style={{ backgroundImage: "url(" + m002 + ")" }}>
                                            </div>
                                            <div className="dr-icon" style={{ backgroundImage: "url(" + m003 + ")" }}>
                                            </div>
                                            <div className="dr-icon" style={{ backgroundImage: "url(" + m004 + ")" }}>
                                            </div>
                                            <span>...</span>

                                        </div>
                                    </div>
                                </div>

                            </Link>
                        </div>
                    ))}
                    { /* ----------- key={item._id} must be for list in react ---------------- */}

                </div>

            </React.Fragment>
        );
    }
}

export default CategoryList;
