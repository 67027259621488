import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as consultantUrlAddress from "../../utils/urlAddress/consultantUrlAddress";

export async function getConsultantByTypeId(reqData) {
    return await axiosRequest.HttpPost(consultantUrlAddress.consultantList, reqData);
}

export async function setFavorite(reqData) {
    return await axiosRequest.HttpPost(consultantUrlAddress.setFavorite, reqData);
}

export async function getFavoriteList() {
    return await axiosRequest.HttpPost(consultantUrlAddress.getFavoriteList, null);
}

export async function getConsultantById(reqData) {
    return await axiosRequest.HttpPost(consultantUrlAddress.consultantDetail, reqData);
}

export async function getSetting() {
    return await axiosRequest.HttpPost(consultantUrlAddress.getSetting, null);
}


export async function getNCats() {
    return await axiosRequest.HttpPost(consultantUrlAddress.getNCats, null);
}

