import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as meetingUrlAddress from "../../utils/urlAddress/meetingUrlAddress";

export async function creatNewResavation(meetData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingResavationCreateNew, meetData);
}

export async function getReserveInfo(meetData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingResavationGetReserveInfo, meetData);
}

export async function getListByStartId(meetData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingResavationGetListByStartId, meetData);
}

export async function getList() {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingResavationGetList, null);
}


export async function deleteResvedMetting(meetData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.deleteResvedMetting, meetData);
}