import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as transactionUrlAddress from "../../utils/urlAddress/transactionUrlAddress";

export async function getTransactionList() {
    return await axiosRequest.HttpPost(transactionUrlAddress.transactionList, null);
}

export async function getPackageList() {
    return await axiosRequest.HttpPost(transactionUrlAddress.packageList, null);
}

export async function payAmount(reqData) {
    return await axiosRequest.HttpPost(transactionUrlAddress.payAmount, reqData);
}


export async function checkDiscountCode(reqData) {
    return await axiosRequest.HttpPost(transactionUrlAddress.checkDiscountCode, reqData);
}

export async function approvePaidAmount(reqData) {
    return await axiosRequest.HttpPost(transactionUrlAddress.approvePaidAmount, reqData);
}



export async function selectPackage(reqData) {
    return await axiosRequest.HttpPost(transactionUrlAddress.selectPackage, reqData);
}
