import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



class MeetingsReserveDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reserves: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {
        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0")
            this.props.history.push("/meetings-reserve-archive");

        const reqData = { StartId: nid + "" }; // Id is string
        const _List = await meetingResavationService.getListByStartId(reqData);
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ reserves: _List.data });
    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="col-12 mx-auto px-0 px-md-3">
                    <div className="ticketing-wrapper">
                        <div className="ticketing-body">

                            <div className="ticketing-date">
                                <p className="miladi-date">
                                    { /* Thursday - 2020 06 August */}
                                </p>
                            </div>

                            { /* ----------- key={item._id} must be for list in react ---------------- */}
                            {this.state.reserves.map(item => (

                                <React.Fragment>

                                    <div key={item.id}>
                                        {(item.from_Person_Id === item.demand_UserId) &&

                                            <div className="tickets-right-wrapper" key={item.id}>
                                                <div className="ticket-avatar" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.demand_Avatar) + ")" }}>
                                                </div>
                                                <div className="ticket-right reserve-offer">
                                                    <div className="reserve-offer-body">
                                                        <ul>
                                                            {/*<li>*/}
                                                            {/*    نوع جلسه :*/}
                                                            {/*    <span>*/}
                                                            {/*        {(item.meetingType === 0) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                چت*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-comments"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 1) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس صوتی*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-phone-volume"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 2) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس  تصویری*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-video"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*    </span>*/}
                                                            {/*</li>*/}
                                                            <li>
                                                                موضوع :
                                                                &nbsp;
                                                                <span>
                                                                    {item.consultantTypeName}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                توضیحات :
                                                                &nbsp;
                                                                <span>
                                                                    {item.statusMessage}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                روز پیشنهادی :
                                                                <span className="miladi-date">
                                                                    &nbsp;
                                                                    {item.start_Proposal_FullDate_InLocal_Miladi}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                زمان پیشنهادی :
                                                                <span>
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_FromHour_InLocal + " "}
                                                                    &nbsp;
                                                                    الی
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_ToHour_InLocal + " "}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                وضعیت :
                                                                <span>
                                                                    &nbsp;
                                                                    {(item.status === 0) &&
                                                                        <span>
                                                                            در انتظار تایید مشاور
                                                                        </span>
                                                                    }
                                                                    {(item.status === 1) &&
                                                                        <span>
                                                                            پذیرفته شده توسط مشاور
                                                                        </span>
                                                                    }
                                                                    {(item.status === 2) &&
                                                                        <span>
                                                                            رد شده توسط مشاور - در جهت حفظ رضایت‌مندی مراجع از شما، خواهشمندیم جلسات رد شده توسط خودتان را به حداقل رسانید
                                                                        </span>
                                                                    }
                                                                    {(item.status === 3) &&
                                                                        <span>
                                                                            در حال بررسی توسط کاربر
                                                                        </span>
                                                                    }
                                                                    {(item.status === 4) &&
                                                                        <span>
                                                                            پذیرفته شده توسط کاربر
                                                                        </span>
                                                                    }
                                                                    {(item.status === 5) &&
                                                                        <span>
                                                                            حذف شده توسط کاربر
                                                                        </span>
                                                                    }
                                                                    &nbsp;
                                                                </span>
                                                            </li>


                                                        </ul>

                                                        {(false) &&

                                                            <div className="w-100">
                                                                <div className="row p-0 m-0">
                                                                    <div className="col-6 px-1">
                                                                        <Link
                                                                            to={"/xxxxxxxxxxxxxxxxxx/" + item.id + ""}
                                                                            className="btn btn-new-offer mb-0"
                                                                        >
                                                                            پذیرفتن
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-6 px-1">
                                                                        <Link
                                                                            to={"/xxxxxxxxxxxxxxxxxx/" + item.id + ""}
                                                                            className="btn btn-acc mb-0"
                                                                        >
                                                                            پیشنهاد جدید
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        {(item.from_Person_Id === item.supply_UserId) &&

                                            <div className="tickets-left-wrapper" key={item.id}>
                                                <div className="ticket-avatar" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.supply_Avatar) + ")" }}>
                                                </div>
                                                <div className="ticket-left reserve-offer">
                                                    <div className="reserve-offer-body">

                                                        <ul>
                                                            {/*<li>*/}
                                                            {/*    نوع جلسه :*/}
                                                            {/*    <span>*/}
                                                            {/*        {(item.meetingType === 0) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                چت*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-comments"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 1) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس صوتی*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-phone-volume"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 2) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس  تصویری*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-video"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*    </span>*/}
                                                            {/*</li>*/}
                                                            <li>
                                                                موضوع :
                                                                &nbsp;
                                                                <span>
                                                                    {item.consultantTypeName}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                توضیحات :
                                                                &nbsp;
                                                                <span>
                                                                    {item.statusMessage}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                روز پیشنهادی :
                                                                <span className="miladi-date">
                                                                    &nbsp;
                                                                    {item.start_Proposal_FullDate_InLocal_Miladi}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                زمان پیشنهادی :
                                                                <span>
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_FromHour_InLocal + " "}
                                                                    &nbsp;
                                                                    الی
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_ToHour_InLocal + " "}
                                                                </span>
                                                            </li>


                                                        </ul>

                                                        {(item.status === 3) &&

                                                            <div className="w-100">
                                                                <div className="row p-0 m-0">
                                                                    <div className="col-6 px-1">
                                                                        <Link
                                                                            to={"/xxxxxxxxxxxxxxxxxx/" + item.id + ""}
                                                                            className="btn btn-new-offer mb-0"
                                                                        >
                                                                            پذیرفتن
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-6 px-1">
                                                                        <Link
                                                                            to={"/xxxxxxxxxxxxxxxxxx/" + item.id + ""}
                                                                            className="btn btn-acc mb-0"
                                                                        >
                                                                            پیشنهاد جدید
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        }

                                    </div>

                                </React.Fragment>

                            ))}
                            { /* ----------- key={item._id} must be for list in react ---------------- */}

                        </div>

                        {/* 
                            <button className="btn btn-save rounded-0">
                                کنسل کردن
                            </button>
                        */}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default MeetingsReserveDetail;
