import React, { Component } from "react";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

class NotFound extends Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        store.dispatch({
            type: menuActionTypes.NotFound
        });
    }


    render() {
        return (

            <React.Fragment>

                <center style={{ paddingTop: "15%" }}>
                    <h1>
                        Not Found
                        <br />
                        Not Found
                        <br />
                        Not Found
                    </h1>
                </center >

            </React.Fragment>
        );
    }
};

export default NotFound;