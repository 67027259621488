import React, {Component} from "react";
import {BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {toast} from "react-toastify";
import * as profileService from "../../services/profile/profileService";
import Pushe from "pushe-webpush";

import * as meetingService from '../../services/meeting/meetingService';
import * as tourService from "../../services/profile/tourService";

import Pic001 from "../../theme/assets/images/icon/chat.png";
import Pic002 from "../../theme/assets/images/icon/money.png";
import Pic003 from "../../theme/assets/images/icon/giftbox.png";
import Pic003_1 from "../../theme/assets/images/icon/waitinglist.png";
import Pic004 from "../../theme/assets/images/icon/test.png";
import Pic005 from "../../theme/assets/images/icon/magazine.png";
import Pic006 from "../../theme/assets/images/icon/headset.png";
import Pic007 from "../../theme/assets/images/icon/signpost.png";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import "../../theme/assets/css/dashboard-style.css";
import "../../theme/assets/css/owl.carousel.css";
import "../../theme/assets/css/owl.theme.default.css";

import CategoryList from '../category/breifList';
import NotificationBreifList from '../dashboard/notificationBreifList';
import ReserveArchiveBriefList from '../meeting/reserveArchiveBriefList';
import UserSummary from '../dashboard/userSummary';

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";
//import AlertPage from "../layout/alertPage";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userCredit: "-",
            isMeetNow: false,
            hasSurvey: false,
            meetId: 0,
            profileId: 0,
        };
    }

    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.Dashboard
        });

        this.props.startLoadingAction();
        setTimeout(() => {
            this.props.finishLoadingAction();
        }, 2000);

        if (this.props.accountState.currentUser) {
            this.setState({userCredit: this.props.accountState.currentUser.UserPriceCredit});
        }

        Pushe.getDeviceId()
            .then(function (deviceId) {
                (async () => {
                    const userData = {
                        PushNotif_UserId: deviceId
                    };
                    const _result = await profileService.setPushNotifClientId(userData);
                    if (_result.success) {
                        //toast.success(_result.message);
                    } else {
                        toast.error(_result.message);
                    }
                })();
            });


        // -----------------------------
        // #TourGuid:
        const reqTourData = {Id: "1or5", token: Math.random()}; // Id is string
        const _tourObj = await tourService.isSeenTour(reqTourData);
        if (_tourObj.success === true) {
            if (_tourObj.data !== null) {
                if (_tourObj.data.isSeen === false) {
                    this.showTourNow();
                }
            } else {
                this.showTourNow();
            }
        } else {
            this.showTourNow();
        }
        // -----------------------------


        const ___res = await meetingService.checkAllIsMeeting();
        if (___res.success === true) {
            if (___res.data.result) {
                this.setState({isMeetNow: true})
            }
        }


        let _consultantId = window.kbh_selected_consultantId;
        let _type = window.kbh_selected_type;
        let _catId = window.kbh_selected_catId;
        window.kbh_selected_consultantId = "0";
        window.kbh_selected_type = "";
        window.kbh_selected_catId = "0";


        let mustShow = false;

        const ___resSurvey = await meetingService.hasSurvey();
        if (___resSurvey.success === true) {
            if (___resSurvey.data.hasSurvey) {
                this.setState({hasSurvey: ___resSurvey.data.hasSurvey});
                this.setState({meetId: ___resSurvey.data.meetId});
                this.setState({profileId: ___resSurvey.data.profileId});
                mustShow = true;
            }
        }


        let mustRedirect = false;

        if (_consultantId !== undefined) {
            if (_consultantId !== "0" && _type !== "") {
                mustRedirect = true;
                this.props.startLoadingAction();
                window.thisThatDash = this;
                setTimeout(function () {
                    window.thisThatDash.props.finishLoadingAction();
                    window.thisThatDash.props.history.push("/consultant-detail/" + _consultantId + "/" + _type + "/" + _catId + "");
                }, 1000);
            }
        }


        if (mustRedirect === false) {
            if (mustShow === true) {
                this.showMesageBox();
            }
        }

        this.props.finishLoadingAction();


    }


    showTourNow = () => {

        //let height = 0;
        let width = 990;

        try {
            //height = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
            width = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
        } catch {
            //height = 0;
            width = 990;
        }

        if (width <= 992) {
            this.props.openTourFive();
        } else {
            this.props.openTourOne();
        }

    }


    componentDidUpdate(prevProps, prevState) {
        const currState = this.state;

        if (prevState.userCredit === "-") {

            if (this.props.accountState.currentUser) {
                this.setState({userCredit: this.props.accountState.currentUser.UserPriceCredit});
            }

        }
    }


    showMesageBox = () => {

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: "نظرسنجی مشاور",
            text: ""
                + "\n\r"
                + "برای آخرین جلسه هنوز نظرسنجی را انجام نداده اید"
                + "\n\r",
            icon: 'warning',
            showCancelButton: true,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#727272',
            confirmButtonText: 'انجام نظرسنجی',
            cancelButtonText: 'فعلا نه',
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.props.history.push("/survey/" + this.state.profileId + "/" + this.state.meetId + "");
                }
            })

    };

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        // Redux :
        // this.props.accountState.currentUser
        //if (this.props.accountState.currentUser) {
        //    this.state = {
        //        userCredit: this.props.accountState.currentUser.UserPriceCredit,
        //    };
        //}

        return (
            <React.Fragment>
                <div className="doctors-page">
                    <LoadingPage/>
                    { /* <AlertPage /> */}
                    <main className="dashboard">

                        {/* {
                            this.state.isMeetNow &&
                            (
                                <div className="row p-0 m-0">
                                    <div className="col-12 p-0">
                                        <div className="row p-0 m-0">
                                            <div class="col-12 px-0 px-md-2">
                                                <div class="card alert" style={{ marginBottom: "0px", marginTop: "10px" }}>
                                                    <div class="row p-0 m-0 w-100">
                                                        <div class="col-md-10 px-0 d-flex align-items-center justify-content-center justify-content-md-start">
                                                            <span>
                                                                در حال حاضر یک جلسه فعال دارید که می‌توانید با کلیک روی شروع، اطلاعات جلسه را مشاهده کنید
                                                            </span>
                                                        </div>
                                                        <div class="col-md-2 px-0 d-flex align-items-end justify-content-md-end justify-content-center mt-2 mt-md-0">
                                                            <Link class="btn btn-reserve-offline rounded w-unset" to={"/meetings-reserve-archive"}>
                                                                شروع جلسه
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        } */}


                        {(this.state.hasSurvey) &&
                            <div className="row p-0 m-0">
                                <div class="col-12 px-0 px-md-2">
                                    <div class="card alert" style={{marginBottom: "0px", marginTop: "10px"}}>
                                        <div class="row p-0 m-0 w-100">
                                            <div
                                                class="col-md-10 px-0 d-flex align-items-center justify-content-center justify-content-md-start">
                                                <span>
                                                    برای آخرین جلسه هنوز نظرسنجی را انجام نداده اید
                                                </span>
                                            </div>
                                            <div
                                                class="col-md-2 px-0 d-flex align-items-end justify-content-md-end justify-content-center mt-2 mt-md-0">
                                                <Link class="btn btn-reserve-offline rounded w-unset"
                                                      to={"/survey/" + this.state.profileId + "/" + this.state.meetId}>
                                                    انجام نظرسنجی
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="row p-0 m-0">

                            <div class="col-12 p-0">
                                <div class="top">
                                    <div class="row p-0 m-0">

                                        <div class="col-12 px-0 px-md-1">
                                            <div class="recent-results card">
                                                <div class="row p-0 m-0 justify-content-center">
                                                    <div className="col-12 px-1 mb-3">

                                                        <div class="card alert" style={{
                                                            marginBottom: "0px",
                                                            marginTop: "0px",
                                                            background: "#dbb02b"
                                                        }}>
                                                            <div class="row p-0 m-0 w-100">
                                                                <div
                                                                    class="col-md-10 px-0 d-flex align-items-center justify-content-center justify-content-md-start">
                                                                    <img src={Pic007} alt="" width="60px"
                                                                         className="ml-2"/>
                                                                    <span
                                                                        style={{fontSize: "14px", textAlign: "right"}}>
اگر در انتخاب روانشناس موردنظر خود مشکل دارید,در جلسه ارزیابی شرکت کنید
                                                                </span>
                                                                </div>
                                                                <div
                                                                    class="col-md-2 px-0 d-flex align-items-end align-items-md-center justify-content-md-end justify-content-center mt-2 mt-md-0">
                                                                    <Link class="btn btn-light " to="/initial-evaluation"
                                                                          style={{fontSize: "12px"}}>
                                                                        ارزیابی اولیه
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-6 px-1 mb-3">
                                                        <Link to="/categories" data-tour="step-1">
                                                            <div className="club-widget-card">
                                                                <img src={Pic001} alt=""/>
                                                                <p>
                                                                    شروع جلسه جدید
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </div>


                                                    <div class="col-md-3 col-6 px-1 mb-3">
                                                        <Link to="/meetings-reserve-archive">
                                                            <div class="club-widget-card" data-tour="step-2">
                                                                <img src={Pic003_1} alt=""/>
                                                                <p>
                                                                    لیست جلسات
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div class="col-md-3 col-6 px-1 mb-3">
                                                        <Link to="/transaction">
                                                            <div class="club-widget-card">
                                                                <img src={Pic002} alt=""/>
                                                                <p>
                                                                    امور مالی
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    {/*<div class="col-md-3 col-6 px-1 mb-3">*/}
                                                    {/*    <Link to="/blog">*/}
                                                    {/*        <div class="club-widget-card">*/}
                                                    {/*            <img src={Pic005} alt="" />*/}
                                                    {/*            <p>*/}
                                                    {/*                مجله روانشناسی*/}
                                                    {/*            </p>*/}
                                                    {/*        </div>*/}
                                                    {/*    </Link>*/}
                                                    {/*</div>*/}
                                                    <div class="col-md-3 col-6 px-1 mb-3">
                                                        <Link to="/contact-us">
                                                            <div class="club-widget-card" data-tour="step-3">
                                                                <img src={Pic006} alt=""/>
                                                                <p>
                                                                    ارتباط با پشتیبانی
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    {/*<div class="col-md-3 col-6 px-1 mb-3">*/}
                                                    {/*    <Link to="/">*/}
                                                    {/*        <div class="club-widget-card">*/}
                                                    {/*            <div className="badge badge-coming-soon">*/}
                                                    {/*                بزودی*/}
                                                    {/*            </div>*/}

                                                    {/*            <img src={Pic004} alt="" />*/}
                                                    {/*            <p>*/}
                                                    {/*                تست های روانشناسی*/}
                                                    {/*            </p>*/}
                                                    {/*        </div>*/}
                                                    {/*    </Link>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>


                                        {/* <div className="col-12 p-0">
                                            <div className="row p-0 m-0">
                                                <div class="col-12 px-0 px-md-2">
                                                    <div class="card alert" style={{ marginBottom: "0px", marginTop: "0px" }}>
                                                        <div class="row p-0 m-0 w-100">
                                                            <div class="col-md-10 px-0 d-flex align-items-center justify-content-center justify-content-md-start">
                                                                <span style={{ fontSize: "13px" }}>
                                                                    با معرفی یاری وی، به دوستان خود ۵ دلار هدیه بدهید و با ۵ معرفی، جلسه ای رایگان از یاری وی دریافت کنید.
                                                                </span>
                                                            </div>
                                                            <div class="col-md-2 px-0 d-flex align-items-end justify-content-md-end justify-content-center mt-2 mt-md-0">
                                                                <Link class="btn btn-light " to={"/introducing-code"} style={{ fontSize: "12px" }} >
                                                                    معرفی به دوستان
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> */}


                                        <div className="col-12 p-0">
                                            <div className="top">
                                                <div className="row p-0 m-0">

                                                    <div class="col-lg-12 px-0 px-md-2 mb-3 mb-md-0">
                                                        <div class="upcoming-appointments card h-100">
                                                            <div className="card-header">
                                                                <h1>درخواست های مشاوره</h1>
                                                            </div>
                                                            <div
                                                                class="card-body d-flex align-items-center justify-content-center">

                                                                <ReserveArchiveBriefList/>

                                                            </div>
                                                            <div className="more"><Link to="/meetings-reserve-archive">مشاهده
                                                                همه</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-12 p-0">
                                            <div className="bottom">

                                                <div className="follow-us-on-social">
                                                    <p>
                                                        ما را در شبکه های اجتماعی دنبال کنید.
                                                    </p>
                                                    <div className="col-12 p-0 effect aeneas">
                                                        <div className="buttons">
                                                            {/*<a href="#" className="fb" title="Join us on Facebook"><i className="fab fa-facebook" aria-hidden="true"></i></a>*/}
                                                            {/*<a href="#" className="pinterest" title="Join us on Pinterest"><i className="fab fa-youtube" aria-hidden="true"></i></a>*/}
                                                            <a href="https://www.instagram.com/yariwaycom/"
                                                               className="insta" ><i
                                                                className="fab fa-instagram" aria-hidden="true"></i></a>
                                                            <a href="https://t.me/yariwaycom" className="tw"><i
                                                                className="fab fa-telegram-plane"
                                                                aria-hidden="true"></i></a>
                                                            <a href="https://www.youtube.com/channel/UCxkahgOTKrLN85D3eqNwAuA"
                                                               className="pinterest" ><i
                                                                    className="fab fa-youtube" aria-hidden="true"></i></a>
                                                            <a href="https://www.linkedin.com/company/yariway/"
                                                                className="in" ><i
                                                                    className="fab fa-linkedin" aria-hidden="true"></i></a>
                                                            <a href="https://castbox.fm/channel/id5489513?utm_source=podcaster&utm_medium=dlink&utm_campaign=c_5489513&utm_content=YariWay%20l%20%DB%8C%D8%A7%D8%B1%DB%8C%20%D9%88%DB%8C-CastBox_FM"
                                                                className="cast" ><i
                                                                    className="fas fa-headphones" aria-hidden="true"></i></a>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}


// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer,
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Dashboard));

