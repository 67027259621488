import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

import * as axiosAuthHelper from "../../helper/axiosHeader/axiosAuthHelper";
import * as jwtUserTokenHelper from "../../helper/localStorage/jwtUserTokenHelper";
import * as refreshTokenHelper from "../../helper/localStorage/refreshTokenHelper";

import * as accountService from "../../services/account/accountService";
//import videoAuthService from '../../services/cc-video/auth-service';

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class SidebarMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userLogo: "-",
            displayName: "-",
            userName: "-",
            userCredit: "-"
        };
    }



    onLogoutHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();

        let userData = {
            refreshToken: refreshTokenHelper.getTokenFromLocalStorage()
        };
        const _result = await accountService.userLogout(userData);
        if (_result.success) {

            //videoAuthService.logout();

            //toast.success("Logout Successful");
        }

        refreshTokenHelper.removeTokenFromLocalStorage();
        jwtUserTokenHelper.removeTokenFromLocalStorage();
        axiosAuthHelper.removeAuthTokenFromAxiosHeader();

        this.props.finishLoadingAction();

        this.props.history.push("/");
    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        // Redux :
        // this.props.accountState.currentUser
        if (this.props.accountState.currentUser) {
            this.state = {
                userLogo: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar,
                displayName: this.props.accountState.currentUser.FirstName + " " + this.props.accountState.currentUser.LastName,
                userName: this.props.accountState.currentUser.UserName,
                userCredit: this.props.accountState.currentUser.UserPriceCredit
            };
        }
        let userLogoStyle = { backgroundImage: "url(" + convertUrltoEncode(this.state.userLogo) + ")" };

        return (
            <React.Fragment>

                <div className="sidebar-container">
                    <div className="info-side-box">
                        <div className="row p-0 m-0">
                            <div className="col-5 d-flex align-items-center justify-content-center">
                                <div className="avatar-box" style={userLogoStyle} ></div>
                            </div>
                            <div className="col-7 d-flex align-items-center justify-content-center">
                                <div className="user-name">

                                    <p style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                                        {this.state.userName}
                                    </p>
                                    <p style={{ paddingTop: "0px", marginTop: "0px" }}>
                                        {this.state.displayName}
                                    </p>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row mx-0 mt-lg-3">
                                    <div className="col-lg-6 px-1">
                                        <div className="user-name text-center my-stock" data-tour="step-3">
                                            <p>

                                                موجودی :

                                                &nbsp;

                                                {parseFloat(this.state.userCredit).toFixed(2)}

                                                &nbsp;
                                                دلار
                                            </p>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 px-1">
                                        <Link to={"/transaction"} className="btn btn-stock anlytc-menu-side" data-tour="step-4">
                                            <i className="fas fa-plus"></i>
                                            افزایش اعتبار
                                        </Link>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="nav nav-profile flex-column nav-pills bg-detail" aria-orientation="vertical">

                        {
                            (this.props.menuState.pageUrl === "Dashboard")
                                ?
                                (
                                    <Link to={"/"} className="nav-link active"><i className="fas fa-home"></i>خانه</Link>
                                )
                                :
                                (
                                    <Link to={"/"} className="nav-link"><i className="fas fa-home"></i>خانه</Link>
                                )
                        }
                        {
                            (this.props.menuState.pageUrl === "Categories")
                                ?
                                (
                                    <Link to={"/categories"} className="nav-link active"><i className="fas fa-list-ul"></i> شروع جلسه جدید</Link>
                                )
                                :
                                (
                                    <Link to={"/categories"} className="nav-link"><i className="fas fa-list-ul"></i> شروع جلسه جدید</Link>
                                )
                        }
                        {
                            (this.props.menuState.pageUrl === "MeetingsReserveArchive")
                                ?
                                (
                                    <Link to={"/meetings-reserve-archive"} className="nav-link active"><i className="fas fa-shopping-basket"></i>لیست جلسات</Link>
                                )
                                :
                                (
                                    <Link to={"/meetings-reserve-archive"} className="nav-link"><i className="fas fa-shopping-basket"></i>لیست جلسات</Link>
                                )
                        }
                        {/* {
                            (this.props.menuState.pageUrl === "InitialEvaluation")
                                ?
                                (
                                    <Link to={"/initial-evaluation"} className="nav-link active"><i className="fas fa-flag"></i>ارزیابی اولیه</Link>
                                )
                                :
                                (
                                    <Link to={"/initial-evaluation"} className="nav-link"><i className="fas fa-flag"></i>ارزیابی اولیه</Link>
                                )
                        } */}
                        {/*
                            (this.props.menuState.pageUrl === "MeetingsArchive")
                                ?
                                (
                                    <Link to={"/meetings-archive"} className="nav-link active"><i className="fas fa-archive"></i>آرشیو جلسات</Link>
                                )
                                :
                                (
                                    <Link to={"/meetings-archive"} className="nav-link"><i className="fas fa-archive"></i>آرشیو جلسات</Link>
                                )*/
                        }
                        {/*
                            (this.props.menuState.pageUrl === "Transaction")
                                ?
                                (
                                    <Link to={"/transaction"} className="nav-link active"><i className="fas fa-money-check-alt"></i>امور مالی</Link>
                                )
                                :
                                (
                                    <Link to={"/transaction"} className="nav-link"><i className="fas fa-money-check-alt"></i>امور مالی</Link>
                                )*/
                        }
                        {/*
                            (this.props.menuState.pageUrl === "FavoriteConsultant")
                                ?
                                (
                                    <Link to={"/favorite-consultant"} className="nav-link active" ><i class="fas fa-star"></i>مشاوران منتخب</Link>
                                )
                                :
                                (
                                    <Link to={"/favorite-consultant"} className="nav-link" ><i class="fas fa-star"></i>مشاوران منتخب</Link>
                                )*/
                        }
                        {/* {
                            (this.props.menuState.pageUrl === "IntroducingCode")
                                ?
                                (
                                    <Link to={"/introducing-code"} className="nav-link code active" ><i class="fas fa-users"></i>معرفی به دوستان
                                        <span>جدید</span>
                                    </Link>
                                )
                                :
                                (
                                    <Link to={"/introducing-code"} className="nav-link code" ><i class="fas fa-users"></i>معرفی به دوستان
                                        <span>جدید</span>
                                    </Link>
                                )
                        } */}

                        {/*{*/}
                        {/*    (this.props.menuState.pageUrl === "FavoriteBlog")*/}
                        {/*        ?*/}
                        {/*        (*/}
                        {/*            <Link to={"/favorite-blog"} className="nav-link active" ><i class="fas fa-bookmark"></i>مقالات منتخب</Link>*/}
                        {/*        )*/}
                        {/*        :*/}
                        {/*        (*/}
                        {/*            <Link to={"/favorite-blog"} className="nav-link" ><i class="fas fa-bookmark"></i>مقالات منتخب</Link>*/}
                        {/*        )*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    (this.props.menuState.pageUrl === "Blog")*/}
                        {/*        ?*/}
                        {/*        (*/}
                        {/*            <Link to={"/blog"} className="nav-link active"><i class="fas fa-book-reader"></i>مجله روانشناسی</Link>*/}
                        {/*        )*/}
                        {/*        :*/}
                        {/*        (*/}
                        {/*            <Link to={"/blog"} className="nav-link"><i class="fas fa-book-reader"></i>مجله روانشناسی</Link>*/}
                        {/*        )*/}
                        {/*}*/}
                        
                        {/*{*/}
                        {/*    (this.props.menuState.pageUrl === "Faq")*/}
                        {/*        ?*/}
                        {/*        (*/}
                        {/*            <Link to={"/faq"} className="nav-link active"><i className="fas fa-question"></i>سوالات متداول</Link>*/}
                        {/*        )*/}
                        {/*        :*/}
                        {/*        (*/}
                        {/*            <Link to={"/faq"} className="nav-link"><i className="fas fa-question"></i>سوالات متداول</Link>*/}
                        {/*        )*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    (this.props.menuState.pageUrl === "AboutUs")*/}
                        {/*        ?*/}
                        {/*        (*/}
                        {/*            <Link to={"/about-us"} className="nav-link active"><i class="fas fa-info"></i>درباره یاری‌وی</Link>*/}
                        {/*        )*/}
                        {/*        :*/}
                        {/*        (*/}
                        {/*            <Link to={"/about-us"} className="nav-link"><i class="fas fa-info"></i>درباره یاری‌وی</Link>*/}
                        {/*        )*/}
                        {/*}*/}
                        {/*
                            (this.props.menuState.pageUrl === "ContactUs")
                                ?
                                (
                                    <Link to={"/contact-us"} className="nav-link"><i className="fas fa-headset"></i>ارتباط با پشتیبانی</Link>
                                )
                                :
                                (
                                    <Link to={"/contact-us"} className="nav-link"><i className="fas fa-headset"></i>ارتباط با پشتیبانی</Link>
                                )*/
                        }
                        {
                            (this.props.menuState.pageUrl === "Profile")
                                ?
                                (
                                    <Link to={"/profile"} className="nav-link active"><i className="fas fa-user"></i>ویرایش اطلاعات من</Link>
                                )
                                :
                                (
                                    <Link to={"/profile"} className="nav-link"><i className="fas fa-user"></i>ویرایش اطلاعات من</Link>
                                )
                        }
                        {
                            (this.props.menuState.pageUrl === "Setting")
                                ?
                                (
                                    <Link to={"/setting"} className="nav-link active"><i className="fas fa-cog"></i>تنظیمات</Link>
                                )
                                :
                                (
                                    <Link to={"/setting"} className="nav-link"><i className="fas fa-cog"></i>تنظیمات</Link>
                                )
                        }


                        {/* <Link to={"/pg3/5/10?name=alireza&family=kbh&age=31"} className="nav-link"><i className="fas fa-power-off"></i>خروج از حساب کاربری</Link> */}
                        <Link className="nav-link" onClick={(e) => this.onLogoutHandle(e)}><i className="fas fa-power-off"></i>خروج از حساب کاربری</Link>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}



// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser

// this.props.menuState.pageUrl
// this.props.menuState.pageTitle

const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    menuState: state.menuReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(SidebarMenu));
