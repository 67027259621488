import isNullOrWhiteSpaceOrEmpty from "./isNullOrWhiteSpaceOrEmpty";

const convertUrltoEncode = value => {

    if (isNullOrWhiteSpaceOrEmpty(value)) {
        return "";
    }

    return value.replace(/ /g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');

};

export default convertUrltoEncode;