import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as meetingService from "../../services/meeting/meetingService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import m002 from "../../theme/assets/images/m2.jpg";
//import chatService from "../../services/cc-message/chat-service";
import * as consultantService from "../../services/consultant/consultantService";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { toast } from "react-toastify";

import UserSummary from '../dashboard/userSummary';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


class MeetingsArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            meetings: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.MeetingsArchive
        });

        this.props.startLoadingAction();

        const _List = await meetingService.getList();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ meetings: _List.data });

        this.props.finishLoadingAction();


    }



    handleGotoChat = async (_id) => {
        ////const diaglog = await chatService.fetchDialogsById(_id);
        //try {
        //    this.props.startLoadingAction();

        //    const diaglog = await chatService.fetchDialogs_Really_ById_V2(_id);
        //    chatService.setSelectDialog(diaglog);
        //    this.props.finishLoadingAction();

        //    this.props.history.push("/message-chat/" + _id);
        //}
        //catch {
        //    alert("خطا در برقراری ارتباط ، مجددا سعی نمایید")
        //}
        //this.props.finishLoadingAction();

    }



    onDeleteMetting = async (e, id) => {
        e.preventDefault();

        window.favData = {
            Id: id + ""
        };

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'آیا از حذف این جلسه اطمینان دارید؟',
            text: "در صورت حذف آرشیو جلسات دیگر قادر به بازیابی آن نخواهید بود",
            icon: 'warning',
            showCancelButton: true,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#727272',
            confirmButtonText: 'بله ، حذف شود',
            cancelButtonText: 'خیر'
        })
            .then((result) => {
                if (result.isConfirmed) {

                    (async () => {

                        const _obj = await meetingService.deleteMetting(window.favData);
                        if (_obj.success === true) {
                            toast.success(_obj.message);

                            let meetingsLst = [...this.state.meetings];
                            let objInDb = meetingsLst.find((x) => x.id === id) || {};
                            meetingsLst.splice(meetingsLst.indexOf(objInDb), 1);

                            this.setState({ meetings: meetingsLst });
                        }
                        else {
                            toast.error(_obj.message);
                        }

                    })();

                }
            })

    };



    onFaveHandle = async (e, id) => {
        e.preventDefault();

        const favData = {
            Id: id + ""
        };

        this.props.startLoadingAction();


        const _obj = await consultantService.setFavorite(favData);
        if (_obj.success === true) {
            toast.success(_obj.message);

            let meetingsLst = [...this.state.meetings];
            let objInDb = meetingsLst.filter((x) => x.supply_ProfileId === id) || [];

            for (var i = 0; i < objInDb.length; i++) {
                if (_obj.data.res === "added")
                    objInDb[i].isMyFavorite = true;
                if (_obj.data.res === "removed")
                    objInDb[i].isMyFavorite = false;
                var fIndex = this.findIndexByProperty(meetingsLst, 'id', objInDb[i].id);
                if (fIndex > -1)
                    meetingsLst[fIndex] = objInDb[i];
            }

            this.setState({ meetings: meetingsLst });
        }
        else {
            toast.error(_obj.message);
        }

        this.props.finishLoadingAction();


    };


    findIndexByProperty = (data, key, value) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] === value) {
                return i;
            }
        }
        return -1;
    }





    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // م0ثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">

                    <LoadingPage />


                    { /* <UserSummary /> */}

                    <div className="row p-0 m-0 ">

                        {this.state.meetings.length === 0
                            ?
                            (
                                <>
                                    <div className="col-12 mx-auto">
                                        <br />
                                        <center > در حال حاضر جلسه‌ای در آرشیو وجود ندارد </center>
                                        <br />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>

                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                    {this.state.meetings.map(item => (
                                        <div className="col-xl-4 col-sm-6 px-2" key={item.id}>
                                            <div className="archive-widget">
                                                <div className="action-icon row p-0 m-0">

                                                    {(item.isMyFavorite === true)
                                                        ?
                                                        <a
                                                            className="bookmark active"
                                                            style={{ flexGrow: 1 }}
                                                            onClick={(e) => this.onFaveHandle(e, item.supply_ProfileId)}
                                                        >
                                                            <i className="fas fa-bookmark"></i>
                                                        </a>
                                                        :
                                                        <a
                                                            className="bookmark"
                                                            style={{ flexGrow: 1 }}
                                                            onClick={(e) => this.onFaveHandle(e, item.supply_ProfileId)}
                                                        >
                                                            <i className="fas fa-bookmark"></i>
                                                        </a>
                                                    }

                                                    <button
                                                        className="btn btn-delete-all"
                                                        onClick={(e) => this.onDeleteMetting(e, item.id)}
                                                    >
                                                        <i className="far fa-trash-alt"></i>
                                                    </button>

                                                </div>
                                                <div className="row p-0 m-0">
                                                    <div className="col-5 user-side">
                                                        <div>
                                                            <img src={baseUrlAddress.getFilesBaseUrl() + item.supply_Avatar} />
                                                            <h4>{item.supply_UserName}</h4>
                                                            <p>
                                                                روانشناس
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 user-connection">
                                                        {(item.meetingType === 0) &&
                                                            <i className="fas fa-comments"></i>
                                                        }
                                                        {(item.meetingType === 1) &&
                                                            <i className="fas fa-phone-volume"></i>
                                                        }
                                                        {(item.meetingType === 2) &&
                                                            <i className="fas fa-video"></i>
                                                        }
                                                    </div>
                                                    <div className="col-5 user-side">
                                                        <div>
                                                            <img src={baseUrlAddress.getFilesBaseUrl() + item.demand_Avatar} />
                                                            <h4>{item.demand_UserName}</h4>
                                                            <p>
                                                                کاربر
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 archive-detail">
                                                        <div className="row p-0 m-0">
                                                            <div className="col-12 px-2 detail-it-in-archive">
                                                                <p>
                                                                    کد جلسه
                                                                    :
                                                                    {item.id}
                                                                </p>
                                                            </div>
                                                            <div className="col-12 px-2 detail-it-in-archive">
                                                                <p>
                                                                    موضوع
                                                                    :
                                                                    {item.consultantTypeName}
                                                                </p>
                                                            </div>
                                                            <div className="col-12 px-2 detail-it-in-archive">
                                                                <p>
                                                                    نرم افزار
                                                                    :
                                                                    {item.typeMeet}
                                                                </p>
                                                            </div>

                                                            <div className="col-6 p-2">
                                                                <div className="archive-detail-widget">
                                                                    <p>
                                                                        <span>
                                                                            {item.timeDuration_InMinute}
                                                                            دقیقه
                                                                        </span>
                                                                        مدت زمان
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 p-2">
                                                                <div className="archive-detail-widget">
                                                                    <p>
                                                                        <span style={{ direction: "ltr" }}>
                                                                            {item.proposaled_Start_DateTime_String_Miladi}
                                                                        </span>
                                                                        تاریخ
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 p-0">
                                                                <div className="your-feedback d-flex justify-content-between">
                                                                    <h4>امتیاز شما به این مشاور</h4>
                                                                    <div className="star-wrapper">
                                                                        <div className="rated">
                                                                            {
                                                                                (item.starPoint === 5)
                                                                                    ?
                                                                                    (<input id="rating-5" type="radio" name="rating" value="5" className="checked" />)
                                                                                    :
                                                                                    (<input id="rating-5" type="radio" name="rating" value="5" />)
                                                                            }
                                                                            <label htmlFor="rating-5">
                                                                                <i className="fas fa-star"></i>
                                                                            </label>

                                                                            {
                                                                                (item.starPoint === 4)
                                                                                    ?
                                                                                    (<input id="rating-4" type="radio" name="rating" value="4" className="checked" />)
                                                                                    :
                                                                                    (<input id="rating-4" type="radio" name="rating" value="4" />)
                                                                            }
                                                                            <label htmlFor="rating-4">
                                                                                <i className="fas fa-star"></i>
                                                                            </label>

                                                                            {
                                                                                (item.starPoint === 3)
                                                                                    ?
                                                                                    (<input id="rating-3" type="radio" name="rating" value="3" className="checked" />)
                                                                                    :
                                                                                    (<input id="rating-3" type="radio" name="rating" value="3" />)
                                                                            }
                                                                            <label htmlFor="rating-3">
                                                                                <i className="fas fa-star"></i>
                                                                            </label>

                                                                            {
                                                                                (item.starPoint === 2)
                                                                                    ?
                                                                                    (<input id="rating-2" type="radio" name="rating" value="2" className="checked" />)
                                                                                    :
                                                                                    (<input id="rating-2" type="radio" name="rating" value="2" />)
                                                                            }
                                                                            <label htmlFor="rating-2">
                                                                                <i className="fas fa-star"></i>
                                                                            </label>

                                                                            {
                                                                                (item.starPoint === 1)
                                                                                    ?
                                                                                    (<input id="rating-1" type="radio" name="rating" value="1" className="checked" />)
                                                                                    :
                                                                                    (<input id="rating-1" type="radio" name="rating" value="1" />)
                                                                            }
                                                                            <label htmlFor="rating-1">
                                                                                <i className="fas fa-star"></i>
                                                                            </label>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    (item.meetingType === 0)
                                                        ?
                                                        (
                                                            <div className="row p-0 m-0">
                                                                <div className="col-6 pr-0 pl-1">
                                                                    <a className="btn btn-reserve-offline rounded mt-2" onClick={() => this.handleGotoChat(item.chat_MeetingId)}>
                                                                        آرشیو چت
                                                                    </a>
                                                                </div>
                                                                <div className="col-6 pl-0 pr-1">
                                                                    {
                                                                        (item.starPoint === 0)
                                                                            ?
                                                                            (
                                                                                <Link to={"/survey/" + item.supply_ProfileId + "/" + item.id}
                                                                                    className="btn btn-start-consult rounded mt-2">
                                                                                    نظرسنجی
                                                                                </Link>
                                                                            )
                                                                            :
                                                                            (
                                                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> شما قبلا در این نظرسنجی شرکت کرده اید </Tooltip>} >
                                                                                    <span className="d-inline-block w-100">
                                                                                        <button disabled style={{ pointerEvents: 'none' }} className="btn btn-offline mt-2 rounded">نظرسنجی</button>
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div className="col-12 p-0">
                                                                {
                                                                    (item.starPoint === 0)
                                                                        ?
                                                                        (
                                                                            <Link to={"/survey/" + item.supply_ProfileId + "/" + item.id}
                                                                                className="btn btn-start-consult rounded mt-2">
                                                                                نظرسنجی
                                                                            </Link>
                                                                        )
                                                                        :
                                                                        (
                                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> شما قبلا در این نظرسنجی شرکت کرده اید </Tooltip>} >
                                                                                <span className="d-inline-block w-100">
                                                                                    <button disabled style={{ pointerEvents: 'none' }} className="btn btn-offline rounded mt-2">نظرسنجی</button>
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        )
                                                                }
                                                            </div>
                                                        )
                                                }

                                            </div>
                                        </div>
                                    ))}
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}

                                </>
                            )
                        }


                    </div>
                </div>


            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(MeetingsArchive));
