import * as accountActionTypes from "../actionTypes/accountActionTypes";

const initialState = {
    loading: false,
    errorMessage: null,
    isAuthenticated: false,
    currentUser: null
};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case accountActionTypes.UserLoginStarted:
            return {
                ...state,
                loading: true,
                errorMessage: null,
                isAuthenticated: false,
                currentUser: null
            };
        case accountActionTypes.UserLoginSuccess:
            return {
                ...state,
                loading: false,
                errorMessage: null,
                isAuthenticated: true,
                currentUser: action.payload
            };
        case accountActionTypes.UserLoginFailure:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
                isAuthenticated: false,
                currentUser: null
            };
        default:
            return state;
    }
}
