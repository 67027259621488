import { jwtDecode } from "jwt-decode";
import store from "../../redux/store/store";

import isNullOrWhiteSpaceOrEmpty from "../validation/isNullOrWhiteSpaceOrEmpty";
import * as jwtUserTokenHelper from "./jwtUserTokenHelper";
import * as axiosAuthHelper from "../axiosHeader/axiosAuthHelper";
import * as accountActionTypes from "../../redux/actionTypes/accountActionTypes";


// get User : 
// ---------------------------------------------------------------------------
export function getCurrentUserFromLocalStorage_andDoAllUserSettings() {
    try {
        const _jwtToken = jwtUserTokenHelper.getTokenFromLocalStorage();
        const _currUser = jwtDecode(_jwtToken);

        checkExistsAndExpirationDate(_currUser);
        addRoles(_currUser);
        axiosAuthHelper.setAuthTokenToAxiosHeader(_jwtToken);

        store.dispatch({
            type: accountActionTypes.UserLoginSuccess,
            payload: _currUser
        });

        return _currUser;
    }
    catch (ex) {
        jwtUserTokenHelper.removeTokenFromLocalStorage();
        axiosAuthHelper.removeAuthTokenFromAxiosHeader();

        store.dispatch({
            type: accountActionTypes.UserLoginFailure,
            payload: null //ex.message
        });
        console.log(ex.message);

        return null;
    }
}

function checkExistsAndExpirationDate(user) {
    if (isNullOrWhiteSpaceOrEmpty(user)) {
        throw new Error("This user is not Exist!");
    }

    if (!user || !user.exp) {
        throw new Error("This access token doesn't have an expiration date!");
    }

    // Standard DateTime :
    //const currentTime = Date.now() / 1000;
    //if (_currUser.exp < currentTime) {
    //    throw new Error("This access token is expired!");
    //}

    // UTC DateTime :
    user.expirationDateUtc = new Date(0); // The 0 sets the date to the epoch
    user.expirationDateUtc.setUTCSeconds(user.exp);
    const isAccessTokenTokenExpired = user.expirationDateUtc.valueOf() < new Date().valueOf();
    if (isAccessTokenTokenExpired) {
        throw new Error("This access token is expired!");
    }
}

function addRoles(user) {
    const roles = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if (roles) {
        if (Array.isArray(roles)) {
            user.roles = roles.map(x => x.toLowerCase());
        } else {
            user.roles = [roles.toLowerCase()];
        }
    }
}


// Get Roles of User : 
// ---------------------------------------------------------------------------
export function isAuthUserInRole(user, requiredRole) {
    return isAuthUserInRoles(user, [requiredRole]);
}

export function isAuthUserInRoles(user, requiredRoles) {
    if (!user || !user.roles) {
        return false;
    }

    if (user.roles.indexOf("Admin") >= 0) {
        return true; // The `Admin` role has full access to every pages.
    }

    return requiredRoles
        .some(x => {
            if (user.roles) {
                return user.roles.indexOf(x.toLowerCase()) >= 0;
            }
            else {
                return false;
            }
        });
}
