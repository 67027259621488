export const UserLoginStarted = "UserLoginStarted";
export const UserLoginSuccess = "UserLoginSuccess";
export const UserLoginFailure = "UserLoginFailure";

export const UserRegisterStarted = "UserRegisterStarted";
export const UserRegisterSuccess = "UserRegisterSuccess";
export const UserRegisterFailure = "UserRegisterFailure";

export const UserLogoutDone = "UserLogoutDone";

