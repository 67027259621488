import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import * as profileService from "../../services/profile/profileService";
import { refreshTokenAction } from "../../redux/actions/accountActions";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import * as transactionService from "../../services/transaction/transactionService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import queryString from 'query-string'

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

//import '../../theme/global/transaction.css';

class PaymentFailed extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            userEmail: "",
            userName: "",
            amount: "",
            loading: false
        };
    }


    //// load ajax and get data from server
    //async componentDidMount() {

    //    store.dispatch({
    //        type: menuActionTypes.Transaction
    //    });

    //    let token = queryString.parse(this.props.location.search).token;
    //    // if token === null : .............

    //    const reqData = {
    //        BankOrderTokenId: "" + token + "",
    //        PayType: 10558044 //SandBox
    //    };

    //    const _result = await transactionService.approvePaidAmount(reqData);
    //    if (_result.success === true) {
    //        this.setState({
    //            userEmail: _result.data.userEmail,
    //            userName: _result.data.userName,
    //            amount: _result.data.amount,
    //            loading: false
    //        });
    //    }
    //    else {
    //        toast.error(_result.message);
    //        this.setState({
    //            userEmail: "error",
    //            userName: "error",
    //            amount: "error",
    //            loading: false
    //        });
    //    }

    //}


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div class="about-wrapper content d-flex align-items-center justify-content-center col-12 p-0 m-0" style={{ height: "100vh" }}>
                    <div class="pay-result payment-paypal">

                        {
                            (this.state.loading)
                                ?
                                (
                                    <div>
                                        Please wait, loading Payment Result ...
                                    </div>
                                )
                                :
                                (
                                    <div className="anlytc-fail-pay">

                                        <i class="fas fa-times-circle"></i>
                                        <p>
                                            Payment Failed
                                        </p>
                                        <p class="small-txt">
                                            <br />
                                                the transaction has NOT completed, we sent you an email with the receipt of the purchased item. Please log in to your PayPal account to view transaction details
                                                <br />
                                            <br />
                                        </p>
                                        {/*<div>*/}
                                        {/*    <p>Payment Info</p>*/}
                                        {/*        Email : {this.state.userEmail}*/}
                                        {/*    <br />*/}
                                        {/*        Name : {this.state.userName}*/}
                                        {/*    <br />*/}
                                        {/*        Amount : {this.state.amount}*/}
                                        {/*</div>*/}
                                        
                                        <Link to={"/transaction"} type="button" class="btn btn-next-step">
                                            continue
                                        </Link>


                                    </div>
                                )
                        }

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(PaymentFailed);
