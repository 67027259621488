import * as baseUrlAddress from "./baseUrlAddress";

export const transactionList = baseUrlAddress.getApiBaseUrl() + "/DemandTransactions/GetList";
export const payAmount = baseUrlAddress.getApiBaseUrl() + "/DemandTransactions/PayAmount";


export const checkDiscountCode = baseUrlAddress.getApiBaseUrl() + "/DemandTransactions/CheckDiscountCode";


export const approvePaidAmount = baseUrlAddress.getApiBaseUrl() + "/DemandTransactions/ApprovePaidAmount";
export const selectPackage = baseUrlAddress.getApiBaseUrl() + "/DemandTransactions/SelectPackage";

export const packageList = baseUrlAddress.getApiBaseUrl() + "/DemandPackages/GetList";
