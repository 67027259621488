import React, {Component} from "react";
import {BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {toast} from "react-toastify";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



import * as profileService from "../../services/profile/profileService";
import {refreshTokenAction} from "../../redux/actions/accountActions";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import * as consultantService from "../../services/consultant/consultantService";
import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as meetingService from "../../services/meeting/meetingService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

//import ChatService from '../../services/cc-message/chat-service';
//import CallService from '../../services/cc-video/call-service';

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";


import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';


class ConsultantDetail extends Component {
    constructor(props) {
        super(props);
        //props.xyz
        //props.selectedUsersIds
        //props.closeSelect
        //props.initRemoteStreams
        //props.setLocalStream
        //props.isActiveSelect
        //props.isActiveCall
        //props.resetState
        //props.unselectUser
        //props.selectUser

        // برای بار اولی که اپ بالا می آید
        this.state = {
            consultant: [],
            comments: [],
            nid: "0",
            ntype: "",
            ncat: "0",
            meetingType: "2", // 0 == message
            allCostChat: "-",
            allCostVoice: "-",
            allCostVideo: "-",
            fixCostVoice: "-",
            fixCostVideo: "-",
            perMinCostVoice: "-",
            perMinCostVideo: "-",
            durationTime: "-",
            waitToStartTime: "-",
            mustPay: "-1",
            ncatRequierd: false,
            ncatList: [],
        };
    }


    // load ajax and get data from server
    async componentDidMount() {

        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0") {
            alert("nid : " + nid);
            this.props.history.push("/categories");
        }
        this.setState({nid: nid});


        let ntype = this.props.match.params.ntype; // ntype is string
        if (ntype === undefined || ntype === "undefined" || ntype === "") {
            alert("ntype : " + ntype);
            this.props.history.push("/categories");
        }
        this.setState({ntype: ntype});
        // ntype: "o"  ===  online  Request  
        // ntype: "r"  ===  resarve Request


        let ncat = this.props.match.params.ncat; // ncat is string
        if (ncat === undefined || ncat === "undefined" || ncat === "" || ncat === "0") {
            //this.props.history.push("/categories");
            //alert("ncat : " + ncat);
            //ncatRequierd
            this.setState({ncat: "0"});
            this.setState({ncatRequierd: true});
        } else {
            this.setState({ncat: ncat});
        }


        store.dispatch({
            type: menuActionTypes.ConsultantDetail
        });


        this.props.startLoadingAction();


        const _resss = await consultantService.getNCats();
        if (_resss.success === true) {
            if (_resss.data !== null) {
                this.setState({
                    ncatList: _resss.data.list,
                });
            } else {
                toast.error("امکان اتصال به سرور وجود ندارد، مجددا تلاش نمایید");
                this.props.finishLoadingAction();
                //this.props.history.push("/categories");
            }
        } else {
            alert("getNCats");
            toast.error(_resss.message);
            this.props.finishLoadingAction();
            //this.props.history.push("/categories");
        }


        const _setting = await consultantService.getSetting();
        if (_setting.success === true) {
            if (_setting.data !== null) {
                this.setState({
                    allCostChat: _setting.data.allCostChat,
                    allCostVoice: _setting.data.allCostVoice,
                    allCostVideo: _setting.data.allCostVideo,
                    fixCostVoice: _setting.data.fixCostVoice,
                    fixCostVideo: _setting.data.fixCostVideo,
                    perMinCostVoice: _setting.data.perMinCostVoice,
                    perMinCostVideo: _setting.data.perMinCostVideo,
                    durationTime: _setting.data.durationTime,
                    waitToStartTime: _setting.data.waitToStartTime
                });
            } else {
                toast.error("امکان اتصال به سرور وجود ندارد، مجددا تلاش نمایید");
                this.props.finishLoadingAction();
                alert("getSetting 1");

                //this.props.history.push("/categories");
            }
        } else {
            toast.error(_setting.message);
            this.props.finishLoadingAction();
            alert("getSetting 2");

            //this.props.history.push("/categories");
        }


        const reqData = {Id: nid + ""}; // Id is string
        const _obj = await consultantService.getConsultantById(reqData);
        if (_obj.success === true) {
            if (_obj.data !== null) {
                this.setState({consultant: _obj.data});
                this.setState({comments: _obj.data.commentsList});
            } else {
                this.props.finishLoadingAction();
                toast.error(_obj.message);
                alert("getConsultantById 1");

                //this.props.history.push("/categories");
            }
        } else {
            this.props.finishLoadingAction();
            toast.error(_obj.message);
            alert("getConsultantById 2");

            //this.props.history.push("/categories");
        }

        this.props.finishLoadingAction();


    }

    changenCat = e => {
        this.setState({ncat: "" + e.target.value + ""});
    }

    changeMeetingTypeHandle = e => {
        this.setState({meetingType: e.currentTarget.attributes["data-name"].value});
    }


    creatNewResvMeetingHandle = async e => {
        e.preventDefault();

        if (this.state.meetingType === "-1") {
            toast.error("لطفا نوع جلسه را انتخاب نمایید");
            return;
        }


        if (this.state.ncat === "0") {
            toast.error("لطفا حوزه مشاوره را انتخاب نمایید");
            return;
        }

        if (this.state.ncat === "") {
            toast.error("لطفا حوزه مشاوره را انتخاب نمایید");
            return;
        }


        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'قوانین و مقررات تشکیل جلسه رزرو شده',
            text: ""
                + "•	برای تغییر زمان جلسه به زمان دیگر" +
                "، باید حتما تا 24 ساعت قبل از شروع جلسه‌ مشاوره، این موضوع به پشتیبانی یاری‌وی اطلاع داده شود." +
                "توجه داشته باشید در صورتی که تغییر زمان و یا کنسلی جلسه کمتراز 24 ساعت مانده به زمان مشاوره از سمت شما اطلاع رسانی شود، ۵۰٪ هزینه جلسه از شما کسر می‌گردد. در صورتی که به هر دلیل اعم از مشکل اتصال اینترنت در ایران یا عدم امکان تشکیل جلسه از سمت مشاور جلسه‌ کنسل شود، مراجعه‌کننده می‌تواند بین دریافت وجه پرداختی و یا انتقال جلسه‌ مشاوره‌ خود به زمانی دیگر و یا تغییر روانشناس یک راه را انتخاب کند. "
                + "\n\r"
                + "•	جلسه مشاوره ۴۵ دقیقه می‌باشد. "
                + "\n\r"
                + "",
            showCancelButton: false,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'قوانین را میپذیرم',
            cancelButtonText: 'نمیپذیرم'
        })
            .then((result) => {
                if (result.isConfirmed) {

                    (async () => {

                        //this.props.startLoadingAction();
                        //this.props.finishLoadingAction();

                        this.props.history.push("/consultant-reserve/" + this.state.nid + "/" + this.state.ncat + "/" + this.state.meetingType + "");

                    })();

                }
            })

    }


    creatNewMeetingHandle = async e => {
        e.preventDefault();

        if (this.state.meetingType === "-1") {
            toast.error("لطفا نوع جلسه را انتخاب نمایید");
            return;
        }

        if (this.state.ncat === "0") {
            toast.error("لطفا حوزه مشاوره را انتخاب نمایید");
            return;
        }

        if (this.state.ncat === "") {
            toast.error("لطفا حوزه مشاوره را انتخاب نمایید");
            return;
        }


        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'قوانین و مقررات تشکیل جلسه آنلاین',
            text: "•	جلسه مشاوره ۴۵ دقیقه می‌باشد."
                + "\n\r"
                + "•	به ازای هر دقیقه استفاده کمتر از جلسات تصویری، هزینه‌ای مطابق با مقدار قید شده در پلن‌های مشاوره به حساب کاربری شما بازگردانده می‌شود."
                + "\n\r"
                + "•	در صورت قطع شدن ارتباط به هر علتی، امکان برقراری مجدد جلسه وجود دارد و نیازی به پرداخت مجدد نیست."
                + "\n\r"
                + "•	Refresh‌ کردن صفحه باعث قطع ارتباط شما با روانشناس می‌شود لذا حین جلسه از Refresh‌ کردن صفحه خودداری نمایید."
                + "\n\r",
            showCancelButton: false,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'قوانین را میپذیرم',
            cancelButtonText: 'نمیپذیرم'
        })
            .then((result) => {
                if (result.isConfirmed) {

                    (async () => {

                        this.props.startLoadingAction();

                        const reqData = {
                            NCat: this.state.ncat + "",
                            SupplyId: this.state.consultant.userId + "",
                            MeetingTypeId: this.state.meetingType + "",
                            MeetingDay: "#DATETIME_NOW#",
                            MeetingHour: "#DATETIME_NOW#",
                            MeetingMessage: "درخواست شروع جلسه آنلاین-فوری",
                        };
                        const _res = await meetingResavationService.creatNewResavation(reqData);
                        if (_res.success === true) {

                            if (_res.data.mustPay) {
                                toast.error(_res.message);
                                this.setState({mustPay: _res.data.mustPay});
                            } else {
                                let isPaid = await this.props.refreshTokenAction();
                                if (isPaid)
                                    currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();

                                this.props.finishLoadingAction();


                                const MySwal2 = withReactContent(Swal)
                                MySwal2.fire({
                                    title: 'راهنمای شروع جلسه آنلاین',
                                    text: "•	دقت فرمایید که برای برقراری تماس، دسترسی های مجاز microphone و یا camera مرورگر به حالت Allow باشد. "
                                        + "\n\r"
                                        + "•	بعد از تایید روانشناس به لیست جلسات در منو بروید و جلسه مورد نظر بالای صفحه آماده شروع از سمت شما یا روانشناس می‌باشد. "
                                        + "\n\r"
                                        + "•	اگر روانشناس جلسه را شروع کرد، صفحه شما زنگ می‌خورد که در صورت زدن دکمه Accept توسط شما جلسه شروع می‌شود. "
                                        + "\n\r",
                                    showCancelButton: false,
                                    iconColor: '#ffce46',
                                    confirmButtonColor: '#489fa4',
                                    cancelButtonColor: '#f44336',
                                    confirmButtonText: 'متوجه شدم',
                                    cancelButtonText: 'نمیپذیرم'
                                })
                                    .then((result) => {
                                        if (result.isConfirmed) {

                                            this.props.history.push("/meetings-reserve-archive");
                                            //this.props.history.push("/meetings-reserve-detail/" + _res.data.id);
                                        }
                                    })

                            }

                        } else {
                            toast.error(_res.message);
                        }

                        this.props.finishLoadingAction();

                    })();

                }
            })

    }


    //_____OLD_____creatNewMeetingHandle = async e => {
    //    e.preventDefault();

    //    const reqData = {
    //        SupplyId: this.state.consultant.userId + "",
    //        MeetingTypeId: this.state.meetingType + "",
    //        RelatedTo_MeetingResaved_Id: "-1"
    //    };
    //    const _res = await meetingService.creatNewMeeting(reqData);
    //    if (_res.success === true) {

    //        // type: 2 === PublicDialog === Private Group
    //        const params = {
    //            type: 2,
    //            occupants_ids: [_res.data.chat_Demand_OccupantId, _res.data.chat_Supply_OccupantId],
    //            name: "d: " + _res.data.demand_UserId + " - s: " + _res.data.supply_UserId
    //        };

    //        window._kbh_chat_Supply_OccupantId = _res.data.chat_Supply_OccupantId;
    //        window._kbh_chat_Demand_OccupantId = _res.data.chat_Demand_OccupantId;
    //        window._kbh_meeting_supply_UserId = _res.data.supply_UserId;
    //        window._kbh_meeting_demand_UserId = _res.data.demand_UserId;
    //        window._kbh_meeting_id = _res.data.id;

    //        // type: 2 === PublicDialog === Private Group
    //        ChatService.createPublicDialog_V2(params.occupants_ids, params.name, null)
    //            .then((newDialog) => {

    //                ChatService.sendChatAlertOnCreate_V2(newDialog);

    //                const ccData = {
    //                    Chat_MeetingId: newDialog.id + "",
    //                    Id: window._kbh_meeting_id,
    //                    Supply_UserId: window._kbh_meeting_supply_UserId,
    //                    Demand_UserId: window._kbh_meeting_demand_UserId,
    //                    RelatedTo_MeetingResaved_Id: "-1"
    //                };

    //                (async () => {
    //                    const ___res = await meetingService.updateMeetingWithCCId(ccData);
    //                    if (___res.success === true) {
    //                        //this.props.history.push("/meetings-archive");

    //                        if (this.state.meetingType === "0") {
    //                            //const ___diaglog = await ChatService.fetchDialogs_Really_ById_V2(ccData.Chat_MeetingId);
    //                            //ChatService.setSelectDialog(___diaglog);
    //                            ChatService.setSelectDialog(newDialog);
    //                            this.props.history.push("/message-chat/" + newDialog.id);
    //                        }
    //                        if (this.state.meetingType === "1") {
    //                            this.startVoiceCall(window._kbh_chat_Supply_OccupantId);
    //                        }
    //                        if (this.state.meetingType === "2") {
    //                            this.startCall(window._kbh_chat_Supply_OccupantId);
    //                        }

    //                    }
    //                    else {
    //                        toast.error("Error: " + ___res.message);
    //                    }
    //                })();

    //            })
    //            .catch((error) => {
    //                toast.error("Error: " + error);
    //            });
    //    }
    //    else {
    //        this.props.history.push("/meetings-archive");
    //    }
    //}


    //startCall = (chat_Supply_OccupantId) => {
    //    let {
    //        selectedUsersIds,
    //        closeSelect,
    //        initRemoteStreams,
    //        setLocalStream,
    //        unselectUser,
    //        selectUser
    //    } = this.props;

    //    selectUser(chat_Supply_OccupantId);
    //    selectedUsersIds.push(chat_Supply_OccupantId);

    //    if (selectedUsersIds.length === 0) {
    //        CallService.showToast('Select at less one user to start Videocall');
    //    }
    //    else {
    //        closeSelect();
    //        initRemoteStreams(selectedUsersIds);
    //        CallService.startCall(selectedUsersIds).then(setLocalStream);
    //    }
    //};


    //startVoiceCall = (chat_Supply_OccupantId) => {
    //    let {
    //        selectedUsersIds,
    //        closeSelect,
    //        initRemoteStreams,
    //        setLocalStream,
    //        unselectUser,
    //        selectUser
    //    } = this.props;

    //    selectUser(chat_Supply_OccupantId);
    //    selectedUsersIds.push(chat_Supply_OccupantId);

    //    if (selectedUsersIds.length === 0) {
    //        CallService.showToast('Select at less one user to start Videocall');
    //    }
    //    else {
    //        closeSelect();
    //        initRemoteStreams(selectedUsersIds);
    //        CallService.startCall(selectedUsersIds, "AUDIO").then(setLocalStream);
    //    }
    //};


    //stopCall = () => {
    //    const { resetState } = this.props;

    //    CallService.stopCall();
    //    resetState();
    //};


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :


        // meetingType:
        // 0 == message
        // 1 == audio
        // 2 == video

        let className_Message = "consult-plans";
        let className_Audio = "consult-plans";
        let className_Video = "consult-plans";

        if (this.state.meetingType === "0")
            className_Message += " active";
        if (this.state.meetingType === "1")
            className_Audio += " active";
        if (this.state.meetingType === "2")
            className_Video += " active";


        return (
            <React.Fragment>
                <div className="doctor-single-page-wrapper w-100">

                    <LoadingPage/>


                    <div className="row p-0 m-0">
                        <div className="col-md-6 px-2 mt-3">
                            <div className="doctor-single-page h-100">
                                <div className="doctor-widge-wrapp">
                                    <div className="doctor-bg">
                                        <div className="row p-0 m-0">
                                            <div className="col-4 p-0 d-flex align-items-center">
                                                <div className="doctor-image mx-auto"
                                                    style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(this.state.consultant.avatar) + ")"}}>
                                                    {/*<span className="online-badge"></span>*/}
                                                </div>
                                            </div>
                                            <div className="col-8 px-2 d-flex align-items-center">
                                                <div className="doctor-single-page__body">
                                                    <h2>
                                                        {!isNullOrWhiteSpaceOrEmpty(this.state.consultant.title) &&
                                                            <span>
                                                                {this.state.consultant.title + " "}
                                                            </span>
                                                        }
                                                        {this.state.consultant.firstName + " "}
                                                        {this.state.consultant.lastName + " "}
                                                    </h2>
                                                    <p className="p-no m-0">
                                                        شماره پروانه :
                                                        <span>{this.state.consultant.licenseNumber}</span>
                                                    </p>
                                                    <div className="rate-teaser">
                                                        <i className="fas fa-star"></i>
                                                        <span>
                                                            {this.state.consultant.count_Star}
                                                            از
                                                            5
                                                            امتیاز
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 p-0">
                                        <div className="doctor-single-page__body w-100">
                                            <h3>زمینه‌های تخصصی روانشناس</h3>
                                            <p style={{wordBreak: "break-word"}}>
                                                {this.state.consultant.summary}
                                            </p>

                                            <h3>ویدئو معرفی</h3>

                                            <video
                                                className="intro-video"
                                                controls
                                                currentTime={0.0}
                                                src={baseUrlAddress.getFilesBaseUrl() + this.state.consultant.resume_Video}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 px-2 mt-3">
                            <div className="doctor-single-page-plans h-100">
                                <h3>انتخاب نوع جلسه</h3>

                                {this.state.consultant.isAbilityTo_TextMessage &&
                                    <div className={className_Message} data-name="0"
                                         onClick={this.changeMeetingTypeHandle} style={{cursor: "pointer"}}>
                                        <div className="plan-type">
                                            <i className="fas fa-comments"></i>
                                            <span>
                                                چت و ویس
                                            </span>
                                        </div>
                                        <div className="plan-price">
                                            هزینه کل جلسه : {this.state.allCostChat} دلار
                                        </div>
                                        <p>
                                            بعد از ثبت درخواست، منتظر تایید روانشناس مربوطه باشید و سپس در منو ”لیست
                                            جلسات“ دکمه ”شروع جلسه“ را بزنید.
                                        </p>
                                    </div>
                                }
                                {this.state.consultant.isAbilityTo_AudioCall &&
                                    <div className={className_Audio} data-name="1"
                                         onClick={this.changeMeetingTypeHandle} style={{cursor: "pointer"}}>
                                        <div className="plan-type">
                                            <i className="fas fa-phone-volume"></i>
                                            <span>
                                                تماس صوتی
                                            </span>
                                        </div>
                                        <div className="plan-price">
                                            هزینه جلسه : {this.state.allCostVoice} دلار
                                        </div>
                                        <p>
                                        </p>
                                        {/*<p>*/}
                                        {/*    هزینه اولیه : {this.state.fixCostVoice} دلار*/}
                                        {/*</p>*/}
                                        {/*<p>*/}
                                        {/*    بازگشت هزینه به حساب کاربری به ازای هر دقیقه زودتر تمام کردن جلسه*/}
                                        {/*    : {this.state.perMinCostVoice} دلار*/}
                                        {/*</p>*/}
                                        {/*<p>*/}
                                        {/*    هزینه به ازای هر دقیقه : {this.state.perMinCostVoice} دلار*/}
                                        {/*</p>*/}
                                        <p>
                                            بعد از ثبت درخواست، منتظر تایید روانشناس مربوطه باشید و سپس در منو ”لیست
                                            جلسات“ دکمه ”شروع جلسه“ را بزنید.
                                        </p>
                                    </div>
                                }
                                {this.state.consultant.isAbilityTo_VideoCall &&
                                    <div className={className_Video} data-name="2"
                                         onClick={this.changeMeetingTypeHandle} style={{cursor: "pointer"}}>
                                        <div className="plan-type">
                                            <i className="fas fa-video"></i>
                                            <span>
                                                تماس تصویری
                                            </span>
                                        </div>
                                        <div className="plan-price">
                                            هزینه جلسه : {this.state.allCostVideo} دلار
                                        </div>
                                        <p>
                                        </p>
                                        {/*<p>*/}
                                        {/*    هزینه اولیه : {this.state.fixCostVideo} دلار*/}
                                        {/*</p>*/}
                                        {/*<p>*/}
                                        {/*    بازگشت هزینه به حساب کاربری به ازای هر دقیقه زودتر تمام کردن جلسه*/}
                                        {/*    : {this.state.perMinCostVideo} دلار*/}
                                        {/*</p>*/}
                                        {/*<p>*/}
                                        {/*    هزینه به ازای هر دقیقه : {this.state.perMinCostVideo} دلار*/}
                                        {/*</p>*/}
                                        <p>
                                            بعد از ثبت درخواست، منتظر تایید روانشناس مربوطه باشید و سپس در منو ”لیست
                                            جلسات“ دکمه ”شروع جلسه“ را بزنید.
                                        </p>
                                    </div>
                                }

                                <div className="row p-0 mx-0 my-3">
                                    <div className="col-12 px-1">
                                        {this.state.ncatRequierd &&
                                            <>
                                                <div class="form-group">
                                                    <label for="slct1">انتخاب حوزه مشاوره :</label>
                                                    <select class="form-control" id="nCatSelector" name="nCatSelector"
                                                            onChange={this.changenCat}>
                                                        <option key="0" value="0">
                                                            انتخاب کنید ...
                                                        </option>
                                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                        {this.state.ncatList.map(item => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                    </select>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>


                                <div className="row p-0 mx-0 my-3">
                                    <div className="col-12 px-1">
                                        {this.state.ntype === "o" &&
                                            <button type="button" className="btn btn-start-consult rounded"
                                                    onClick={this.creatNewMeetingHandle}>
                                                شروع جلسه (چند دقیقه آتی)
                                            </button>
                                        }
                                        {this.state.ntype === "r" &&
                                            <button type="button" className="btn btn-reserve-offline rounded"
                                                    onClick={this.creatNewResvMeetingHandle}>
                                                درخواست رزرو جلسه
                                            </button>
                                        }
                                    </div>
                                </div>

                                {(this.state.mustPay !== "-1") &&
                                    < div className="alert alert-light" role="alert"
                                          style={{backgroundColor: "#ffdbdb"}}>
                                        برای تشکیل جلسه مورد نظر، مبلغ '{this.state.mustPay}' دلار کم دارید که باید حساب
                                        خود را شارژ کنید
                                        <Link className="btn btn-primary w-100 rounded"
                                              to={"/transaction/" + this.state.mustPay}>پرداخت</Link>
                                    </div>
                                }

                                <div className="alert alert-light" role="alert">
                                    <i className="fas fa-lock"></i>
                                    تمامی جلسات مشاوره به طریقی رمزگذاری شده است که تنها شما و روانشناس به آن دسترسی
                                    دارید.
                                    در صورت مواجهه با هرگونه ابهام یا مشکل، از طریق منو ”ارتباط با پشتیبانی“ یاری‌وی با
                                    ما در تماس باشید.
                                    <br/>
                                    <i className="fas fa-check-circle"></i>
                                    مدت زمان جلسات تصویری
                                    &nbsp;{this.state.durationTime}&nbsp;
                                    دقیقه می باشد
                                </div>

                            </div>

                        </div>
                        <div className="col-md-6 px-2 mt-3">
                            <div className="doctor-single-page-plans h-100vh h-100">
                                <h3>درباره من</h3>
                                <div
                                    dangerouslySetInnerHTML={{__html: this.state.consultant.description}}
                                    style={{wordBreak: "break-word"}}
                                />
                                <h3>حوزه های فعالیت</h3>
                                <div
                                    dangerouslySetInnerHTML={{__html: this.state.consultant.description2}}
                                    style={{wordBreak: "break-word"}}
                                />
                                <h3>رویکردها</h3>
                                <div
                                    dangerouslySetInnerHTML={{__html: this.state.consultant.description3}}
                                    style={{wordBreak: "break-word"}}
                                />
                                <h3>سوابق علمی</h3>
                                <div
                                    dangerouslySetInnerHTML={{__html: this.state.consultant.description4}}
                                    style={{wordBreak: "break-word"}}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 px-2 mt-3">
                            <div className="doctor-single-page-plans h-100vh h-100">
                                <h3>نظرات کاربران</h3>

                                {this.state.comments.map(item => (
                                    <div className="comment">
                                        <div className="row p-0 m-0">
                                            <div
                                                className="col-3 col-lg-2 p-0 d-flex align-items-start justify-content-md-center">
                                                <div className="img-wrapper">
                                                </div>
                                            </div>
                                            <div className="col-9 col-lg-10 p-0">
                                                <h4>
                                                    {!isNullOrWhiteSpaceOrEmpty(item.create_ByUserName)
                                                        ? <span> {item.create_ByUserName} </span>
                                                        : <span> کاربر بدون نام </span>
                                                    }
                                                </h4>
                                                <p>
                                                    {item.commentText}
                                                </p>
                                                <span className="date-published">
                                                    {item.createDateTimeString}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>
                </div>


            </React.Fragment>
        );
    }
}


// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    loadingState: state.loadingReducer

});

// this.props.refreshTokenAction()
const map_DispatchAction_to_Props = {
    refreshTokenAction,
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(withRouter(ConsultantDetail));
