import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as notifService from "../../services/notification/notificationService";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class Notification extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            notifs: [],
            pageSize: 9,
            currentPage: 1
        };
    }

    async componentDidMount() {

        this.props.startLoadingAction();

        const reqData = { pageindex: 1 };
        const _obj = await notifService.getList(reqData);
        if (_obj.success === true) {
            if (_obj.data !== null) {
                this.setState({ notifs: _obj.data });
            }
        }

        this.props.finishLoadingAction();

        store.dispatch({
            type: menuActionTypes.Notification
        });
    }



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div className="doctors-page">

                    <LoadingPage />


                    <div className="col-12 px-2">

                        <div className="notifs">
                            <div className="notifications notifications-page">
                                <div className="card-body">
                                    <ul>

                                        {this.state.notifs.length === 0
                                            ?
                                            (
                                                <>
                                                    <div className="col-12 mx-auto">
                                                        <br />
                                                        <center > هیچ اعلانی وجود ندارد </center>
                                                        <br />
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>

                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                    {this.state.notifs.map(item => (

                                                        <li key={item.id}>
                                                            <a className="notification">
                                                                {(item.isSeen === false) &&
                                                                    <div className="dot red">new</div>
                                                                }
                                                                {(item.isSeen === true) &&
                                                                    <div className="dot green">read</div>
                                                                }
                                                                <span className="title">
                                                                    {item.title}
                                                                    <br />
                                                                    {item.description}
                                                                </span>

                                                                { /* <span className="date">{item.createDateTime.slice(0, 16).replace("T", " , ")}</span> */}
                                                                <span className="date">{item.createDateTime_Miladi_InLocal}</span>

                                                            </a>
                                                        </li>
                                                    ))}
                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                </>
                                            )
                                        }


                                    </ul>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Notification));
