import React, { Component } from "react";

import BlogList from "./blogList";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import * as blogService from "../../services/blog/blogService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

class Blog extends Component {
    constructor(props) {
        super(props);


        // برای بار اولی که اپ بالا می آید
        this.state = {
            isSearchShow: false,
            allCount: 0,
            imageCount: 0,
            videoCount: 0,
            aduioCount: 0,
            category: 0
        };
    }

    async componentDidMount() {
        store.dispatch({
            type: menuActionTypes.Blog
        });

        const _result = await blogService.getBlogTypesCount();
        if (_result.success)
            this.setState({
                allCount: _result.data.all_Count,
                imageCount: _result.data.image_Count,
                videoCount: _result.data.video_Count,
                aduioCount: _result.data.aduio_Count,
            });

    }

    funcToggleSearchShow = () => {
        if (this.state.isSearchShow) {
            this.setState({ isSearchShow: false });
        }
        else if (!this.state.isSearchShow) {
            this.setState({ isSearchShow: true });
        }
    }

    changeCategoryHandle = (type) => {
        this.setState({ category: type });
        console.log("changeCategoryHandle");
        console.log(type);
    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        let classSerchBox = "";
        if (this.state.isSearchShow) {
            classSerchBox = "blog-search show-search-area";
        }
        else {
            classSerchBox = "blog-search";
        }

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <button
                    className="btn btn-shortcuts btn-search"
                    role="button"
                    onClick={() => this.funcToggleSearchShow()}
                    style={{
                        top: "10px",
                        right: "55px",
                        position: "absolute"
                    }}
                >
                    <i className="fas fa-search"></i>
                </button>


                <section className={classSerchBox}>
                    <div className="container">
                        <div className="col-12 search-home p-0">

                            <form className="form-inline search-form-home">
                                <input className="form-control" type="search" placeholder="مطلب موردنظر خود را جستجو کن." aria-label="Search" />
                                <button className="btn" type="submit"><i className="fas fa-search"></i></button>
                            </form>

                        </div>
                    </div>
                </section>


                <div className="blog-page">
                    <div className="row p-0 m-0">
                        <div className="col-12 p-0">
                            <div className="blog-cats">
                                <div className="row p-0 m-0">
                                    <div className="col-md-6 px-1">
                                        <div className="blog-cat-card" onClick={() => this.changeCategoryHandle(0)}>
                                            <p>همه<span>({this.state.allCount})</span></p>
                                            <i className="fas fa-th"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6 px-1">
                                        <div className="blog-cat-card" onClick={() => this.changeCategoryHandle(1)}>
                                            <p>مقاله<span>({this.state.imageCount})</span></p>
                                            <i className="fas fa-book"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6 px-1">
                                        <div className="blog-cat-card" onClick={() => this.changeCategoryHandle(2)}>
                                            <p>پادکست<span>({this.state.aduioCount})</span></p>
                                            <i className="fas fa-headphones-alt"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6 px-1" onClick={() => this.changeCategoryHandle(3)}>
                                        <div className="blog-cat-card">
                                            <p>ویدئو<span>({this.state.videoCount})</span></p>
                                            <i className="fas fa-video"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 p-0">
                            <div className="blog-posts">
                                <div className="row p-0 m-0">

                                    <BlogList category={this.state.category} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Blog;
