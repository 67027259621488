import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

import * as axiosAuthHelper from "../../helper/axiosHeader/axiosAuthHelper";
import * as jwtUserTokenHelper from "../../helper/localStorage/jwtUserTokenHelper";
import * as refreshTokenHelper from "../../helper/localStorage/refreshTokenHelper";

import * as accountActionTypes from "../actionTypes/accountActionTypes";
import * as accountService from "../../services/account/accountService";

//import ccAuthService from '../../services/cc-message/auth-service';


// loginUser :
export const userLoginAction = (userData) => {
    return async (dispatch, getState) => {

        dispatch({
            type: accountActionTypes.UserLoginStarted
        });

        try {
            const _result = await accountService.userLogin(userData);
            if (_result.success) {
                const accessJwtToken = _result.data.access_token;
                const refreshToken = _result.data.refresh_token;

                refreshTokenHelper.setTokenToLocalStorage(refreshToken);
                jwtUserTokenHelper.setTokenToLocalStorage(accessJwtToken);
                axiosAuthHelper.setAuthTokenToAxiosHeader(accessJwtToken);

                const _currUser = jwtDecode(accessJwtToken);
                dispatch({
                    type: accountActionTypes.UserLoginSuccess,
                    payload: _currUser
                });

                //try {
                //    const _ccUsrId = await CC_Login({ login: _currUser.UserName, password: _currUser.UserName });
                //    if (_ccUsrId === 0) {
                //        FailLoginOpt();

                //        dispatch({
                //            type: accountActionTypes.UserLoginFailure,
                //            payload: "FailLogin in CC_Login"
                //        });

                //        toast.error("اتصال با سرور مشاوره برقرار نشد");

                //        return false;
                //    }

                //    const _ckeckedChanged = CC_ChangeUserInfo(_ccUsrId);
                //    if (_ckeckedChanged === false) {
                //        FailLoginOpt();

                //        dispatch({
                //            type: accountActionTypes.UserLoginFailure,
                //            payload: "FailLogin in CC_ChangeUserInfo"
                //        });

                //        toast.error("اتصال با سرور مشاوره برقرار نشد");

                //        return false;
                //    }
                //}
                //catch (eeexxx) {
                //    toast.error("اتصال با سرور مشاوره برقرار نشد");

                //    FailLoginOpt(eeexxx);

                //    dispatch({
                //        type: accountActionTypes.UserLoginFailure,
                //        payload: eeexxx.message
                //    });

                //    return false;
                //}

                //toast.success("Login Successful");
                return true;
            }
            else {
                FailLoginOpt();

                dispatch({
                    type: accountActionTypes.UserLoginFailure,
                    payload: _result.message
                });

                toast.error(_result.message);

                return false;
            }
        }
        catch (error) {
            FailLoginOpt();

            dispatch({
                type: accountActionTypes.UserLoginFailure,
                payload: error.message
            });

            toast.error(error.message);

            return false;
        }

    };
};


function FailLoginOpt() {
    refreshTokenHelper.removeTokenFromLocalStorage();
    jwtUserTokenHelper.removeTokenFromLocalStorage();
    axiosAuthHelper.removeAuthTokenFromAxiosHeader();
}




// refreshToken :
export const refreshTokenAction = () => {
    return async (dispatch, getState) => {

        try {
            const _refTokenData = {
                RefreshToken: refreshTokenHelper.getTokenFromLocalStorage()
            };
            const _result = await accountService.refreshToken(_refTokenData);

            if (_result.success) {
                const accessJwtToken = _result.data.access_token;
                const refreshToken = _result.data.refresh_token;

                refreshTokenHelper.setTokenToLocalStorage(refreshToken);
                jwtUserTokenHelper.setTokenToLocalStorage(accessJwtToken);
                axiosAuthHelper.setAuthTokenToAxiosHeader(accessJwtToken);

                const _currUser = jwtDecode(accessJwtToken);
                dispatch({
                    type: accountActionTypes.UserLoginSuccess,
                    payload: _currUser
                });

                //toast.success("Changed Successful");
                return true;
            }
            else {
                refreshTokenHelper.removeTokenFromLocalStorage();
                jwtUserTokenHelper.removeTokenFromLocalStorage();
                axiosAuthHelper.removeAuthTokenFromAxiosHeader();

                dispatch({
                    type: accountActionTypes.UserLoginFailure,
                    payload: _result.message
                });

                toast.error(_result.message);
                return false;
            }
        }
        catch (error) {
            refreshTokenHelper.removeTokenFromLocalStorage();
            jwtUserTokenHelper.removeTokenFromLocalStorage();
            axiosAuthHelper.removeAuthTokenFromAxiosHeader();

            dispatch({
                type: accountActionTypes.UserLoginFailure,
                payload: error.message
            });

            toast.error(error.message);
            return false;
        }

    };
};


//// Log user out
//export const userLogoutAction = () => dispatch => {
//refreshTokenHelper.removeTokenFromLocalStorage();
//jwtUserTokenHelper.removeTokenFromLocalStorage();
//axiosAuthHelper.removeAuthTokenFromAxiosHeader();
//dispatch({
//    type: accountActionTypes.UserLoginFailure,
//    payload: error.message
//});
//};


//// user register
//export const userRegisterAction = (userData, history) => dispatch => {
//    axios
//        .post("/api/users/register", userData)
//        .then(res => history.push("/login"))
//        .catch(err =>
//            dispatch({
//                type: GET_ERRORS,
//                payload: err.response.data
//            })
//        );
//};


async function CC_Login(dataUser) {
    //await ccAuthService.initConfig_WithoutLogin();
    //let ccUserId = 0;
    //try {
    //    ccUserId = await ccAuthService.signIn(dataUser);
    //    //toast.success("CC_Login Successfully");
    //}
    //catch (error) {
    //    ccUserId = 0;
    //    toast.error("اتصال با سرور مشاوره برقرار نشد");
    //    toast.error(error.message);

    //    FailLoginOpt();

    //}
    ////.then((returned_ccUserId) => {
    ////    toast.success("CC_Login Successfully");
    ////    return returned_ccUserId;
    ////    //return true;
    ////})
    ////.catch(error => {
    ////    toast.error(JSON.stringify(error));
    ////    console.log(JSON.stringify(error));
    ////    //return false;
    ////    return 0;
    ////});
    //return ccUserId;
    return 0;
}


async function CC_ChangeUserInfo(ccUserId) {
    //let editUserInfo = {
    //    UserName: "",
    //    CCUserId: ccUserId + ""
    //};
    //try {
    //    const _result = await accountService.demandUserCCEdit(editUserInfo);
    //    if (_result.success) {
    //        //toast.success("CC_User Checked & Updated Successfully");
    //        return true;
    //    }
    //    else {
    //        toast.error("اتصال با سرور مشاوره برقرار نشد");
    //        toast.error(_result.message);
    //        FailLoginOpt();

    //        return false;
    //    }
    //}
    //catch (error) {
    //    toast.error(error.message);
    //    FailLoginOpt();

    //    return false;
    //}
    return true;
}

