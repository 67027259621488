import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";

import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

class MeetingsReserveArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            meetings: [],
            reserves: [],
            pageSize: 100,
            currentPage: 1,
            intervalId: null
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        const _List = await meetingResavationService.getList();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ reserves: _List.data });

    }

    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div class="w-100">
                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                    {this.state.reserves.slice(0, 3).map(item => (

                        <div className="appointment" key={item.id}>
                            <div className="row p-0 m-0 w-100 mt-2">
                                <div className="col-md-7 mb-2 mb-md-0 p-0">
                                    <div className="col-12 d-flex align-items-center justify-content-start p-0 m-0">
                                        <img className="image" src={baseUrlAddress.getFilesBaseUrl() + item.supply_Avatar} />
                                        <div className="w-100 d-flex flex-column mr-2">
                                            <span className="name">{item.supply_UserName}</span>
                                            <span className="title p-0">{item.statusMessage}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 p-0">
                                    <div className="col-12 p-0 d-flex justify-content-center justify-content-md-end mb-2">
                                        <span className="date ml-2">
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                            &nbsp;
                                            {item.start_Proposal_FullDate_InLocal_Miladi}
                                        </span>
                                        <span className="time">
                                            <i className="fas fa-clock"></i>
                                            {" " + item.start_Proposal_FromHour_InLocal + " "}
                                        </span>
                                    </div>
                                    <div className="col-12 p-0 labels-status d-flex align-items-center justify-content-center justify-content-md-end">

                                        {(item.status === 0) &&
                                            <Link to={ "/meetings-reserve-archive" } className="is-waiting">
                                            در انتظار تایید
                                            </Link>
                                        }
                                        {(item.status === 1) &&
                                            <Link to={"/meetings-reserve-archive"} className="is-active">
                                            پذیرفته شده
                                                                    </Link>
                                        }
                                        {(item.status === 2) &&
                                            <Link to={"/meetings-reserve-archive"} className="not-active">
                                            رد شده
                                    </Link>
                                        }
                                        {(item.status === 3) &&
                                            <Link to={"/meetings-reserve-archive"} className="is-waiting">
                                            در حال بررسی
                                    </Link>
                                        }
                                        {(item.status === 4) &&
                                            <Link to={"/meetings-reserve-archive"} className="is-active">
                                            پذیرفته شده
                                             
                                                                    </Link>
                                        }
                                        {(item.status === 5) &&
                                            <Link to={"/meetings-reserve-archive"} className="not-active">
                                            حذف شده

                                    </Link>
                                        }



                                    </div>
                                </div>

                            </div>
                        </div>

                    ))}
                    { /* ----------- key={item._id} must be for list in react ---------------- */}





                    {(this.state.reserves.length === 0) &&

                        <div className="appointment">
                            <div className="col-12 p-0">
                                <div className="col-12 p-0 d-flex justify-content-center mb-2 text-center">
                                    <span className="date ml-2">از همین جا مشاوره رو شروع کنید</span>
                                </div>
                                <div className="col-12 p-0 labels-status d-flex align-items-center justify-content-center">
                                    <Link to="/categories" className="is-active">
                                        شروع مشاوره
                            </Link>
                                </div>
                            </div>
                        </div>

                    }

                </div>
            </React.Fragment >
        );
    }
}

export default withRouter(MeetingsReserveArchive);
