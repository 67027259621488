import * as baseUrlAddress from "./baseUrlAddress";

export const getProfile = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/GetUser";
export const getProfileSummary = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/GetUserSummary";

export const setProfile = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/EditUser";
export const setVerify = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/EditVerify";

//export const setProfileImage = portalBaseUrl + "/Admin/Account/UploadImage";
export const setProfileImage = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/UploadImage";
export const getTimeZone = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/GetTimeZone";
export const setTimeZone = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/SetTimeZone";
export const setPushNotifClientId = baseUrlAddress.getApiBaseUrl() + "/DemandUsers/SetPushNotifClientId";
