import * as loadingActionTypes from "../actionTypes/loadingActionTypes";

export const startLoadingAction = () => {
    return async (dispatch, getState) => {

        dispatch({
            type: loadingActionTypes.StartLoading
        });

    };
};

export const finishLoadingAction = () => {
    return async (dispatch, getState) => {

        dispatch({
            type: loadingActionTypes.FinishLoading
        });

    };
};

