import axios from "axios";

export function setAuthTokenToAxiosHeader(accessJwtToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessJwtToken;
}

export function removeAuthTokenFromAxiosHeader() {
    axios.defaults.headers.common["Authorization"] = "";
    delete axios.defaults.headers.common["Authorization"];
}
