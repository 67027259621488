import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import * as accountService from "../../services/account/accountService";
import headerPic from "../../theme/assets/images/logo.png";



import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingAccount from "./loadingAccount";



// OTP :
import "../../theme/assets/css/otp.css";
const CODE_LENGTH = new Array(6).fill(0);  // OTP

class VerifyCode extends Component {
    constructor(props) {

        super(props);

        this.state = {
            showBtnSendCode: true,
            userUuid: "",
            userMobileEmail: "",
            password: "",
            focused: false,   // OTP
            // ------------------------------------------------------------------
            time: {}, // Timer
            seconds: 180, // Timer
            // ------------------------------------------------------------------
        };

        // ------------------------------------------------------------------
        this.timer = 0; // Timer
        this.startTimer = this.startTimer.bind(this); // Timer
        this.countDown = this.countDown.bind(this); // Timer
        // ------------------------------------------------------------------

    }

    componentDidMount() {

        let nid = this.props.match.params.nid; // nid is string
        let nid2 = this.props.match.params.nid2; // nid2 is string
        if (nid === undefined || nid === "undefined" || nid === "0")
            this.props.history.push("/register");
        this.setState({ userUuid: nid });
        this.setState({ userMobileEmail: decodeURIComponent(nid2) });



        // Timer:
        // ------------------------------------------------------------------
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        // ------------------------------------------------------------------


        // از همین اول شروع 3 دقیقه:
        this.startTimer();

    }

    ////////////////////////////////////////
    // Timer ///////////////////////////////
    // Timer ///////////////////////////////
    ////////////////////////////////////////

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
            this.setState({ showBtnSendCode: false });
        }
        else {
            this.setState({ showBtnSendCode: true });
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            this.setState({ showBtnSendCode: true });
        }
    }

    ////////////////////////////////////////
    // Timer ///////////////////////////////
    // Timer ///////////////////////////////
    ////////////////////////////////////////



    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        let userData = {
            Uuid: this.state.userUuid,
            MobileEmail: this.state.userMobileEmail,
            Password: this.state.password,
        };

        try {
            const _result = await accountService.checkOTP(userData);
            if (_result.success) {
                toast.success("فعال سازی کاربر با موفقیت انجام شده است، شما می توانید وارد پنل شوید ");

                this.props.finishLoadingAction();

                this.props.history.push("/login");
            }
            else {
                toast.error(_result.message);
            }
        }
        catch (error) {
            toast.error(error.message);
        }

        this.props.finishLoadingAction();


    };




    // OTP :
    // OTP :  ----------------------------------------------------------------------------
    // OTP :  ----------------------------------------------------------------------------
    // OTP :
    otpInput = React.createRef();   // OTP

    handleClick = () => {
        this.otpInput.current.focus();
    };
    handleFocus = () => {
        this.setState({ focused: true });
    };
    handleBlur = () => {
        this.setState({
            focused: false,
        });
    };
    handleKeyUp = e => {
        if (e.key === "Backspace") {
            this.setState(state => {
                return {
                    password: state.password.slice(0, state.password.length - 1),
                };
            });
        }
    };
    handleChange = e => {
        const value = e.target.value;

        this.setState(state => {
            if (state.password.length >= CODE_LENGTH.length) return null;
            return {
                password: (state.password + value).slice(0, CODE_LENGTH.length),
            };
        });
    };
    // OTP :  ----------------------------------------------------------------------------
    // OTP :  ----------------------------------------------------------------------------






    repeatCode = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();

        let userData = {
            Uuid: this.state.userUuid,
        };

        try {
            const _result = await accountService.repeatCode(userData);
            if (_result.success) {
                toast.success(" کد یکبارمصرف مجددا ارسال شد ");

                this.setState({
                    seconds: 180,
                });
                this.timer = 0;
                this.startTimer();

                this.props.finishLoadingAction();
            }
            else {
                toast.error(_result.message);
            }
        }
        catch (error) {
            toast.error(error.message);
        }

        this.props.finishLoadingAction();
    };





    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :


        // OTP :
        const { password, focused } = this.state;  // OTP
        const _passWords = password.split("");  // OTP
        const selectedIndex = _passWords.length < CODE_LENGTH.length ? _passWords.length : CODE_LENGTH.length - 1;  // OTP
        const hideInput = !(_passWords.length < CODE_LENGTH.length);  // OTP

        return (
            <React.Fragment>

                <LoadingAccount />


                <div className="login-page d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center">
                        <div className="bg-logo">
                            <img src={headerPic} className="logo-login"  width="70px" />
                        </div>
                    </div>
                    <div className="content col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto p-0">
                        <div className="login-container">
                            <form className="w-100" onSubmit={this.onSubmit}>

                                <div className="alert alert-success text-center" role="alert">
                                    کد تایید به {this.state.userMobileEmail}  ارسال گردید
                                </div>

                                <label className="d-inline-block w-100 text-center">لطفا کد ارسال شده را وارد نمایید.</label>
                                <input
                                    ref={this.password}
                                    type="text"
                                    id="password"
                                    name="password"
                                    defaultValue={this.state.password}
                                    onChange={this.onChange}
                                    className="activation-code-input w-100" />

                                <div className="appWrap">
                                    <div className="myWrap" onClick={this.handleClick}>
                                        <input
                                            value=""
                                            ref={this.otpInput}
                                            onChange={this.handleChange}
                                            onKeyUp={this.handleKeyUp}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            className="myinput"
                                            style={{
                                                width: "32px",
                                                top: "0px",
                                                bottom: "0px",
                                                left: `${selectedIndex * 32}px`,
                                                opacity: hideInput ? 0 : 1,
                                            }}
                                        />
                                        {CODE_LENGTH.map((v, index) => {
                                            const selected = _passWords.length === index;
                                            const filled = _passWords.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                                            return (
                                                <div className="mydisplay">
                                                    {_passWords[index]}
                                                    {(selected || filled) && focused && <div className="myshadows" />}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div class="w-100 d-flex justify-content-center">
                                    {this.state.showBtnSendCode === true
                                        ?
                                        <a onClick={this.repeatCode} id="sendcode" className="btn btn-link send-again" style={{ cursor: "pointer" }}>
                                            کدی دریافت نشد؟ ارسال مجدد
                                            &nbsp;
                                        </a>
                                        :
                                        <a className="btn btn-link send-again">
                                            {this.state.time.m}
                                            :
                                            {this.state.time.s}
                                        </a>
                                    }
                                </div>

                                <button type="submit" className="btn btn-load-effect btn-sign-in">
                                    تایید
                                </button>

                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer
});

// this.props.userLoginAction()
// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(VerifyCode);
