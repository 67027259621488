import React, { Component } from "react";
import Media from "react-media"
import { BrowserRouter as Router, withRouter, Switch, Route, Link, Redirect } from 'react-router-dom';

import PanelAllRoutes from "./panelAllRoutes";
import AlertPage from "./alertPage";
import SidebarMenu from "../menu/sidebarMenu";
import NavbarMenu from "../menu/navbarMenu";
import Footer from "../footer/footer";
import Header from "../header/header";
//import ChatService from "../../services/cc-message/chat-service";
//import AuthService from "../../services/cc-message/auth-service";

import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";
import TourProvider from "reactour";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

class MainLayout extends Component {
    constructor(props) {
        super(props);
        //props.xyz
        //props.selectedUsersIds
        //props.closeSelect
        //props.initRemoteStreams
        //props.setLocalStream
        //props.isActiveSelect
        //props.isActiveCall
        //props.resetState
        //props.unselectUser
        //props.selectUser
        //props.currentCall_Demand_ChatOpcId
        //props.currentCall_Supply_ChatOpcId
        //props.currentCall_Demand_UserId
        //props.currentCall_Supply_UserId
        //props.currentCall_Meeting_Id
        //props.changeMeetingParameters

        this.state = {
            toggleNavbarMenu_isOpen: false,
            isTourOneOpen: false,
            isTourTwoOpen: false,
            isTourThreeOpen: false,
            isTourFourOpen: false,
            isTourFiveOpen: false
        };

        //console.log("main layout : " + this.props.selectedUsersIds.length)
        //console.log("main layout : " + this.props.isActiveCall)

    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)


    closeTourOne = () => {
        this.setState({ isTourOneOpen: false });
    };
    openTourOne = () => {
        this.setState({ isTourOneOpen: true });
    };
    closeTourTwo = () => {
        this.setState({ isTourTwoOpen: false });
    };
    openTourTwo = () => {
        this.setState({ isTourTwoOpen: true });
    };
    closeTourThree = () => {
        this.setState({ isTourThreeOpen: false });
    };
    openTourThree = () => {
        this.setState({ isTourThreeOpen: true });
    };
    closeTourFour = () => {
        this.setState({ isTourFourOpen: false });
    };
    openTourFour = () => {
        this.setState({ isTourFourOpen: true });
    };
    closeTourFive = () => {
        this.setState({ isTourFiveOpen: false });
    };
    openTourFive = () => {
        this.setState({ isTourFiveOpen: true });
    };


    handleFunc_toggleNavbarMenu = (checkOpenOrClose) => {
        console.warn("checkOpenOrClose : " + checkOpenOrClose);
        this.setState({ toggleNavbarMenu_isOpen: checkOpenOrClose });
    };

    //omponentDidMount() {
    async componentDidMount() {
        const _currentUser = currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        if (_currentUser === null) {
            this.props.history.push("/login");
        }
        else {
            //const result = await AuthService.init();
            //if (result === false) {
            //    this.props.history.push("/login");
            //}
            //else {
            //    ChatService.setUpListeners();
            //}
        }
    }

    render() {
        return (
            <React.Fragment >
                <TourProvider
                    onRequestClose={this.closeTourOne}
                    steps={tourConfigOne}
                    isOpen={this.state.isTourOneOpen}
                    className="helper"
                    rounded={5}
                    rtl
                    scrollSmooth={true}
                    disableInteraction={true}
                    disableFocusLock={true}
                    disableDotsNavigation={true}
                    onClickHighlighted={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClickMask={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClick={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    closeWithMask={false}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />
                <TourProvider
                    onRequestClose={this.closeTourTwo}
                    steps={tourConfigTwo}
                    isOpen={this.state.isTourTwoOpen}
                    className="helper"
                    rounded={5}
                    rtl
                    scrollSmooth={true}
                    disableInteraction={true}
                    disableFocusLock={true}
                    disableDotsNavigation={true}
                    onClickHighlighted={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClickMask={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClick={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    closeWithMask={false}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />
                <TourProvider
                    onRequestClose={this.closeTourThree}
                    steps={tourConfigThree}
                    isOpen={this.state.isTourThreeOpen}
                    className="helper"
                    rounded={5}
                    rtl
                    scrollSmooth={true}
                    disableInteraction={true}
                    disableFocusLock={true}
                    disableDotsNavigation={true}
                    onClickHighlighted={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClickMask={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClick={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    closeWithMask={false}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />
                <TourProvider
                    onRequestClose={this.closeTourFour}
                    steps={tourConfigFour}
                    isOpen={this.state.isTourFourOpen}
                    className="helper"
                    rounded={5}
                    rtl
                    scrollSmooth={true}
                    disableInteraction={true}
                    disableFocusLock={true}
                    disableDotsNavigation={true}
                    onClickHighlighted={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClickMask={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClick={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    closeWithMask={false}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />
                <TourProvider
                    onRequestClose={this.closeTourFive}
                    steps={tourConfigFive}
                    isOpen={this.state.isTourFiveOpen}
                    className="helper"
                    rounded={5}
                    rtl
                    scrollSmooth={true}
                    disableInteraction={true}
                    disableFocusLock={true}
                    disableDotsNavigation={true}
                    onClickHighlighted={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClickMask={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    onClick={e => {
                        e.stopPropagation()
                        console.log('No interaction at all');
                    }}
                    closeWithMask={false}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />


                {/*<button onClick={this.openTourOne}>*/}
                {/*    تور داشبورد*/}
                {/*</button>*/}
                {/*<button onClick={this.openTourTwo}>*/}
                {/*    تور لیست پزشکان*/}
                {/*</button>*/}
                {/*<button onClick={this.openTourThree}>*/}
                {/*    تور صفحه رزرو*/}
                {/*</button>*/}
                {/*<button onClick={this.openTourFour}>*/}
                {/*    تور صفحه لیست جلسات*/}
                {/*</button>*/}
                {/*<button onClick={this.openTourFive}>*/}
                {/*    استپ اضافه داشبورد در موبایل*/}
                {/*</button>*/}


                {/* -------------- Alert-Box : for recieved message alerts  ---------------------- */}
                <AlertPage />
                {/* -------------- Alert-Box : for recieved message alerts  ---------------------- */}


                {/* -------------- NavbarMenu -------------------------------------------------- */}
                <Media
                    query="(max-width: 991px)"
                    render={() =>
                        <NavbarMenu
                            isOpen={this.state.toggleNavbarMenu_isOpen}
                            handleFunc_toggleNavbarMenu={this.handleFunc_toggleNavbarMenu}
                        />}
                />
                {/* <NavbarMenu isOpen={this.state.toggleNavbarMenu_isOpen} handleFunc_toggleNavbarMenu={this.handleFunc_toggleNavbarMenu} /> */}
                {/* -------------- NavbarMenu -------------------------------------------------- */}

                <div className="content col-xl-11 mx-auto p-0">
                    <div className="row p-0 m-0">

                        <div className="col-lg-3 d-none d-lg-block mx-auto px-0">

                            {/* -------------- SidebarMenu -------------------------------------------------- */}
                            <Media
                                query="(min-width: 992px)"
                                render={() => <SidebarMenu />}
                            />
                            {/* <SidebarMenu /> */}
                            {/* -------------- SidebarMenu -------------------------------------------------- */}

                        </div>

                        <div className="col-lg-9 col-12 p-0">

                            {/* -------------- header -------------------------------------------------- */}
                            <Header
                                {...this.props}
                                handleFunc_toggleNavbarMenu={this.handleFunc_toggleNavbarMenu}
                                toggleNavbarMenu_isOpen={this.state.toggleNavbarMenu_isOpen}
                            />
                            {/* -------------- header -------------------------------------------------- */}


                            {/* -------------- Body -------------------------------------------------- */}
                            {/* -------------- ContentPlace-Holder -------------------------------------------------- */}
                            <PanelAllRoutes
                                selectedUsersIds={this.props.selectedUsersIds}
                                closeSelect={this.props.closeSelect}
                                initRemoteStreams={this.props.initRemoteStreams}
                                setLocalStream={this.props.setLocalStream}
                                isActiveSelect={this.props.isActiveSelect}
                                isActiveCall={this.props.isActiveCall}
                                resetState={this.props.resetState}
                                unselectUser={this.props.unselectUser}
                                selectUser={this.props.selectUser}
                                currentCall_Demand_ChatOpcId={this.props.currentCall_Demand_ChatOpcId}
                                currentCall_Supply_ChatOpcId={this.props.currentCall_Supply_ChatOpcId}
                                currentCall_Demand_UserId={this.props.currentCall_Demand_UserId}
                                currentCall_Demand_UserName={this.props.currentCall_Demand_UserName}
                                currentCall_Demand_UserAvatar={this.props.currentCall_Demand_UserAvatar}
                                currentCall_Supply_UserId={this.props.currentCall_Supply_UserId}
                                currentCall_Supply_UserName={this.props.currentCall_Supply_UserName}
                                currentCall_Supply_UserAvatar={this.props.currentCall_Supply_UserAvatar}
                                currentCall_Meeting_Id={this.props.currentCall_Meeting_Id}
                                currentCall_MeetingType={this.props.currentCall_MeetingType}
                                changeMeetingParameters={this.props.changeMeetingParameters}
                                closeTourOne={this.closeTourOne}
                                openTourOne={this.openTourOne}
                                closeTourTwo={this.closeTourTwo}
                                openTourTwo={this.openTourTwo}
                                closeTourThree={this.closeTourThree}
                                openTourThree={this.openTourThree}
                                closeTourFour={this.closeTourFour}
                                openTourFour={this.openTourFour}
                                closeTourFive={this.closeTourFive}
                                openTourFive={this.openTourFive}
                            />
                            {/* -------------- ContentPlace-Holder -------------------------------------------------- */}
                            {/* -------------- Body -------------------------------------------------- */}

                        </div>

                    </div>
                </div>

                {/* -------------- Footer -------------------------------------------------- */}
                <Footer />
                {/* -------------- Footer -------------------------------------------------- */}

            </React.Fragment>
        );
    }
}

const tourConfigOne = [
    {
        selector: '[data-tour="step-1"]',
        content: <p className="anlytc-new-register">برای رزرو جلسه وارد این قسمت بشید</p>,
    },
    {
        selector: '[data-tour="step-2"]',
        content: <p> لیست جلسات رزرو شده شما</p>,
    },
    {
        selector: '[data-tour="step-3"]',
        content: <p>موجودی حساب شما</p>,
    },
    {
        selector: '[data-tour="step-4"]',
        content: <p>شارژ کیف پول و افزایش اعتبار</p>,
    }
]
const tourConfigTwo = [
    {
        selector: '[data-tour="step-5"]',
        content: <p>نوع جلساتی که روانشناس ارائه می دهد.</p>,
    },
    {
        selector: '[data-tour="step-6"]',
        content: <p>جلسه آنلاین و فوری در لحظه ( در صورتی که روانشناس آنلاین باشد. ) </p>,
    },
    {
        selector: '[data-tour="step-7"]',
        content: <p>رزرو جلسه برای آینده</p>,
    }
]
const tourConfigThree = [
    {
        selector: '[data-tour="step-8"]',
        content: <p>اگر درخواست یا سوالی از روانشناس دارید اینجا وارد کنید.</p>,
    },
    {
        selector: '[data-tour="step-9"]',
        content: <p>روز و ساعت جلسه رو بر اساس محل زندگی خودتون انتخاب کنید.</p>,
    },
    {
        selector: '[data-tour="step-10"]',
        content: <p>بازه 3 ساعتی در نظر گرفته شده پیشنهاد شما به روانشناس است و روانشناس پس از بررسی بازه، یک ساعت از این سه ساعت را انتخاب و تایید میکند که جلسه 45 دقیقه ای شما در این یک ساعت برگزار میشود. برای مثال اگر شما بازه 9 تا 12 را انتخاب کرده باشید، روانشناس پس از بررسی، ساعت 11 تا 12 را انتخاب کرده باشد  جلسه 45 دقیقه ای شما در ساعت 11 شروع میشود و تا 11.45 ادامه خواهد داشت.(در صورت تاخیر و یا بروز مشکل امکان برگزاری جلسه در دقایقی بعد هم مقدور است)</p>,
    }
]
const tourConfigFour = [
    {
        selector: '[data-tour="step-11"]',
        content: <p>جلسات مشاوره آماده برای برگزاری شما در اینجا قرار می گیرد.</p>,
    },
    {
        selector: '[data-tour="step-12"]',
        content: <p>جلساتی که شما رزرو کردید در این قسمت قرار می گیرد.</p>,
    },
    {
        selector: '[data-tour="step-13"]',
        content: <p>وضعیت جلسات رزرو شده توسط شما</p>,
    }
]
const tourConfigFive = [
    {
        selector: '[data-tour="step-14"]',
        content: <p className="anlytc-new-register">دسترسی به منو از طریق این دکمه یا کشیدن صفحه از راست به چپ.</p>,
    }
]

export default withRouter(MainLayout);
