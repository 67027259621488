import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as consultantTypeService from "../../services/consultant/consultantTypeService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import m001 from "../../theme/assets/images/m1.jpg";
import m002 from "../../theme/assets/images/m2.jpg";
import m003 from "../../theme/assets/images/m3.jpg";
import m004 from "../../theme/assets/images/m4.jpg";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class CategoryList extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            categories: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        this.props.startLoadingAction();

        const _List = await consultantTypeService.getConsultantTypes();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ categories: _List.data });


        this.props.finishLoadingAction();


        store.dispatch({
            type: menuActionTypes.Categories
        });

    }

    render() {

        return (
            <React.Fragment>

                <div className="consult-category-page">

                    <LoadingPage />

                    <div className="row p-0 m-0">

                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                        {this.state.categories.map(item => (
                            <div className="col-xl-4 col-sm-6 px-2" key={item.id}>
                                <Link to={"/consultants/" + item.id + ""}>
                                    <div className="consult-cat-widget">
                                        <div className="cat-image" style={{ backgroundImage: "linear-gradient(90deg, #fff 0%, rgba(0,0,0,0) 100%),url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.thumbnail) + ")" }}></div>
                                        <div className="consult-cat-widget__body w-100">
                                            <h2>{item.name}</h2>
                                            <div className="dr-icons">

                                                <div className="dr-icon" style={{ backgroundImage: "url(" + m004 + ")" }}>
                                                </div>
                                                <div className="dr-icon" style={{ backgroundImage: "url(" + m001 + ")" }}>
                                                </div>
                                                <div className="dr-icon" style={{ backgroundImage: "url(" + m002 + ")" }}>
                                                </div>
                                                <div className="dr-icon" style={{ backgroundImage: "url(" + m003 + ")" }}>
                                                </div>
                                                <div className="dr-icon" style={{ backgroundImage: "url(" + m004 + ")" }}>
                                                </div>
                                                <span>...</span>

                                            </div>
                                        </div>
                                    </div>

                                </Link>
                            </div>
                        ))}
                        { /* ----------- key={item._id} must be for list in react ---------------- */}

                    </div>
                </div>


            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(CategoryList));
