import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as blogUrlAddress from "../../utils/urlAddress/blogUrlAddress";
import * as groups_ServiceAPI from "./blogGroupService";

export async function getBlogs(reqData) {
    const _res = await axiosRequest.HttpPost(blogUrlAddress.blogList, reqData);
    return _res.data;
}

export async function getFavBlogs() {
    const _res = await axiosRequest.HttpPost(blogUrlAddress.getFavBlogs, null);
    return _res.data;
}

export async function getBlogById(reqData) {
    return await axiosRequest.HttpPost(blogUrlAddress.blogDetail, reqData);
}


export async function getBlogTypesCount() {
    return await axiosRequest.HttpPost(blogUrlAddress.blogTypesCount, null);
}

// -----------------------------------------------------------------
// -----------------------------------------------------------------
// -----------------------------------------------------------------



export const blogs = [];

export function getBlog(id) {
    return blogs.find((x) => x._id === id);
}

export function saveBlog(blog) {
    let blogInDb = blogs.find((x) => x._id === blog._id) || {};
    blogInDb.name = blog.name;

    //blogInDb.group = groups_ServiceAPI.getGroups().find(x => x._id === blog.groupId);
    blogInDb.group = groups_ServiceAPI.groups.find(
        (x) => x._id === blog.groupId
    );

    blogInDb.numberInStock = blog.numberInStock;
    blogInDb.dailyRentalRate = blog.dailyRentalRate;

    if (!blogInDb._id) {
        blogInDb._id = Date.now();
        console.log(blogInDb._id);
        blogs.push(blogInDb);
    }

    return blogInDb;
}

export function deleteBlog(id) {
    let blogInDb = blogs.find((x) => x._id === id);
    blogs.splice(blogs.indexOf(blogInDb), 1);
    return blogInDb;
}


export function saveLikeBlog(id) {
    let _updatedBlog = blogs.find(x => x._id === id);
    _updatedBlog.likeCount = _updatedBlog.likeCount + 1;
    _updatedBlog.liked = true;

    var fIndex = findIndexByProperty(blogs, '_id', _updatedBlog._id);
    if (fIndex > -1) {
        blogs[fIndex] = _updatedBlog;
    } else {
        //blogs.push(_updatedBlog);
    }

    return true;
}


function findIndexByProperty(data, key, value) {
    for (var i = 0; i < data.length; i++) {
        if (data[i][key] === value) {
            return i;
        }
    }
    return -1;
}