import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as tourService from "../../services/profile/tourService";
import * as consultantService from "../../services/consultant/consultantService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";


import m001 from "../../theme/assets/images/m1.jpg";
import m002 from "../../theme/assets/images/m2.jpg";
import m003 from "../../theme/assets/images/m3.jpg";
import m004 from "../../theme/assets/images/m4.jpg";

import f2 from "../../theme/assets/images/2.jpg";
import f3 from "../../theme/assets/images/3.jpg";
import f4 from "../../theme/assets/images/4.jpg";
import f5 from "../../theme/assets/images/5.jpg";
import f6 from "../../theme/assets/images/6.jpg";

import { toast } from "react-toastify";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


class ConsultantList extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            ncat: "0",
            consultants: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {
        let ncat = this.props.match.params.ncat; // ncat is string
        if (ncat === undefined || ncat === "undefined" || ncat === "0")
            this.props.history.push("/categories");
        this.setState({ ncat: ncat });

        this.props.startLoadingAction();



        store.dispatch({
            type: menuActionTypes.Consultant
        });


        // -----------------------------
        // #TourGuid:
        const reqTourData = { Id: "2", token: Math.random() }; // Id is string
        const _tourObj = await tourService.isSeenTour(reqTourData);
        if (_tourObj.success === true) {
            if (_tourObj.data !== null) {
                if (_tourObj.data.isSeen === false) {
                    this.props.openTourTwo();
                }
            }
            else {
                this.props.openTourTwo();
            }
        }
        else {
            this.props.openTourTwo();
        }
        // -----------------------------


        const reqData = { Id: ncat }; // Id is string
        const _List = await consultantService.getConsultantByTypeId(reqData);
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ consultants: _List.data });

        this.props.finishLoadingAction();

    }



    onFaveHandle = async (e, id) => {
        e.preventDefault();

        this.props.startLoadingAction();

        const favData = {
            Id: id + ""
        };

        const _obj = await consultantService.setFavorite(favData);
        if (_obj.success === true) {
            toast.success(_obj.message);

            let consultantsLst = [...this.state.consultants];
            let objInDb = consultantsLst.find((x) => x.id === id) || {};
            if (_obj.data.res === "added")
                objInDb.isMyFavorite = true;
            if (_obj.data.res === "removed")
                objInDb.isMyFavorite = false;
            var fIndex = this.findIndexByProperty(consultantsLst, 'id', objInDb.id);
            if (fIndex > -1)
                consultantsLst[fIndex] = objInDb;

            this.setState({ consultants: consultantsLst });
        }
        else {
            toast.error(_obj.message);
        }

        this.props.finishLoadingAction();


    };


    findIndexByProperty = (data, key, value) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] === value) {
                return i;
            }
        }
        return -1;
    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">

                    <LoadingPage />

                    <div className="row p-0 m-0">





                        {this.state.consultants.length === 0
                            ?
                            (
                                <>
                                    <div className="col-12 mx-auto">
                                        <br />
                                        <center > در حال حاضر هیچ روان شناسی وجود ندارد </center>
                                        <br />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>

                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                    {this.state.consultants.map(item => (
                                        <div className="col-xl-4 col-sm-6 px-2" key={item.id}>
                                            <div className="doctor-widget mobile-responsive">
                                                <div className="rate-teaser">
                                                    {(item.count_Star === 0)
                                                        ?
                                                        (<i className="fas fa-star" style={{ color: "#d7d7d7" }}></i>)
                                                        :
                                                        (<i className="fas fa-star"></i>)
                                                    }
                                                    <span>
                                                        {item.count_Star}
                                                        از
                                                        5
                                                    </span>
                                                </div>
                                                <div className="cm-teaser">
                                                    {/*<a href="#">
                                            <i className="fas fa-comment"></i>
                                            نظرات کاربران
                                        </a>*/}
                                                    {/*<div className="action-icon row p-0 m-0">*/}
                                                    {/*    {(item.isMyFavorite === true)*/}
                                                    {/*        ?*/}
                                                    {/*        <a className="bookmark active" onClick={(e) => this.onFaveHandle(e, item.id)} >*/}
                                                    {/*            <i className="fas fa-bookmark"></i>*/}
                                                    {/*        </a>*/}
                                                    {/*        :*/}
                                                    {/*        <a className="bookmark" onClick={(e) => this.onFaveHandle(e, item.id)} >*/}
                                                    {/*            <i className="fas fa-bookmark"></i>*/}
                                                    {/*        </a>*/}
                                                    {/*    }*/}
                                                    {/*</div>*/}
                                                </div>

                                                <div className="doctor-widge-wrapp">
                                                    <div className="row px-0 pt-2 pt-md-0 m-0">
                                                        <div className="col-3 col-md-12 p-0">
                                                            
                                                            <div className="doctor-image mx-auto" style={{ backgroundImage: "url(" + "" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.avatar) + "" + ")" }}>
                                                                {/*{(item.isOnline)*/}
                                                                {/*    ?*/}
                                                                {/*    (*/}
                                                                {/*        <span className="online-badge"></span>*/}
                                                                {/*    )*/}
                                                                {/*    :*/}
                                                                {/*    (*/}
                                                                {/*        <span className="offline-badge"></span>*/}
                                                                {/*    )*/}
                                                                {/*}*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-9 col-md-12 px-0 pt-2 pt-md-0 d-flex d-md-block justify-content-center align-items-end">
                                                            <div>
                                                                <a>
                                                                    <h2>
                                                                        {!isNullOrWhiteSpaceOrEmpty(item.title) &&
                                                                            <span>
                                                                                {item.title + " "}
                                                                            </span>
                                                                        }
                                                                        {item.firstName + " "}
                                                                        {item.lastName + " "}
                                                                    </h2>
                                                                </a>
                                                                <div className="parvane-no">
                                                                    <p>
                                                                        شماره پروانه :
                                                                        <span>{item.licenseNumber}</span>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 p-0">
                                                            <div className="doctor-widget__body d-none d-md-block w-100">
                                                                <p>
                                                                    <span>
                                                                        {item.summary}
                                                                    </span>
                                                                    ...
                                                                </p>
                                                            </div>

                                                            {/*<div className="contact-ways-doctor  w-100 d-flex justify-content-center align-items-center" data-tour="step-5">*/}
                                                            {/*    {item.isAbilityTo_TextMessage &&*/}
                                                            {/*        <div className="btn btn-way-contact">*/}
                                                            {/*            <i className="fas fa-comments"></i>*/}
                                                            {/*            چت*/}
                                                            {/*        </div>*/}
                                                            {/*    }*/}
                                                            {/*    {item.isAbilityTo_AudioCall &&*/}
                                                            {/*        <div className="btn btn-way-contact">*/}
                                                            {/*            <i className="fas fa-phone-volume"></i>*/}
                                                            {/*            صوتی*/}
                                                            {/*        </div>*/}
                                                            {/*    }*/}
                                                            {/*    {item.isAbilityTo_VideoCall &&*/}
                                                            {/*        <div className="btn btn-way-contact">*/}
                                                            {/*            <i className="fas fa-video"></i>*/}
                                                            {/*            تصویری*/}
                                                            {/*        </div>*/}
                                                            {/*    }*/}
                                                            {/*</div>*/}

                                                        </div>
                                                        <div className="col-12 p-0">
                                                            <div className="row p-0 mx-0 mt-2 mt-md-3">
                                                                {/*<div className="col-6 px-1">*/}
                                                                {/*    {(item.isOnline)*/}
                                                                {/*        ?*/}
                                                                {/*        (*/}
                                                                {/*            <Link to={"/consultant-detail/" + item.id + "/o/" + this.state.ncat}*/}
                                                                {/*                className="btn btn-start-consult rounded" data-tour="step-6">*/}
                                                                {/*                شروع مشاوره*/}
                                                                {/*            </Link>*/}
                                                                {/*        )*/}
                                                                {/*        :*/}
                                                                {/*        (*/}

                                                                {/*            <OverlayTrigger*/}
                                                                {/*                overlay={<Tooltip id="tooltip-disabled">*/}
                                                                {/*                    روانشناس آفلاین است، می‌توانید جلسه‌ای با او رزرو کنید*/}
                                                                {/*                </Tooltip>}>*/}
                                                                {/*                <span className="d-inline-block w-100">*/}
                                                                {/*                    <button disabled style={{ pointerEvents: 'none' }} data-tour="step-6"*/}
                                                                {/*                        className="btn btn-offline rounded">آفلاین</button>*/}

                                                                {/*                </span>*/}
                                                                {/*            </OverlayTrigger>*/}


                                                                {/*        )*/}
                                                                {/*    }*/}

                                                                {/*</div>*/}
                                                                <div className="col-12 px-1">
                                                                    <Link to={"/consultant-detail/" + item.id + "/r/" + this.state.ncat}
                                                                        className="btn btn-start-consult rounded" data-tour="step-7">
                                                                        رزرو جلسه
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}

                                </>
                            )
                        }



                    </div>
                </div>

            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(ConsultantList));
