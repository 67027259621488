import React, { Component } from "react";
import { BrowserRouter as Router, withRouter, Switch, Route, Link, Redirect } from 'react-router-dom';
import { ToastContainer } from "react-toastify";

import MainLayout from "./components/layout/mainLayout";
import Login from "./components/account/login";
import Register from "./components/account/register";
import VerifyCode from "./components/account/verifyCode";
import VerifyCodeLater from "./components/account/verifyCodeLater";
import ForgotPassword from "./components/account/forgotPassword";

import * as currentUserHelper from "./helper/localStorage/currentUserHelper";
//import * as axiosContentTypeHelper from "./helper/axiosHeader/axiosContentTypeHelper";

import PreMainLayoutForCcVideo from "./components/cc-video-Deprecated/VideoScreen/_preMainLayoutForCcVideo";
import PrivateRoute from "./components/layout/privateRoute";
import PaymentSucceed from "./components/transaction/succeed";
import PaymentFailed from "./components/transaction/failed";

import StartConversation from "./components/redirect/startConversation";


class App extends Component {
    constructor(props) {
        super(props);
        //props.xyz
    }

    //async componentDidMount() {
    componentDidMount() {
        //axiosContentTypeHelper.setContentTypeToAxiosHeader();
        const _currentUser = currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        if (_currentUser === null)
            this.props.history.push("/login");
    }

    render() {
        return (
            <React.Fragment>

                {/* -------------- React Toastify Container -------------------------------------------------- */}
                <ToastContainer position="bottom-center" />
                {/* -------------- React Toastify Container -------------------------------------------------- */}

                <Router>
                    <Switch>
                        <Route exact path="/login" render={props => <Login {...props} />} />
                        <Route exact path="/register" render={props => <Register {...props} />} />
                        <Route exact path="/register/:nid" render={props => <Register {...props} />} />
                        <Route exact path="/forgot-password" render={props => <ForgotPassword {...props} />} />

                        <Route exact path="/verify-code/:nid/:nid2" render={props => <VerifyCode {...props} />} />
                        <Route exact path="/verify-code" render={props => <VerifyCode {...props} />} />

                        <Route exact path="/verify-code-later" render={props => <VerifyCodeLater {...props} />} />

                        <Route exact path="/start-conversation/:catId/:consultantId/:type" render={props => <StartConversation {...props} />} />

                        {/* <Route exact path="/payment-succeed" render={props => <PaymentSucceed {...props} />} /> */}
                        <PrivateRoute path="/payment-succeed" component={PaymentSucceed} />
                        <PrivateRoute path="/payment-failed" component={PaymentFailed} />

                        {/* <MainLayout /> */}
                        <PreMainLayoutForCcVideo />
                    </Switch>
                </Router>

            </React.Fragment>
        );
    }
}

export default withRouter(App);
