import isNullOrWhiteSpaceOrEmpty from "../validation/isNullOrWhiteSpaceOrEmpty";

export function getTokenFromLocalStorage() {
    var _token = localStorage.getItem("jwtUserToken");
    if (!isNullOrWhiteSpaceOrEmpty(_token)) {
        return _token;
    }
    return null;
}

export function setTokenToLocalStorage(accessJwtToken) {
    localStorage.setItem("jwtUserToken", accessJwtToken);
}

export function removeTokenFromLocalStorage() {
    localStorage.removeItem("jwtUserToken");
}
