import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import * as profileService from "../../services/profile/profileService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import pic001 from "../../theme/assets/images/logo.png";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

class UserSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code_To_Introduce: '',
            userName: '',
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.IntroducingCode
        });


        const _result = await profileService.getProfile();
        if (_result.success) {
            this.setState({
                code_To_Introduce: _result.data.code_To_Introduce,
                userName: _result.data.userName,
            });
        }
        else {
            //toast.error(_result.message);
        }

    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // م0ثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>


                {this.state.code_To_Introduce}



                <div className="doctors-page">
                    <div className="col-12">
                        <div className="about-wrapper">
                            <div className="about-logo">
                                <img src={pic001} />
                            </div>
                            <h4>مشاوره روانشناسی آنلاین یاری‌وی</h4>

                            <p>

                                با معرفی پلتفرم یاری وی به دوستانتان، به آنها ۵ دلار هدیه دهید
                                و با اولین رزرو توسط آنها از ما ۵ دلار هدیه دریافت کنید

                            </p>

                            <p>
                                کد معرفی شما:
                            </p>
                            <p style={{
                                background: "#38929a",
                                border: "1px solid #00f1ff",
                                color: "#fff"
                            }}
                            >
                                {this.state.code_To_Introduce}
                            </p>
                            <p>
                                لینک دعوت از طریق کد شما:
                            </p>
                            <p style={{
                                background: "#38929a",
                                border: "1px solid #00f1ff",
                                color: "#fff"
                            }}
                            >
                                https://my.yariway.com/register/{this.state.code_To_Introduce}
                            </p>


                            <br />


                        </div>
                    </div>
                </div>



            </React.Fragment>
        );
    }
}

export default UserSummary;
