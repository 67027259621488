import React, { Component } from "react";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseArray: [
                {
                    id: "collapseOne",
                    isShow: false,
                    collapseMode: "collapse"
                },
                {
                    id: "collapseTwo",
                    isShow: false,
                    collapseMode: "collapse"
                },
                {
                    id: "collapseThree",
                    isShow: false,
                    collapseMode: "collapse"
                },
                {
                    id: "collapseFour",
                    isShow: false,
                    collapseMode: "collapse"
                },
                {
                    id: "collapseFive",
                    isShow: false,
                    collapseMode: "collapse"
                },
                {
                    id: "collapseSix",
                    isShow: false,
                    collapseMode: "collapse"
                },
                {
                    id: "collapseSeven",
                    isShow: false,
                    collapseMode: "collapse"
                }
            ]
        };
    }

    componentDidMount() {
        store.dispatch({
            type: menuActionTypes.Faq
        });
    }

    handleCollapse = (tagId) => {

        let tempCopyCollapseArray = [...this.state.collapseArray];

        tempCopyCollapseArray.forEach(function (item, index, array) {
            ////array[index] ======= item
            if (item.id === tagId) {
                if (item.isShow === true) {
                    item.collapseMode = "collapse";
                    item.isShow = false;
                }
                else {
                    item.collapseMode = "collapse show";
                    item.isShow = true;
                }
            }
            else {
                item.collapseMode = "collapse";
                item.isShow = false;
            }
        })

        this.setState({ collapseArray: tempCopyCollapseArray });
    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">
                    <div className="col-12 pt-3">
                        <div className="accordion" id="accordionExample">

                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <button className="btn btn-faq" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"
                                        onClick={() => this.handleCollapse("collapseOne")}
                                    >
                                        ورود و ثبت نام

                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>

                                <div id="collapseOne" class={this.state.collapseArray.find(x => x.id === "collapseOne").collapseMode} aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">

                                        <h6>
                                            برای مشاوره گرفتن حتما باید حساب کاربری بسازم؟
                                        </h6>
                                        <p>
                                            برای دریافت مشاوره و کلی خدمات دیگه باید حساب کاربری داشته باشید که البته ساخت حساب کاربری رایگان بوده و برای دریافت مشاوره باید هزینه پرداخت کنید.
                                        </p>
                                        <br />


                                        <h6>
                                            اگر رمز عبور را فراموش کنم، باید چکار کنم؟
                                        </h6>
                                        <p>
                                            از طریق دکمه فراموشی رمز عبور، کدی جهت احراز هویت به شماره موبایل شما ارسال می‌شود و سپس با وارد کردن کد می‌توانید رمز جدید برای خود بسازید.
                                        </p>
                                        <br />


                                        <h6>
                                            از موبایل فرد دیگری می‌تونم استفاده کنم؟
                                        </h6>
                                        <p>
                                            یاری‌وی خدمات خود را برای افراد با هویت مشخص ارائه می‌دهد و در راستای محرمانگی اسرار به کاربران پیشنهاد می‌دهد که از موبایل شخصی خود استفاده کنند. طبیعتا اگر شخصی از موبایل فرد دیگری استفاده نماید، عواقب آن برعهده همان شخص خواهد بود. 
                                        </p>
                                        <br />


                                        <h6>
                                            فعال کردن نوتیفیکیشن چقدر اهمیت داره؟
                                        </h6>
                                        <p>
                                            تمامی پیام‌های یاری‌وی از قبیل اعلانات برگزاری جلسه و برنامه ریزی‌های رزرو جلسات و همچنین تمام اتفاقات و رویداد‌های یاری‌وی به دو شیوه نوتیفیکیشن و ارسال پیام به موبایل شما ارسال می‌شود. بنابراین برای دریافت به موقع خدمات باید نوتیفیکیشن یاری‌وی را فعال نمائید. 
                                        </p>
                                        <br />


                                        <h6>
                                            می‌خوام آیکن یاری‌وی رو در منو موبایل داشته باشم باید چکار کنم؟
                                        </h6>
                                        <p>
                                            برای موبایل‌های IOS:  
                                        </p>
                                        <p>
                                            سافاری (Safari) را باز کنید و به www.yariway.com بروید. در پایین روی (<img alt="" style={{ width: "16px", height: "100%", display: "inline" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAA8CAIAAAHhwi9mAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHFSURBVFhH7ZjBUcMwEEXTAB3QCQ3RATMcaYE2uEEjOVAAlxwZbhzDl75nLe9Ka0VyJhmiNzvKRtr9+nZ8kLM7Gqapu2d+BspVKZtNfX37VWKtTR5UTsGIQlfBSnqjSEbKEorYrQTSyWqlVc5XtD9MibCoeHwL14IxZa7Aws9vqMCYFs0VWAC8H8yJ9sGKlB2mJICMEqEixX69TIXimiqmtED217YUVdj//jmPjlZG5ekj9MhjgpzwGcOqZeWKgKg4BBXU2RBUbgNMKg41q7eo4jNU8ixUemJS6WdFRXbz8VTE86pQUQWdD6+TBBOHvAo7mbDfF8qoiARzaXaEMirpGSdVAfb4Q7y7C5RKiY1UWGeD2NwG2FSlROXqUFkgqzen4jNU8gyVPOdR6YmgEoYOKl83ami3QhNpdBpqsSIm7l9mH5I3GzrNipjAEURe2xkAMzyaIBoM1VqxJggnEUKzoSor+0O4/8oEsVYIDaHL/qdSov2xJSUrDQwrOYIVkasJxep8TZCFldLh3kF6TwV7qd6FlQY27B1WIqp3WImo3mElonr/kZUe1L7DSkTtO6xE1L7DSkTtO6xE1L4LK5cKcjVWjsc/K5bGsvUnJY0AAAAASUVORK5CYII=" />)  کلیک کنید تا منوی جدیدی نمایش داده شود. در ردیف پایین اگر انگشت‌تان را به سمت چپ بکشید تا بقیه گزینه‌ها نمایش داده شوند، Add to Home Screen  را بزنید. در نهایت روی Add بزنید تا آیکنی از آدرس در صفحه اصلی گوشی ساخته شود. 
                                        </p>
                                        <p>
                                            برای موبایل‌های Android:
                                        </p>
                                        <p>
                                            روی منوی سه نقطه‌ای که در بالای Browser قرار دارد، کلیک کرده و در منوی باز شده گزینه Add to Home Screen را بزنید. در نهایت روی Add بزنید تا آیکنی از آدرس در صفحه اصلی گوشی ساخته شود. اکنون اگر به صحنه اصلی گوشی بروید، می‌توانید آیکن آدرس را در آنجا ببینید.
                                        </p>
                                        <br />


                                        <h6>
                                            من باید اختلاف ساعت در کشور‌های مختلف رو بدونم؟
                                        </h6>
                                        <p>
                                            خیر؛ یاری‌وی به صورت اتوماتیک اختلاف ساعت را محاسبه می‌کند و شما در ثبت نام یا در تنظیمات، کشور محل زندگی خود را وارد نمائید.  
                                        </p>
                                        <br />


                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <button className="btn btn-faq collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                        onClick={() => this.handleCollapse("collapseTwo")}
                                    >
                                        انتخاب روانشناس

                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                <div id="collapseTwo" class={this.state.collapseArray.find(x => x.id === "collapseTwo").collapseMode} aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">



                                        <h6>
                                            می‌تونم با توجه به سلیقه‌ام، روانشناسم رو خودم انتخاب کنم؟
                                        </h6>
                                        <p>
                                            یاری‌وی سامانه‌ای است که می‌توانید بسته به نیاز خود و امتیازهای روانشناس در لیست مشاوران، حوزه نیاز خود را انتخاب و در آن لیست از بین متخصصین برتر یکی را انتخاب کنید.
                                        </p>
                                        <br />


                                        <h6>
                                            روانشناسان از چه کشوری هستن؟
                                        </h6>
                                        <p>
                                            یاری‌وی برای ارائه بهترین خدمات مشاوره روانشناسی از روانشناسان برتر و مطرح ایران استفاده می‌کند تا کاربران از مشاوری با فرهنگ و زبان مادری یکسان بهره‌مند شوند. 
                                        </p>
                                        <br />


                                        <h6>
                                            مشاوران شما دارای مدرک روانشناسی هستن؟
                                        </h6>
                                        <p>
                                            بله؛ رواشناسانی که در یاری‌وی فعالیت می‌کنند از فیلتر‌های تخصصی عبور می‌کنند و با معیارهایی همچون دانش، تخصص و تجربه سنجیده می‌شوند. همچنین تمامی روانشناسان یاری‌وی دارای پروانه سازمان نظام روان شناسی کشور می‌باشند.
                                        </p>
                                        <br />


                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <button className="btn btn-faq collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                                        onClick={() => this.handleCollapse("collapseThree")}
                                    >
                                        شروع مشاوره

                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                <div id="collapseThree" class={this.state.collapseArray.find(x => x.id === "collapseThree").collapseMode} aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">




                                        <h6>
                                            به چند شیوه می‌تونم از مشاوره شما استفاده کنم؟
                                        </h6>
                                        <p>
                                            در یاری‌وی شما می‌توانید تماس تصویری ارتباط برقرار کنید.
                                        </p>
                                        <br />


                                        <h6>
                                            آیا در یک زمان می‌تونم از چند روانشناس مشاوره بگیرم؟
                                        </h6>
                                        <p>
                                            خیر نمی‌توانید همزمان از دو جلسه استفاده کنید. کیفیت و ارزش مشاوره به تمرکز در اون جلسه است و حتی در واقعیت شما نمی‌توانید همزمان از دو مشاوره استفاده کنید. 
                                        </p>
                                        <br />



                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <button className="btn btn-faq collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                                        onClick={() => this.handleCollapse("collapseFour")}
                                    >
                                        فرایند رزرو

                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                <div id="collapseFour" class={this.state.collapseArray.find(x => x.id === "collapseFour").collapseMode} aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">




                                        <h6>
                                            اگر روانشناسی که می‌خواستم در دسترس نبود، باید چیکار کنم؟
                                        </h6>
                                        <p>
                                            در حالتی که روانشناس در دسترس نیست امکان رزرو برای شما وجود دارد و در رزرو مواردی مانند سوال اصلی، تاریخ جلسه و بازه ساعت رو انتخاب کنید و منتظر تایید روانشناس مورد نظر باشید. 
                                        </p>
                                        <br />


                                        <h6>
                                            فرایند رزرو به چه صورتی است؟
                                        </h6>
                                        <p>
                                            در صورتی که روانشناس مورد نظر شما در دسترس نبود یا شما برای زمان دیگری درخواست جلسه داشتید، باید گزینه رزرو را انتخاب کنید. سپس سوال مورد نظر را تایپ کنید (البته این گزینه اختیاریه) بعد روز مورد نظر، یکی از بازه‌ها را انتخاب کنید و در انتها نوع جلسه تماس تصویری را انتخاب کنید و دکمه ثبت رزرو را بزنید. سپس تا ۲۴ ساعت منتظر تایید مشاور خود باشید. 
                                        </p>
                                        <br />


                                        <h6>
                                            اگر به جلسه رزرو شده نرسم چی میشه؟
                                        </h6>
                                        <p>
                                            درصورتی که جلسه رزرو شده را از دست بدین مبلغ اولیه که ناچیز هست از حساب کاربری شما کم میشه و باقی مبلغ جلسه در حساب کاربری شما برای استفاده‌های بعدی خواهد ماند. 
                                        </p>
                                        <br />



                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <button className="btn btn-faq collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"
                                        onClick={() => this.handleCollapse("collapseFive")}
                                    >
                                        امور مالی

                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                <div id="collapseFive" class={this.state.collapseArray.find(x => x.id === "collapseFive").collapseMode} aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">




                                        <h6>
                                            برای رزرو جلسه مورد نظر باید هزینه‌ای پرداخت کنم؟
                                        </h6>
                                        <p>
                                            بله برای رزرو باید هزینه را پرداخت کنید. نگران نباشید؛ اگر مشاور ساعت پیشنهادی شما رو رد کرد، پول در حساب کاربری شما نزد ما محفوظ می‌ماند تا بهترین روانشناس را انتخاب کنید و بعد از اتمام جلسه از حساب کاربری شما کسر می‌گردد.
                                        </p>
                                        <br />


                                        <h6>
                                            اگر رزرو از طرف روانشناس تایید نشد پول من چی میشه؟
                                        </h6>
                                        <p>
                                            پول شما به هیچ وجه کم نمیشه و در حساب کاربری شما محفوظ می ماند تا بتوانید با همان روانشناس در زمانی دیگر یا از روانشناس دیگری بهره ببرید.
                                        </p>
                                        <br />


                                        <h6>
                                            چطور هزینه را پرداخت کنم؟
                                        </h6>
                                        <p>
                                            هزینه را ابتدای هر جلسه یا به صورت پکیج که شامل تخفیف هم میشه باید پرداخت کنید. البته می‌توانید مبلغی رو در حساب کاربری شارژ کنید که تخفیف بیشتر دریافت کنید و برای چندبار استفاده مجبور به پرداخت مکرر نباشید. 
                                        </p>
                                        <br />


                                        <h6>
                                            اگر هزینه چند جلسه را پرداخت کنم شامل تخفیف میشه؟
                                        </h6>
                                        <p>
                                            بله شامل تخفیف مناسبی میشه و از کیف پول خود هر زمانی که مناسب میدونید می‌توانید استفاده کنید و محدودیتی ندارید.
                                        </p>
                                        <br />


                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <button className="btn btn-faq collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"
                                        onClick={() => this.handleCollapse("collapseSix")}
                                    >
                                        برقراری تماس

                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                <div id="collapseSix" class={this.state.collapseArray.find(x => x.id === "collapseSix").collapseMode} aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">




                                        <h6>
                                            می‌تونم وسط جلسه از ادامه دادن منصرف بشم؟
                                        </h6>
                                        <p>
                                            بله شما می‌توانید هر زمانی که خواستین جلسه رو تموم کنید. بر خلاف مشاوره‌های حضوری، هزینه کامل از شما کسر نمیشه. یک هزینه کم بابت برگزاری و هزینه‌ای که بابت میزان زمان استفاده شما بوده کسر میشه و بخشی که از جلسه شما باقی مانده در حساب کاربری شما می‌ماند تا بتوانید از باقی کیف پول خود برای بخشی از جلسات بعدی استفاده کنید. 
                                        </p>
                                        <br />


                                        <h6>
                                            اگر از روانشناسی راضی بودم می‌تونم هر بار از مشاوره ایشون استفاده کنم؟
                                        </h6>
                                        <p>
                                            در صورت رضایت شما که هدف تیم یاری‌وی هست، میتوانید روانشناس را بعد از جلسه در لیست مشاور من ذخیره کنید تا برای مشاوره بعدی به راحتی کارت مشاور مورد نظر خود را در قسمت مشاور من پیدا کنید.
                                        </p>
                                        <br />


                                        <h6>
                                            برای برگزاری جلسه حتما باید به اینترنت وصل باشم؟
                                        </h6>
                                        <p>
                                            تمامی خدمات ما بر بستر اینترنت هست و بدون اینترنت خدماتی ارائه نمی‌شود.
                                        </p>
                                        <br />


                                        <h6>
                                            می‌تونم بخشی از جلسه را به زمان دیگه‌ای موکول کنم؟
                                        </h6>
                                        <p>
                                            متاسفانه نه؛ البته میتوانید در حین جلسه از روانشناس خداحافظی کنید و باقی پول در حساب کاربری می‌ماند و در زمان دیگری از اون روانشناس درخواست جلسه کنید ولی به تجربه دیده شده که با این کار، بازدهی جلسات به شدت افت می‌کند.
                                        </p>
                                        <br />


                                        <h6>
                                            می‌تونم وسط جلسه به یک صفحه دیگری در اینترنت برم و صدای روانشناس رو داشته باشم؟
                                        </h6>
                                        <p>
                                            خیر، در صورت بستن صفحه یا رفتن به صحفه دیگر ارتباط شما با روانشناس با اختلال مواجه می‌شود و امکان دارد نتوانید از ادامه جلسه بهره ببرید و با این کار هزینه‌ای که پرداخت کردید حیف بشود.
                                        </p>
                                        <br />


                                        <h6>
                                            اگه وسط جلسه دستم خورد و اشتباهی قطع کردم باید چیکار کنم؟
                                        </h6>
                                        <p>
                                            مجدد به صفحه پنل خود بروید و در قسمت اعلانات جلسه، مجدد ادامه جلسه رو دنبال کنید. در صورت بروز مشکل می‌توانید با پشتیبانی تماس بگیرید و مشکل را حل کنید.
                                        </p>
                                        <br />


                                        <h6>
                                            آیا جلسات من در یاری‌وی ذخیره میشه؟
                                        </h6>
                                        <p>
                                            در راستای محرمانگی اسرار شما در یاری‌وی مکالمات شما ذخیره نمی‌شود اما چت‌های شما عزیزان برای شخص شما نمایش داده می‌شود ولی هیچ فرد دیگری به اطلاعات شما دسترسی ندارد. در آرشیو جلسات صرفا گزارش جلسه اعم از تاریخ و ساعت جلسه و میزان جلسه با روانشناس مشخص، نمایش داده می‌شود که شما بتوانید برنامه ریزی بهتری برای جلسات خود داشته باشید.
                                        </p>
                                        <br />


                                        <h6>
                                            در اکانت من جلسات بایگانی میشه؟
                                        </h6>
                                        <p>
                                            گزارش جلسات در قسمت آرشیو جلسات قابل مشاهده است ولی به منظور رعایت محرمانگی اسرار شما، جزئیات جلسات تماس تصویری ذخیره نمی‌شوند.
                                        </p>
                                        <br />



                                        <h6>
                                            از چند دیوایس می‌تونم برای مشاوره گرفتن استفاده کنم؟
                                        </h6>
                                        <p>
                                            یاری‌وی در حال حاضر به صورت وب اپلیکیشن ارائه می‌شود. هر دیوایسی که به اینترنت مجهز باشد از طریق Browser و با سرچ کردن یاری‌وی قابلیت دسترسی به این سامانه را خواهید داشت. تمام تلاش تیم یاری‌وی این است که بهترین تجربه کاربری را داشته باشید.
                                        </p>
                                        <br />


                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <button className="btn btn-faq collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"
                                        onClick={() => this.handleCollapse("collapseSeven")}
                                    >
                                        پشتیبانی

                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                <div id="collapseSeven" class={this.state.collapseArray.find(x => x.id === "collapseSeven").collapseMode} aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">



                                        <h6>
                                            آیا واقعا نظرسنجی مهمه؟
                                        </h6>
                                        <p>
                                            تمام تلاش تیم یاری‌وی ارائه بهترین خدمت به شماست و این موضوع مستلزم ارتقای کیفیت خدمات ماست که شما عزیزان با نظر دادن و نشان دادن نقاط قوت و ضعف می‌توانید به عنوان عضوی از این تیم به ما کمک کنید.
                                        </p>
                                        <br />


                                        <h6>
                                            می‌تونم چندبار نظرسنجی برای یک نفر داشته باشم؟
                                        </h6>
                                        <p>
                                            متاسفانه نه؛ چونکه ما تحلیل‌های خود را بر پایه نظرات شما گذاشتیم و تغییر در نظرسنجی ابهام و خطای زیادی در تحلیل‌های ما ایجاد می‌کند.
                                        </p>
                                        <br />


                                        <h6>
                                            به پشتیبانی زنگ زدم کسی جواب نداد، چیکار کنم؟
                                        </h6>
                                        <p>
                                            تمام تلاش ما پاسخگویی منظم و دقیق می‌باشد با این حال اگر نتوانستید ارتباط مناسب برقرار کنید در قسمت ارتباط با پشتیبانی در صفحه خانه خود، پیام یا درخواست خودتان رو ثبت کنید تا در اسرع وقت تیم یاری‌وی با شما ارتباط برقرار کند.
                                        </p>
                                        <br />


                                        <h6>
                                            پشتیبانی یاری‌وی به چه صورت هست؟
                                        </h6>
                                        <p>
                                            تیم پشتیبانی یاری‌وی با صبر و حوصله آماده پاسخگویی به شما می‌باشد. ارتباط با پشتیبانی در صفحه اصلی سایت و همچنین در صفحه خانه خود قابل مشاهده می‌باشد. با این حال اگر نتوانستید ارتباط مناسب برقرار کنید در قسمت ارتباط با پشتیبانی در صفحه خانه خود، پیام یا درخواست خودتون رو ثبت کنید تا در اسرع وقت تیم یاری‌وی با شما ارتباط برقرار کند.
                                        </p>
                                        <br />


                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default FAQ;
