import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { userLoginAction } from "../../redux/actions/accountActions";
import headerPic from "../../theme/assets/images/logo.png";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingAccount from "./loadingAccount";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "" //,
        };
    }


    onChangeHndle = e => {

        //const _regx = /^[0-9\b]+$/; // only number

        //if (e.target.name === "username") {
        //    if (e.target.value === '' || _regx.test(e.target.value)) {
        //        this.setState({ [e.target.name]: e.target.value });
        //    }
        //    else {
        //        e.preventDefault();
        //        alert("فیلد شماره موبایل فقط میتواند کاراکتر عددی باشد");
        //        this.setState({ [e.target.name]: "" });
        //    }
        //}
        //else {

        this.setState({ [e.target.name]: e.target.value });

        //}

    };

    onSubmitHandle = async (e) => {
        e.preventDefault();

        let userData = {
            UserName: this.state.username,
            Password: this.state.password
        };

        this.props.startLoadingAction();

        const isLogined = await this.props.userLoginAction(userData);
        if (isLogined) {
            this.props.history.push("/");
        }

        this.props.finishLoadingAction();

    };


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        return (
            <React.Fragment>

                <LoadingAccount />

                <div className="login-page d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center">
                        <div className="bg-logo">
                            <img src={headerPic} className="logo-login" width="70px" />
                        </div>
                    </div>
                    <div className="content col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto p-0">

                        <div className="login-container">
                            <form className="w-100" onSubmit={this.onSubmitHandle}>
                                <div className="form-group">
                                    <div className="cap-focus">
                                        <input onChange={this.onChangeHndle} value={this.state.username} id="username" name="username" type="text" className="form-control login-input"
                                            placeholder="آدرس ایمیل یا شماره موبایل (بدون 0 و بدون کد کشور)"
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <div className="cap-focus">
                                        <input onChange={this.onChangeHndle} value={this.state.password} id="password" name="password" type="password" autoComplete="new-password" className="form-control login-input" placeholder="رمز عبور" />
                                    </div>
                                    <div className="w-100 d-flex justify-content-end">
                                        <Link to={"/forgot-password"} className="forgot-pass">رمز عبور خود را فراموش کرده ام</Link>
                                    </div>
                                </div>
                                {/* 
                                 <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                    <label className="custom-control-label" htmlFor="customSwitch1">مرا به خاطر داشته باش</label>
                                </div>
                                */}

                                <button type="submit" className="btn btn-load-effect btn-sign-in">
                                    ورود
                                </button>

                                {this.props.accountState.loading
                                    ?
                                    (
                                        <div className="alert alert-info">Waiting ...</div>
                                    )
                                    :
                                    (
                                        <div></div>
                                    )
                                }

                                {this.props.accountState.errorMessage
                                    &&
                                    <div className="alert alert-warning">{this.props.accountState.errorMessage}</div>
                                }

                                <div className="d-flex w-100 justify-content-center">
                                    <Link to={"/register"} className="btn btn-link" type="link">
                                        هنوز
                                        <span>
                                            ثبت نام&nbsp;
                                        </span>
                                        نکردید؟
                                    </Link>
                                </div>

                                <div className="d-flex w-100 justify-content-center">
                                    <Link to={"/verify-code-later"} className="btn btn-link" type="link" style={{ marginTop: "-5px" }} >
                                        قبلا
                                        <span>
                                            پیامک رمز یکبار مصرف&nbsp;
                                        </span>
                                        را دریافت کرده ام
                                    </Link>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer
});

// this.props.userLoginAction()
// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    userLoginAction,
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(Login);
