import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Nav from 'react-bootstrap/Nav';

import '../../theme/global/NavStyle.css';


import { toast } from "react-toastify";

import * as profileService from "../../services/profile/profileService";
import { refreshTokenAction } from "../../redux/actions/accountActions";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import * as surveyService from "../../services/survey/surveyService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";


import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


class Survey extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            SupplyProfileId: "0",
            MeetingId: "0",
            surveys: [],
            selectedSurveyId: [],
            selectedStarPoint: 0,
            selectedComment: ""
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        // SupplyProfileId === nid
        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0")
            this.props.history.push("/meetings-reserve-archive");

        // MeetingId === mid
        let mid = this.props.match.params.mid; // mid is string
        if (mid === undefined || mid === "undefined" || mid === "0")
            this.props.history.push("/meetings-reserve-archive");


        this.setState({ SupplyProfileId: nid });
        this.setState({ MeetingId: mid });

        this.props.startLoadingAction();


        // check for Exist nid :
        const reqData = {
            Supply_ProfileId: nid + "",
        };
        const _result = await surveyService.surveyList(reqData);
        if (_result.success === true) {
            if (_result.data !== null)
                this.setState({ surveys: _result.data });
        }
        else {
            toast.error(_result.message);
            this.props.finishLoadingAction();

            this.props.history.push("/meetings-reserve-archive");
        }

        this.props.finishLoadingAction();


        store.dispatch({
            type: menuActionTypes.Survey
        });

    }



    HandleChangeStarPoint = (e, id) => {
        //e.preventDefault();

        this.setState({ selectedStarPoint: id });
        console.log(id);

    };


    HandleChangeSurveyId = (e, id) => {
        //e.preventDefault();

        let selectedSurveyIdArr = [...this.state.selectedSurveyId];

        let findedItemId = selectedSurveyIdArr.find(item => item === id);
        if (isNullOrWhiteSpaceOrEmpty(findedItemId)) {
            // add:
            selectedSurveyIdArr.push(id);
        }
        else {
            //delete:
            selectedSurveyIdArr.splice(selectedSurveyIdArr.indexOf(findedItemId), 1);
        }

        this.setState({ selectedSurveyId: selectedSurveyIdArr });
        console.log(selectedSurveyIdArr);
        //alert(selectedSurveyIdArr);
    };

    onChangeHandle = e => {
        this.setState({ [e.target.name]: e.target.value });
    };


    onSubmitHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        const reqData = {
            Supply_ProfileId: this.state.SupplyProfileId + "",
            MeetingId: this.state.MeetingId + "",
            SurveyIds: this.state.selectedSurveyId.join() + "",
            StarPoint: this.state.selectedStarPoint,
            Comment: this.state.selectedComment + "",
        };

        const _result = await surveyService.surveySave(reqData);
        if (_result.success === true) {
            toast.success("نظرسنجی با موفقیت ثبت شد");
            this.props.finishLoadingAction();

            this.props.history.push("/meetings-reserve-archive");
        }
        else {
            toast.error(_result.message);
        }

        this.props.finishLoadingAction();


    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>


                <div class="doctors-page">

                    <LoadingPage />


                    <div class="col-md-8 mx-auto">
                        <div class="arch-detail pb-4">
                            <h2 class="arch-heading-side">امتیاز شما به این مشاور</h2>
                            <div class="arch-single-detail">
                                <div class="star-rating-wrapper">
                                    <div class="rating d-flex justify-content-center">
                                        <input id="rating-5" type="radio" name="rating" value="5" onClick={(e) => this.HandleChangeStarPoint(e, 5)} />
                                        <label for="rating-5">
                                            <i class="fas fa-star"></i>
                                            <span class="d-none">
                                                عالی
                                            </span>
                                        </label>
                                        <input id="rating-4" type="radio" name="rating" value="4" onClick={(e) => this.HandleChangeStarPoint(e, 4)} />
                                        <label for="rating-4" >
                                            <i class="fas fa-star"></i>
                                            <span class="d-none">
                                                خوب
                                            </span>
                                        </label>
                                        <input id="rating-3" type="radio" name="rating" value="3" onClick={(e) => this.HandleChangeStarPoint(e, 3)} />
                                        <label for="rating-3" >
                                            <i class="fas fa-star"></i>
                                            <span class="d-none">
                                                متوسط
                                            </span>
                                        </label>
                                        <input id="rating-2" type="radio" name="rating" value="2" onClick={(e) => this.HandleChangeStarPoint(e, 2)} />
                                        <label for="rating-2" >
                                            <i class="fas fa-star"></i>
                                            <span class="d-none">
                                                قابل قبول
                                            </span>
                                        </label>
                                        <input id="rating-1" type="radio" name="rating" value="1" onClick={(e) => this.HandleChangeStarPoint(e, 1)} />
                                        <label for="rating-1" >
                                            <i class="fas fa-star"></i>
                                            <span class="d-none">
                                                ضعیف
                                            </span>
                                        </label>
                                    </div>

                                </div>
                            </div >

                        </div >
                        <div class="arch-detail pb-4">
                            <h2 class="arch-heading-side">جزییات امتیاز</h2>


                            <Tab.Container id="left-tabs-example" defaultActiveKey="first" class="pros-cons-tab">

                                <Nav variant="pills" className="nav nav-pills mb-3 d-flex justify-content-center">
                                    <Nav.Item class="nav-item">
                                        <Nav.Link eventKey="first" class="nav-link btn btn-pros">نقاط قوت</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item class="nav-item">
                                        <Nav.Link eventKey="second" class="nav-link btn btn-cons">نقاط ضعف</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content class="tab-content">
                                    <Tab.Pane eventKey="first" class="tab-pane fade">
                                        <div class="rate-reasons">
                                            <div class="row p-0 m-0">

                                                { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                {this.state.surveys.map(item => (
                                                    <>
                                                        {(item.isPositive) &&
                                                            <div class="col-md-6 px-1" key={item.id}>
                                                                <div class="custom-control custom-checkbox">
                                                                    <input type="checkbox" class="custom-control-input" id={"pros-" + item.id} onClick={(e) => this.HandleChangeSurveyId(e, item.id)} />
                                                                    <label class="custom-control-label" for={"pros-" + item.id}>{item.title}</label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                ))}
                                                { /* ----------- key={item._id} must be for list in react ---------------- */}

                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" class="tab-pane fade">
                                        <div class="rate-reasons cons-reasons">
                                            <div class="row p-0 m-0">

                                                { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                {this.state.surveys.map(item => (
                                                    <>
                                                        {(!item.isPositive) &&
                                                            <div class="col-md-6 px-1" key={item.id}>
                                                                <div class="custom-control custom-checkbox">
                                                                    <input type="checkbox" class="custom-control-input" id={"cons-" + item.id} onClick={(e) => this.HandleChangeSurveyId(e, item.id)} />
                                                                    <label class="custom-control-label" for={"cons-" + item.id}>{item.title}</label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                ))}
                                                { /* ----------- key={item._id} must be for list in react ---------------- */}

                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>

                        </div>
                        <div class="arch-detail pb-4">
                            <h2 class="arch-heading-side">اگر جزئیات دیگری در مورد روانشناس دارید، اینجا بنویسید ...</h2>
                            <form class="w-100" onSubmit={this.onSubmitHandle}>
                                <div class="form-group">

                                    <textarea
                                        class="form-control"
                                        placeholder=""
                                        rows="3"
                                        name="selectedComment"
                                        value={this.state.selectedComment}
                                        onChange={(e) => this.onChangeHandle(e)}
                                    >
                                    </textarea>

                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <button type="submit" class="btn btn-next-step">
                                        ثبت امتیاز
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div >

                </div >


            </React.Fragment >
        );
    }
}



// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer,
    accountState: state.accountReducer

});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction,
    refreshTokenAction

};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Survey));
