import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import * as blogService from "../../services/blog/blogService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as commentService from "../../services/comment/commentService";

import { toast } from "react-toastify";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class Blog extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            blogs: [],
            //blogs: blogService.getBlogs(),  // in 'componentDidMount' lifecycle hooks
            pageSize: 100,
            currentPage: 1
        };
    }

    async componentDidMount() {
        store.dispatch({
            type: menuActionTypes.FavoriteBlog
        });

        this.LoadNewList();

    }


    LoadNewList = async () => {

        this.props.startLoadingAction();


        const _blogList = await blogService.getFavBlogs();
        if (_blogList !== null)
            this.setState({ blogs: _blogList });

        this.props.finishLoadingAction();

    }







    onFaveHandle = async (e, id) => {
        e.preventDefault();

        this.props.startLoadingAction();


        const favData = {
            Id: id + ""
        };

        const _obj = await commentService.setFavorite(favData);
        if (_obj.success === true) {
            toast.success(_obj.message);

            await this.LoadNewList();
        }
        else {
            toast.error(_obj.message);
        }

        this.props.finishLoadingAction();


    };


    findIndexByProperty = (data, key, value) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] === value) {
                return i;
            }
        }
        return -1;
    }






    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {


        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="blog-page">

                    <LoadingPage />


                    <div className="row p-0 m-0">
                        <div className="col-12 p-0">
                            <div className="blog-posts">
                                <div className="row p-0 m-0">

                                    <React.Fragment>





                                        {this.state.blogs.length === 0
                                            ?
                                            (
                                                <>
                                                    <div className="col-12 mx-auto">
                                                        <br />
                                                        <center> هنوز مقاله ای را برای خود منتخب  نکرده اید </center>
                                                        <br />
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>


                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        {this.state.blogs.map(item => (
                                            <div className="col-md-4 px-1" key={item.id}>

                                                <div className="card card-blog-home">
                                                    <span className="badge badge-content-type">

                                                        {(item.video != null) &&
                                                            <i class="fas fa-video"></i>
                                                        }

                                                        {(item.image != "") &&
                                                            <i class="fas fa-book"></i>
                                                        }

                                                        {(item.audio != null) &&
                                                            <i class="fas fa-headphones-alt"></i>
                                                        }

                                                    </span>
                                                    <div className="blog-img-holder">
                                                        <Link to={"/blog-detail/" + item.id + ""} >
                                                            <img src={baseUrlAddress.getFilesBaseUrl() + item.thumbnail} className="card-img" alt={item.title} />
                                                        </Link>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="post-actions">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-6 p-0">
                                                                    <div className="action-icon row p-0 m-0">
                                                                        {(item.isMyFavorite && item.isMyFavorite === true)
                                                                            ?
                                                                            <a className="bookmark active" onClick={(e) => this.onFaveHandle(e, item.id)}>
                                                                                <i className="fas fa-bookmark"></i>
                                                                            </a>
                                                                            :
                                                                            <a className="bookmark" onClick={(e) => this.onFaveHandle(e, item.id)}>
                                                                                <i className="fas fa-bookmark"></i>
                                                                            </a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-0">
                                                                    <div className="action-icon row p-0 m-0 d-flex justify-content-end">
                                                                        {item.count_Like > 0
                                                                            ?
                                                                            <a className="ml-2 like active">
                                                                                {item.count_Like}
                                                                                <i className="fas fa-heart mr-2"></i>
                                                                            </a>
                                                                            :
                                                                            <a className="ml-2 like">
                                                                                {item.count_Like}
                                                                                <i className="fas fa-heart mr-2"></i>
                                                                            </a>
                                                                        }
                                                                        {item.count_Comment > 0
                                                                            ?
                                                                            <a className="cm active">
                                                                                {item.count_Comment}
                                                                                <i className="fas fa-comment"></i>
                                                                            </a>
                                                                            :
                                                                            <a className="cm">
                                                                                {item.count_Comment}
                                                                                <i className="fas fa-comment"></i>
                                                                            </a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h5 className="card-title">
                                                            <Link to={"/blog-detail/" + item.id + ""} style={{ color: "#575757" }}>
                                                                {item.title}
                                                            </Link>
                                                        </h5>
                                                        <div className="date-published">
                                                            <p>{item.createDateTimeString}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                        { /* ----------- key={item._id} must be for list in react ---------------- */}


                                        
                                </>
                            )
                        }




                                    </React.Fragment>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Blog));
