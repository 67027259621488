import * as alertActionTypes from "../actionTypes/alertActionTypes";

const initialState = {
    showAlert: false,
    message: { sender_id: null, body: null, dialog_id: null }
};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case alertActionTypes.MessageRecieved:
            return {
                ...state,
                showAlert: true,
                message: action.payload
            };
        case alertActionTypes.MessageCleared:
            return {
                ...state,
                showAlert: false,
                message: { sender_id: null, body: null, dialog_id: null }
            };
        default:
            return state;
    }
}
