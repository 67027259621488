import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as profileService from "../../services/profile/profileService";
import { refreshTokenAction } from "../../redux/actions/accountActions";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as notifService from "../../services/notification/notificationService";
import pic001 from "../../theme/assets/images/logo.png";

class Header extends Component {
    constructor(props) {
        super(props);
        //props.handleFunc_toggleNavbarMenu

        this.state = {
            toggleNavbarMenu_isOpen: this.props.toggleNavbarMenu_isOpen, // برای بار اولی که اپ بالا می آید
            NotifNewCount: 0,
            intrvl_Id: null
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        await this.checkUpdateNotification();

        var intrvl_Id = setInterval(this.intrvlTimer, 20000);
        // store interval_Id in the state so it can be accessed later:
        this.setState({ intrvl_Id: intrvl_Id });

    }


    async componentWillUnmount() {
        // use interval_Id from the state to clear the interval
        clearInterval(this.state.intrvl_Id);
    }


    intrvlTimer = () => {

        (async () => {
            await this.checkUpdateNotification();
        })();

    }


    checkUpdateNotification = async () => {

        const _res = await notifService.getNewCount();
        if (_res.success === true) {
            this.setState({ NotifNewCount: _res.data.count });

            let isPaid = await this.props.refreshTokenAction();
            if (isPaid)
                currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();

            //console.log(_res.data.count);
        }

    }


    routeToRoot_Func = () => {
        this.props.history.push("/");
    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {
        return (
            <React.Fragment>

                <header>
                    <div className="row p-0 m-0">
                        <div className="col-4  d-flex justify-content-start align-items-center">
                            <a  data-tour="step-14"
                                className="navbar-toggler d-lg-none"
                                onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.toggleNavbarMenu_isOpen)}
                            >
                                <i className="fas fa-bars"></i>
                            </a>
                            <div className="d-none d-lg-block">
                                {
                                    (
                                        this.props.menuState.pageUrl !== "Dashboard")
                                        ?
                                        (
                                            <button className="btn btn-back"
                                                onClick={this.routeToRoot_Func}
                                            >
                                                <i className="fas fa-home"></i>
                                            </button>
                                        )
                                        :
                                        (
                                            <img src={pic001} alt="یاری‌وی" style={{ width: "30px" }} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="col-4 p-0 d-flex justify-content-center align-items-center">
                            <h2 className="page-title">
                                {this.props.menuState.pageTitle}
                            </h2>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center">
                            <Link to="/notification" className="btn btn-shortcuts" role="button">
                                {this.state.NotifNewCount > 0 &&
                                    <span className="badge badge-danger">
                                        {this.state.NotifNewCount}
                                    </span>
                                }
                                <i className="far fa-bell"></i>
                            </Link>
                            <div className="d-block d-lg-none">
                                {
                                    (
                                        this.props.menuState.pageUrl !== "Dashboard")
                                        ?
                                        (
                                            <button className="btn btn-back"
                                                onClick={this.routeToRoot_Func}
                                            >
                                                <i className="fas fa-home"></i>
                                            </button>
                                        )
                                        :
                                        (
                                            <img src={pic001} alt="یاری‌وی" style={{ width: "30px" }} />
                                        )
                                }
                            </div>


                        </div>
                    </div>
                </header>

            </React.Fragment>
        );
    }
}


// this.props.menuState.pageUrl
// this.props.menuState.pageTitle

// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser

const map_State_to_Props = state => ({
    menuState: state.menuReducer,
    accountState: state.accountReducer
});

// this.props.refreshTokenAction()
const map_DispatchAction_to_Props = {
    refreshTokenAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Header));
