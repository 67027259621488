import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";

import * as consultantService from "../../services/consultant/consultantService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as meetingService from "../../services/meeting/meetingService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as tourService from "../../services/profile/tourService";

//import CallService from '../../services/cc-video/call-service';
//import ChatService from "../../services/cc-message/chat-service";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';


class MeetingsReserveArchive extends Component {
    constructor(props) {
        super(props);
        //props.xyz
        //props.selectedUsersIds
        //props.closeSelect
        //props.initRemoteStreams
        //props.setLocalStream
        //props.isActiveSelect
        //props.isActiveCall
        //props.resetState
        //props.unselectUser
        //props.selectUser
        //props.currentCall_Demand_ChatOpcId
        //props.currentCall_Supply_ChatOpcId
        //props.currentCall_Demand_UserId
        //props.currentCall_Supply_UserId
        //props.currentCall_Meeting_Id
        //props.changeMeetingParameters

        //console.log("reserve archive : " + this.props.selectedUsersIds.length)
        //console.log("reserve archive : " + this.props.isActiveCall)

        this.state = {
            meetings: [],
            reserves: [],
            meetingsOld: [],
            pageSize: 100,
            currentPage: 1,
            intervalId: null,
            readyToCall: false,
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.MeetingsReserveArchive
        });

        this.props.startLoadingAction();



        // -----------------------------
        // #TourGuid:
        const reqTourData = { Id: "4", token: Math.random() }; // Id is string
        const _tourObj = await tourService.isSeenTour(reqTourData);
        if (_tourObj.success === true) {
            if (_tourObj.data !== null) {
                if (_tourObj.data.isSeen === false) {
                    this.props.openTourFour();
                }
            }
            else {
                this.props.openTourFour();
            }
        }
        else {
            this.props.openTourFour();
        }
        // -----------------------------


        const _List = await meetingResavationService.getList();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ reserves: _List.data });

        const _mList = await meetingService.getOnlineList();
        if (_mList.success === true)
            if (_mList.data !== null)
                this.setState({ meetings: _mList.data });



        const _oldList = await meetingService.getList();
        if (_oldList.success === true)
            if (_oldList.data !== null)
                this.setState({ meetingsOld: _oldList.data });



        this.props.finishLoadingAction();

        var intervalId = setInterval(this.intervalTimer, 20000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });

        window._that = this;
        setTimeout(
            function () {
                window._that.setState({ readyToCall: true });
            },
            8000
        );



    }



    async componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    intervalTimer = () => {

        (async () => {
            const _List = await meetingResavationService.getList();
            if (_List.success === true)
                if (_List.data !== null) {
                    this.setState({ reserves: _List.data });
                }
        })();


        (async () => {
            const _mList = await meetingService.getOnlineList();
            if (_mList.success === true)
                if (_mList.data !== null)
                    this.setState({ meetings: _mList.data });
        })();

        //window.location.reload();
    }


    creatNewMeetingHandle = async (
        demandUserId,
        supplyUserId,
        demandOccupantId,
        supplyOccupantId,
        meetingId,
        meetingType,
        chatId,
        anOtherCallAvatar,
        anOtherCallName) => {

        //props.currentCall_Demand_ChatOpcId
        //props.currentCall_Supply_ChatOpcId
        //props.currentCall_Demand_UserId
        //props.currentCall_Supply_UserId
        //props.currentCall_Meeting_Id
        //props.changeMeetingParameters

        this.props
            .changeMeetingParameters(
                demandUserId,
                this.props.accountState.currentUser.FirstName + " " + this.props.accountState.currentUser.LastName,
                this.props.accountState.currentUser.Avatar,
                supplyUserId,
                anOtherCallName,
                anOtherCallAvatar,
                demandOccupantId,
                supplyOccupantId,
                meetingId,
                meetingType,
                chatId);


        if (meetingType === 0) {

            try {
                this.props.startLoadingAction();

                //const ___diaglog = await ChatService.fetchDialogs_Really_ById_V2(chatId);
                //ChatService.setSelectDialog(___diaglog);

                // use intervalId from the state to clear the interval
                clearInterval(this.state.intervalId);

                this.props.finishLoadingAction();
                //this.props.history.push("/message-chat/" + ___diaglog.id); // ___diaglog.id == chatId
            }
            catch {
                alert("خطا در برقراری ارتباط ، مجددا سعی نمایید");
                window.location.reload();
            }

        }
        if (meetingType === 1) {

            try {
                // use intervalId from the state to clear the interval
                clearInterval(this.state.intervalId);

                this.props.finishLoadingAction();
                //this.startVoiceCall(supplyOccupantId);
            }
            catch {
                alert("خطا در برقراری ارتباط ، مجددا سعی نمایید");
                window.location.reload();
            }

        }
        if (meetingType === 2) {

            try {
                // use intervalId from the state to clear the interval
                clearInterval(this.state.intervalId);

                this.props.finishLoadingAction();
                //this.startCall(supplyOccupantId);
            }
            catch {
                alert("خطا در برقراری ارتباط ، مجددا سعی نمایید");
                window.location.reload();
            }

        }

    }


    startCall = (chat_Supply_OccupantId) => {
        //let {
        //    selectedUsersIds,
        //    closeSelect,
        //    initRemoteStreams,
        //    setLocalStream,
        //    unselectUser,
        //    selectUser
        //} = this.props;

        //selectUser(chat_Supply_OccupantId);
        //selectedUsersIds.push(chat_Supply_OccupantId);

        //console.log("----------------------**********************************************************-----------------");
        //console.log("chat_Supply_OccupantId : " + chat_Supply_OccupantId);
        //console.log("selectedUsersIds : " + selectedUsersIds);


        //if (selectedUsersIds.length === 0) {
        //    CallService.showToast('Select at less one user to start Videocall');
        //}
        //else {
        //    closeSelect();
        //    initRemoteStreams(selectedUsersIds);
        //    CallService.startCall(selectedUsersIds).then(setLocalStream);
        //}
    };

    startVoiceCall = (chat_Supply_OccupantId) => {
        //let {
        //    selectedUsersIds,
        //    closeSelect,
        //    initRemoteStreams,
        //    setLocalStream,
        //    unselectUser,
        //    selectUser
        //} = this.props;

        //selectUser(chat_Supply_OccupantId);
        //selectedUsersIds.push(chat_Supply_OccupantId);

        //if (selectedUsersIds.length === 0) {
        //    CallService.showToast('Select at less one user to start VoiceCall');
        //}
        //else {
        //    closeSelect();
        //    initRemoteStreams(selectedUsersIds);
        //    CallService.startCall(selectedUsersIds, "AUDIO").then(setLocalStream);
        //}
    };

    stopCall = () => {
        //const { resetState } = this.props;

        //CallService.stopCall();
        //resetState();
    };





    onDeleteMetting = async (e, id) => {
        e.preventDefault();

        window.ddata_iid = {
            Id: id + ""
        };

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'آیا از کنسل کردن این جلسه اطمینان دارید؟',
            text: "چنانچه حتی یک لحظه قبل از اتمام مراحل کنسلی توسط شما، روانشناس انتخابی، برگزاری جلسه را تایید کرده باشد، هزینه اولیه ی جلسه که از حساب شما کسر شده است، قابل بازگشت به حساب شما نمی باشد",
            icon: 'warning',
            showCancelButton: true,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#727272',
            confirmButtonText: 'بله ، کنسل شود',
            cancelButtonText: 'خیر'
        })
            .then((result) => {
                if (result.isConfirmed) {

                    (async () => {

                        const _obj = await meetingResavationService.deleteResvedMetting(window.ddata_iid);
                        if (_obj.success === true) {
                            toast.success(_obj.message);

                            // حذف نباید بشه ، باید تغییر وضعیت بشه
                            //let reservesLst = [...this.state.reserves];
                            //let objInDb = reservesLst.find((x) => x.id === id) || {};
                            //reservesLst.splice(reservesLst.indexOf(objInDb), 1);

                            //this.setState({ reserves: reservesLst });

                            let reservesLst = [...this.state.reserves];
                            let objInDb = reservesLst.find((x) => x.id === id) || {};
                            objInDb.status = 5;
                            objInDb.isFinalAccepted_Status = true;
                            var fIndex = this.findIndexByProperty(reservesLst, 'id', objInDb.id);
                            if (fIndex > -1)
                                reservesLst[fIndex] = objInDb;

                            this.setState({ reserves: reservesLst });

                        }
                        else {
                            toast.error(_obj.message);
                        }

                    })();

                }
            })

    };



    findIndexByProperty = (data, key, value) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] === value) {
                return i;
            }
        }
        return -1;
    }



    //_____OLD_____creatNewMeetingHandle = async (consultantUserId, meetingType, reservedId) => {
    //    //e.preventDefault();

    //    // قبلش چک بشه که نفر مقابل زودتر این رزرو رو تبدیل به جلسه نکرده باشه
    //    // و اینکه بعد از انجام دیگه از لیست رزرو حذف بشه چون در لیست آرشیو جلسات است

    //    const reqData = {
    //        SupplyId: consultantUserId + "",
    //        MeetingTypeId: meetingType + "",
    //        RelatedTo_MeetingResaved_Id: reservedId + ""
    //    };
    //    const _res = await meetingService.creatNewMeeting(reqData);
    //    if (_res.success === true) {

    //        // type: 2 === PublicDialog === Private Group
    //        const params = {
    //            type: 2,
    //            occupants_ids: [_res.data.chat_Demand_OccupantId, _res.data.chat_Supply_OccupantId],
    //            name: "d: " + _res.data.demand_UserId + " - s: " + _res.data.supply_UserId
    //        };

    //        window._kbh_chat_Supply_OccupantId = _res.data.chat_Supply_OccupantId;
    //        window._kbh_chat_Demand_OccupantId = _res.data.chat_Demand_OccupantId;
    //        window._kbh_meeting_supply_UserId = _res.data.supply_UserId;
    //        window._kbh_meeting_demand_UserId = _res.data.demand_UserId;
    //        window._kbh_meeting_id = _res.data.id;

    //        // type: 2 === PublicDialog === Private Group
    //        ChatService.createPublicDialog_V2(params.occupants_ids, params.name, null)
    //            .then((newDialog) => {

    //                ChatService.sendChatAlertOnCreate_V2(newDialog);

    //                const ccData = {
    //                    Chat_MeetingId: newDialog.id + "",
    //                    Id: window._kbh_meeting_id,
    //                    Supply_UserId: window._kbh_meeting_supply_UserId,
    //                    Demand_UserId: window._kbh_meeting_demand_UserId,
    //                    RelatedTo_MeetingResaved_Id: reservedId + ""
    //                };

    //                (async () => {
    //                    const ___res = await meetingService.updateMeetingWithCCId(ccData);
    //                    if (___res.success === true) {
    //                        //this.props.history.push("/meetings-archive");

    //                        if (meetingType === 0) {
    //                            //const ___diaglog = await ChatService.fetchDialogs_Really_ById_V2(ccData.Chat_MeetingId);
    //                            //ChatService.setSelectDialog(___diaglog);
    //                            ChatService.setSelectDialog(newDialog);
    //                            this.props.history.push("/message-chat/" + newDialog.id);
    //                        }
    //                        if (meetingType === 1) {
    //                            this.startVoiceCall(window._kbh_chat_Supply_OccupantId);
    //                        }
    //                        if (meetingType === 2) {
    //                            this.startCall(window._kbh_chat_Supply_OccupantId);
    //                        }

    //                    }
    //                    else {
    //                        toast.error("Error: " + ___res.message);
    //                    }
    //                })();

    //            })
    //            .catch((error) => {
    //                toast.error("Error: " + error);
    //            });
    //    }
    //    else {
    //        this.props.history.push("/meetings-archive");
    //    }
    //}





    //handleGotoChat = async (_id) => {
    //    //const diaglog = await ChatService.fetchDialogsById(_id);
    //    try {
    //        const diaglog = await ChatService.fetchDialogs_Really_ById_V2(_id);
    //        ChatService.setSelectDialog(diaglog);
    //        this.props.history.push("/message-chat/" + _id);
    //    }
    //    catch {
    //        alert("خطا در برقراری ارتباط ، مجددا سعی نمایید")
    //    }
    //}











    onDeleteMettingOld = async (e, id) => {
        e.preventDefault();

        window.favData = {
            Id: id + ""
        };

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'آیا از حذف این جلسه اطمینان دارید؟',
            text: "در صورت حذف آرشیو جلسات دیگر قادر به بازیابی آن نخواهید بود",
            icon: 'warning',
            showCancelButton: true,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#727272',
            confirmButtonText: 'بله ، حذف شود',
            cancelButtonText: 'خیر'
        })
            .then((result) => {
                if (result.isConfirmed) {

                    (async () => {

                        const _obj = await meetingService.deleteMetting(window.favData);
                        if (_obj.success === true) {
                            toast.success(_obj.message);

                            let meetingsLstOld = [...this.state.meetingsOld];
                            let objInDb = meetingsLstOld.find((x) => x.id === id) || {};
                            meetingsLstOld.splice(meetingsLstOld.indexOf(objInDb), 1);

                            this.setState({ meetingsOld: meetingsLstOld });
                        }
                        else {
                            toast.error(_obj.message);
                        }

                    })();

                }
            })

    };



    onFaveHandleOld = async (e, id) => {
        e.preventDefault();

        const favData = {
            Id: id + ""
        };

        this.props.startLoadingAction();


        const _obj = await consultantService.setFavorite(favData);
        if (_obj.success === true) {
            toast.success(_obj.message);

            let meetingsLstOld = [...this.state.meetingsOld];
            let objInDb = meetingsLstOld.filter((x) => x.supply_ProfileId === id) || [];

            for (var i = 0; i < objInDb.length; i++) {
                if (_obj.data.res === "added")
                    objInDb[i].isMyFavorite = true;
                if (_obj.data.res === "removed")
                    objInDb[i].isMyFavorite = false;
                var fIndex = this.findIndexByProperty(meetingsLstOld, 'id', objInDb[i].id);
                if (fIndex > -1)
                    meetingsLstOld[fIndex] = objInDb[i];
            }

            this.setState({ meetingsOld: meetingsLstOld });
        }
        else {
            toast.error(_obj.message);
        }

        this.props.finishLoadingAction();


    };




















    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند

    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">

                    <LoadingPage />


                    <div className="row p-0 m-0 pb-5">





                        <div data-tour="step-11" className="live-conv px-0 w-100 about-wrapper rounded-0  pt-2 pb-3 mt-0">
                            <p className="text-center">
                                <span style={{ fontWeight: "600", color: "blue", direction: "rtl" }}>
                                    جلسه آنلاین در حال برگزاری :
                                </span>
                                <br />
                                {/*<span style={{ color: "blue", direction: "rtl" }}>*/}
                                {/*    برای برگزاری جلسه زمانی که موبایل شما iphone است حتما از مرورگر safari استفاده کنید*/}
                                {/*</span>*/}
                            </p>


                            {this.state.meetings.length === 0
                                ?
                                (
                                    <>
                                        <div className="col-12 mx-auto">
                                            <br />
                                            <center > در حال حاضر جلسه‌ی آنلاینی وجود ندارد </center>
                                            <br />
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>


                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        {this.state.meetings.map(item => (

                                            <div className="col-xl-5 col-md-6 col-sm-10 mx-auto" key={item.id}>
                                                <div className="archive-widget">
                                                    <div className="row p-0 m-0">
                                                        <div className="col-5 user-side">
                                                            <div>
                                                                <img src={baseUrlAddress.getFilesBaseUrl() + item.supply_Avatar} />
                                                                <h4>{item.supply_UserName}</h4>
                                                                <p>
                                                                    روانشناس
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 user-connection">
                                                            {(item.meetingType === 0) &&
                                                                <i className="fas fa-comments"></i>
                                                            }
                                                            {(item.meetingType === 1) &&
                                                                <i className="fas fa-phone-volume"></i>
                                                            }
                                                            {(item.meetingType === 2) &&
                                                                <i className="fas fa-video"></i>
                                                            }
                                                        </div>
                                                        <div className="col-5 user-side">
                                                            <div>
                                                                <img src={baseUrlAddress.getFilesBaseUrl() + item.demand_Avatar} />
                                                                <h4>{item.demand_UserName}</h4>
                                                                <p>
                                                                    مراجع
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 archive-detail">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-6 p-2">
                                                                    <div className="archive-detail-widget">
                                                                        <p>
                                                                            <span>
                                                                                {item.timeDuration_InMinute}
                                                                                دقیقه
                                                                            </span>
                                                                            مدت زمان
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-2">
                                                                    <div className="archive-detail-widget">
                                                                        <p>
                                                                            <span style={{ direction: "ltr" }}>
                                                                                {item.proposaled_Start_DateTime_String_Miladi}
                                                                            </span>
                                                                            تاریخ
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="row p-0 m-0">
                                                            <div className="col-12 px-1">
                                                                <div className="row p-0 mx-0 mt-2">
                                                                    <div className="col-12 px-1">
                                                                        <p className="text-center">
                                                                            کد جلسه
                                                                            :
                                                                            {item.id}
                                                                        </p>
                                                                        <p className="text-center">
                                                                            موضوع
                                                                            :
                                                                            {item.consultantTypeName}
                                                                        </p>
                                                                        <p className="text-center">
                                                                            نرم افزار
                                                                            :
                                                                            {item.typeMeet}
                                                                        </p>


                                                                        <div>

                                                                            {(item.urlMeet !== null && item.urlMeet !== "") &&
                                                                                <p>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        href={item.urlMeet}
                                                                                        className="btn btn-start-consult rounded"
                                                                                    >
                                                                                        ورود به اتاق جلسه مشاوره
                                                                                    </a>
                                                                                </p>
                                                                            }

                                                                            {this.state.readyToCall
                                                                                ?
                                                                                <>
                                                                                    <p>
                                                                                        برگزاری جلسه مشاوره توسط تیم پشتیبانی یاری وی با شما هماهنگ میگردد.
                                                                                    </p>

                                                                                    {/* {(item.meetingType === 0) &&
                                                                                        <a
                                                                                            className="btn btn-start-consult rounded"
                                                                                            onClick={() => this.creatNewMeetingHandle(item.demand_UserId, item.supply_UserId, item.chat_Demand_OccupantId, item.chat_Supply_OccupantId, item.id, item.meetingType, item.chat_MeetingId, item.supply_Avatar, item.supply_UserName)}
                                                                                        >
                                                                                            شروع چت
                                                            </a>
                                                                                    } */}
                                                                                    {/* {(item.meetingType === 1) &&
                                                                                        <a
                                                                                        href={baseUrlAddress.getCallsBaseUrl() + "/?username=" + item.demand_Real_UserName + "&usernameside=" + item.supply_Real_UserName + "&mid=" + item.id + "&mtype=" + item.meetingType}
                                                                                            className="btn btn-start-consult rounded"
                                                                                        >
                                                                                        ورود به اتاق جلسه مشاوره
                                                            </a>
                                                                                    } */}
                                                                                    {/* {(item.meetingType === 2) &&
                                                                                        <a
                                                                                        href={baseUrlAddress.getCallsBaseUrl() + "/?username=" + item.demand_Real_UserName + "&usernameside=" + item.supply_Real_UserName + "&mid=" + item.id + "&mtype=" + item.meetingType}
                                                                                            className="btn btn-start-consult rounded"
                                                                                        >
                                                                                        ورود به اتاق جلسه مشاوره
                                                            </a>
                                                                                    } */}

                                                                                    { /* ----------- OLD ---------------- */}
                                                                                    { /*
                                                                                    {(item.meetingType === 1) &&
                                                                                        <a
                                                                                            onClick={() => this.creatNewMeetingHandle(item.demand_UserId, item.supply_UserId, item.chat_Demand_OccupantId, item.chat_Supply_OccupantId, item.id, item.meetingType, item.chat_MeetingId, item.supply_Avatar, item.supply_UserName)}
                                                                                            className="btn btn-start-consult rounded"
                                                                                        >
                                                                                            شروع تماس صوتی
                                                            </a>
                                                                                    }
                                                                                    {(item.meetingType === 2) &&
                                                                                        <a
                                                                                            onClick={() => this.creatNewMeetingHandle(item.demand_UserId, item.supply_UserId, item.chat_Demand_OccupantId, item.chat_Supply_OccupantId, item.id, item.meetingType, item.chat_MeetingId, item.supply_Avatar, item.supply_UserName)}
                                                                                            className="btn btn-start-consult rounded"
                                                                                        >
                                                                                            شروع تماس تصویری
                                                            </a>
                                                                                    }
                                                                                    */}
                                                                                    { /* ----------- OLD ---------------- */}

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <p>
                                                                                        برگزاری جلسه مشاوره توسط تیم پشتیبانی یاری وی با شما هماهنگ میگردد.
                                                                                    </p>
                                                                                    {/* <a
                                                                                        className="btn btn-stock rounded"
                                                                                        style={{ border: "1px solid darkred" }}
                                                                                    >
                                                                                                                                                                       برگزاری جلسه مشاوره توسط تیم پشتیبانی یاری وی با شما هماهنگ میگردد.

                                                            </a> */}
                                                                                </>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                        { /* ----------- key={item._id} must be for list in react ---------------- */}


                                    </>
                                )
                            }


                        </div>





                        <div data-tour="step-12" className="row live-conv px-0 w-100 about-wrapper rounded-0 pt-2 pb-3 mt-3" >
                            <div className="col-12">
                                <p className="text-center" style={{ fontWeight: "600", color: "blue", direction: "rtl" }}>
                                    لیست جلسات رزرو شده :
                                </p>
                            </div>

                            {this.state.reserves.length === 0
                                ?
                                (
                                    <>
                                        <div className="col-12 mx-auto">
                                            <br />
                                            <center > در حال حاضر درخواست جلسه‌ای ندارید </center>
                                            <br />
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>


                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        {this.state.reserves.map(item => (

                                            <div className="col-xl-4 col-sm-6 px-2" key={item.id}>
                                                <div className="notif-widget meetings-reserve-archive-card">
                                                    {/*<div className="reserve-type-icon-big">*/}
                                                    {/*    {(item.meetingType === 0) &&*/}
                                                    {/*        <i className="fas fa-comments"></i>*/}
                                                    {/*    }*/}
                                                    {/*    {(item.meetingType === 1) &&*/}
                                                    {/*        <i className="fas fa-phone-volume"></i>*/}
                                                    {/*    }*/}
                                                    {/*    {(item.meetingType === 2) &&*/}
                                                    {/*        <i className="fas fa-video"></i>*/}
                                                    {/*    }*/}
                                                    {/*</div>*/}

                                                    <div className="row p-0 m-0">
                                                       
                                                        <div className="col-12 p-0">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-3 p-0 d-flex align-items-center">
                                                                    <div className="user-image" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.supply_Avatar) + ")" }}>
                                                                    </div>
                                                                </div>
                                                                <div className="col-9 p-0 d-flex align-items-center">
                                                                    <div className="w-100 notif-name">
                                                                        <h2>{item.supply_UserName}</h2>
                                                                        <p>
                                                                            روانشناس
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-1">
                                                            <div className="reserve-offer">
                                                                <div className="reserve-offer-body">
                                                                    <ul className="px-1">
                                                                        {/*<li>*/}
                                                                        {/*    نوع جلسه :*/}
                                                                        {/*    <span>*/}
                                                                        {/*        {(item.meetingType === 0) &&*/}
                                                                        {/*            <span>*/}
                                                                        {/*                &nbsp;*/}
                                                                        {/*                چت*/}
                                                                        {/*                &nbsp;*/}
                                                                        {/*            </span>*/}
                                                                        {/*        }*/}
                                                                        {/*        {(item.meetingType === 1) &&*/}
                                                                        {/*            <span>*/}
                                                                        {/*                &nbsp;*/}
                                                                        {/*                تماس صوتی*/}
                                                                        {/*                &nbsp;*/}
                                                                        {/*            </span>*/}
                                                                        {/*        }*/}
                                                                        {/*        {(item.meetingType === 2) &&*/}
                                                                        {/*            <span>*/}
                                                                        {/*                &nbsp;*/}
                                                                        {/*                تماس  تصویری*/}
                                                                        {/*                &nbsp;*/}
                                                                        {/*            </span>*/}
                                                                        {/*        }*/}

                                                                        {/*        {*/}
                                                                        {/*            item.isOnlineRequest &&*/}
                                                                        {/*            (<span style={{ color: "#379199" }} >*/}
                                                                        {/*                (آنلاین)*/}
                                                                        {/*            </span>)*/}
                                                                        {/*        }*/}
                                                                        {/*    </span>*/}
                                                                        {/*</li>*/}
                                                                        <li>
                                                                            موضوع :
                                                                            <span>
                                                                                &nbsp;
                                                                                {item.consultantTypeName}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            روز پیشنهادی :
                                                                            <span className="miladi-date" style={{ unicodeBidi: "plaintext" }}>
                                                                                &nbsp;
                                                                                {item.start_Proposal_FullDate_InLocal_Miladi}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            زمان پیشنهادی :
                                                                            <span>
                                                                                &nbsp;
                                                                                {" " + item.start_Proposal_FromHour_InLocal + " "}
                                                                                &nbsp;
                                                                                الی
                                                                                &nbsp;
                                                                                {" " + item.start_Proposal_ToHour_InLocal + " "}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            وضعیت :
                                                                            <span>
                                                                                &nbsp;
                                                                                {(item.status === 0) &&
                                                                                    <span>
                                                                                        در انتظار پاسخ مشاور
                                                                                    </span>
                                                                                }
                                                                                {(item.status === 1) &&
                                                                                    <span>
                                                                                        پذیرفته شده توسط مشاور
                                                                                    </span>
                                                                                }
                                                                                {(item.status === 2) &&
                                                                                    <span>
                                                                                        رد شده توسط مشاور
                                                                                    </span>
                                                                                }
                                                                                {(item.status === 3) &&
                                                                                    <span>
                                                                                        در حال بررسی توسط کاربر
                                                                                    </span>
                                                                                }
                                                                                {(item.status === 4) &&
                                                                                    <span>
                                                                                        پذیرفته شده توسط کاربر
                                                                                    </span>
                                                                                }
                                                                                {(item.status === 5) &&
                                                                                    <span>
                                                                                        حذف شده توسط کاربر
                                                                                    </span>
                                                                                }
                                                                                &nbsp;
                                                                            </span>
                                                                            <span>
                                                                                {(item.isFinalAccepted_Status === true) &&
                                                                                    <>
                                                                                        {(item.status === 1) &&
                                                                                            <span className="labels-status">
                                                                                                <span className="is-active">
                                                                                                    پذیرفته شده
                                                                                                </span>
                                                                                            </span>
                                                                                        }
                                                                                        {(item.status === 2) &&
                                                                                            <span className="labels-status">
                                                                                                <span className="not-active">
                                                                                                    رد شده
                                                                                                </span>
                                                                                            </span>
                                                                                        }
                                                                                        {(item.status === 5) &&
                                                                                            <span className="labels-status">
                                                                                                <span className="not-active">
                                                                                                    حذف شده
                                                                                                </span>
                                                                                            </span>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {(item.isFinalAccepted_Status === false) &&
                                                                                    <span className="labels-status">
                                                                                        <span className="is-waiting">
                                                                                            در انتظار
                                                                                        </span>

                                                                                    </span>
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            کد رزرو جلسه :
                                                                            <span>
                                                                                &nbsp;
                                                                                {item.id}
                                                                            </span>
                                                                        </li>
                                                                        <li>

                                                                            <div style={{ width: "100%" }}>
                                                                                <div className="action-icon row p-0 m-0">
                                                                                    <span style={{ flexGrow: 1, fontWeight: "inherit" }} >

                                                                                        نرم افزار :
                                                                                        <span>
                                                                                            &nbsp;
                                                                                            {item.typeMeet}
                                                                                        </span>

                                                                                    </span>
                                                                                    {item.status !== 5
                                                                                        ?
                                                                                        <button
                                                                                            className="btn btn-delete-all d-flex"
                                                                                            onClick={(e) => this.onDeleteMetting(e, item.id)}
                                                                                        >
                                                                                            {/*{(item.status !== 2) &&*/}
                                                                                            <i className="fas fa-calendar-times"></i>
                                                                                            {/*}*/}
                                                                                            <span className="decline-this-reserve">
                                                                                                لغو جلسه
                                                                                            </span>
                                                                                           
                                                                                        </button>
                                                                                        :
                                                                                        <button
                                                                                            className="btn btn-delete-all d-flex"
                                                                                            style={{ visibility: "hidden" }}
                                                                                        >
                                                                                            <span className="decline-this-reserve">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                            {(item.status !== 2) &&
                                                                                                <i className="fas fa-calendar-times" style={{ visibility: "hidden" }}></i>
                                                                                            }
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </li>

                                                                    </ul>
                                                                    {/*<div className="w-100">*/}
                                                                    {/*    <div className="row p-0 m-0">*/}
                                                                    {/*        <div data-tour="step-13" className="col-12 px-1">*/}

                                                                    {/*            {(item.isFinalAccepted_Status === true) &&*/}
                                                                    {/*                <>*/}
                                                                    {/*                    {(item.status === 1) &&*/}
                                                                    {/*                            <div className="labels-status">*/}
                                                                    {/*                                <div className="is-active">*/}
                                                                    {/*                                    پذیرفته شده*/}
                                                                    {/*                                </div>*/}
                                                                    {/*                            </div>*/}
                                                                    {/*                    }*/}
                                                                    {/*                    {(item.status === 2) &&*/}
                                                                    {/*                            <div className="labels-status">*/}
                                                                    {/*                                <div className="not-active">*/}
                                                                    {/*                                    رد شده*/}
                                                                    {/*                                </div>*/}
                                                                    {/*                            </div>*/}
                                                                    {/*                    }*/}
                                                                    {/*                    {(item.status === 5) &&*/}
                                                                    {/*                            <div className="labels-status">*/}
                                                                    {/*                                <div className="not-active">*/}
                                                                    {/*                                    حذف شده*/}
                                                                    {/*                                </div>*/}
                                                                    {/*                            </div>*/}
                                                                    {/*                    }*/}
                                                                    {/*                </>*/}
                                                                    {/*            }*/}
                                                                    {/*            {(item.isFinalAccepted_Status === false) &&*/}
                                                                    {/*                    <div className="labels-status">*/}
                                                                    {/*                        <div className="is-waiting">*/}
                                                                    {/*                            در انتظار پاسخ*/}
                                                                    {/*                        </div>*/}

                                                                    {/*                    </div>*/}
                                                                    {/*            }*/}
                                                                    {/*        </div>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        ))}
                                        { /* ----------- key={item._id} must be for list in react ---------------- */}

                                    </>
                                )
                            }

                        </div>















                        <div  className="row live-conv px-0 w-100 about-wrapper rounded-0 pt-2 pb-3 mt-3" >
                            <div className="col-12">
                                <p className="text-center" style={{ fontWeight: "600", color: "blue", direction: "rtl" }}>
                                    لیست جلسات آرشیو شده :
                                </p>
                            </div>

                            {this.state.meetingsOld.length === 0
                                ?
                                (
                                    <>
                                        <div className="col-12 mx-auto">
                                            <br />
                                            <center > هنوز جلسه آرشیو‌ی ندارید </center>
                                            <br />
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>


                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        {this.state.meetingsOld.map(item => (

                                            <div className="col-xl-3 col-sm-6 px-2" key={item.id}>
                                                <div className="archive-widget">



                                                    <div className="action-icon row p-0 m-0 xxxxxxxxxxxxxxxxxxxxxxxx" style={{ justifyContent: "end" }}>

                                                        {(item.isMyFavorite === true)
                                                            ?
                                                            <a
                                                                className="bookmark active"
                                                                onClick={(e) => this.onFaveHandle(e, item.supply_ProfileId)}
                                                            >
                                                                <i className="fas fa-bookmark"></i>
                                                            </a>
                                                            :
                                                            <a
                                                                className="bookmark"
                                                                onClick={(e) => this.onFaveHandleOld(e, item.supply_ProfileId)}
                                                            >
                                                                <i className="fas fa-bookmark"></i>
                                                            </a>
                                                        }

                                                        <button
                                                            className="btn btn-delete-all"
                                                            onClick={(e) => this.onDeleteMettingOld(e, item.id)}
                                                        >
                                                            <i className="far fa-trash-alt"></i>
                                                        </button>

                                                    </div>


                                                    <div className="row p-0 m-0">




                                                        <div className="col-4 user-side">
                                                            <div>
                                                                <img src={baseUrlAddress.getFilesBaseUrl() + item.supply_Avatar} />
                                                            </div>
                                                        </div>
                                                        <div className="col-8 user-side">
                                                            <div>
                                                                <h4>{item.supply_UserName}</h4>
                                                            </div>
                                                        </div>

                                                        {/*<div className="col-5 user-side">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img src={baseUrlAddress.getFilesBaseUrl() + item.supply_Avatar} />*/}
                                                        {/*        <h4>{item.supply_UserName}</h4>*/}
                                                        {/*        <p>*/}
                                                        {/*            روانشناس*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="col-2 user-connection">*/}
                                                        {/*    {(item.meetingType === 0) &&*/}
                                                        {/*        <i className="fas fa-comments"></i>*/}
                                                        {/*    }*/}
                                                        {/*    {(item.meetingType === 1) &&*/}
                                                        {/*        <i className="fas fa-phone-volume"></i>*/}
                                                        {/*    }*/}
                                                        {/*    {(item.meetingType === 2) &&*/}
                                                        {/*        <i className="fas fa-video"></i>*/}
                                                        {/*    }*/}
                                                        {/*</div>*/}
                                                        {/*<div className="col-5 user-side">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img src={baseUrlAddress.getFilesBaseUrl() + item.demand_Avatar} />*/}
                                                        {/*        <h4>{item.demand_UserName}</h4>*/}
                                                        {/*        <p>*/}
                                                        {/*            کاربر*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}




                                                        <div className="col-12 archive-detail" style={{ marginTop: "3px" }}>
                                                            <div className="row p-0 m-0">
                                                                <div className="col-12 px-2 detail-it-in-archive">
                                                                    <p>
                                                                        کد جلسه
                                                                        :
                                                                        {item.id}
                                                                    </p>
                                                                </div>
                                                                <div className="col-12 px-2 detail-it-in-archive">
                                                                    <p>
                                                                        موضوع
                                                                        :
                                                                        {item.consultantTypeName}
                                                                    </p>
                                                                </div>
                                                              

                                                                <div className="col-12 px-2 detail-it-in-archive">
                                                                    <p>
                                                                        تاریخ
                                                                        :
                                                                        <span style={{ direction: "ltr" }}>
                                                                            {item.proposaled_Start_DateTime_String_Miladi}
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                                {/*<div className="col-12 px-2 detail-it-in-archive">*/}
                                                                {/*    <p>*/}
                                                                {/*        نرم افزار*/}
                                                                {/*        :*/}
                                                                {/*        {item.typeMeet}*/}
                                                                {/*    </p>*/}
                                                                {/*</div>*/}

                                                                {/*<div className="col-6 p-2">*/}
                                                                {/*    <div className="archive-detail-widget">*/}
                                                                {/*        <p>*/}
                                                                {/*            <span>*/}
                                                                {/*                {item.timeDuration_InMinute}*/}
                                                                {/*                دقیقه*/}
                                                                {/*            </span>*/}
                                                                {/*            مدت زمان*/}
                                                                {/*        </p>*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                                
                                                                <div className="col-12 p-0">
                                                                    <div className="your-feedback d-flex justify-content-between" style={{ marginTop: "2px" }}>
                                                                        <h4>امتیاز شما به این مشاور</h4>
                                                                        <div className="star-wrapper">
                                                                            <div className="rated">
                                                                                {
                                                                                    (item.starPoint === 5)
                                                                                        ?
                                                                                        (<input id="rating-5" type="radio" name="rating" value="5" className="checked" />)
                                                                                        :
                                                                                        (<input id="rating-5" type="radio" name="rating" value="5" />)
                                                                                }
                                                                                <label htmlFor="rating-5">
                                                                                    <i className="fas fa-star"></i>
                                                                                </label>

                                                                                {
                                                                                    (item.starPoint === 4)
                                                                                        ?
                                                                                        (<input id="rating-4" type="radio" name="rating" value="4" className="checked" />)
                                                                                        :
                                                                                        (<input id="rating-4" type="radio" name="rating" value="4" />)
                                                                                }
                                                                                <label htmlFor="rating-4">
                                                                                    <i className="fas fa-star"></i>
                                                                                </label>

                                                                                {
                                                                                    (item.starPoint === 3)
                                                                                        ?
                                                                                        (<input id="rating-3" type="radio" name="rating" value="3" className="checked" />)
                                                                                        :
                                                                                        (<input id="rating-3" type="radio" name="rating" value="3" />)
                                                                                }
                                                                                <label htmlFor="rating-3">
                                                                                    <i className="fas fa-star"></i>
                                                                                </label>

                                                                                {
                                                                                    (item.starPoint === 2)
                                                                                        ?
                                                                                        (<input id="rating-2" type="radio" name="rating" value="2" className="checked" />)
                                                                                        :
                                                                                        (<input id="rating-2" type="radio" name="rating" value="2" />)
                                                                                }
                                                                                <label htmlFor="rating-2">
                                                                                    <i className="fas fa-star"></i>
                                                                                </label>

                                                                                {
                                                                                    (item.starPoint === 1)
                                                                                        ?
                                                                                        (<input id="rating-1" type="radio" name="rating" value="1" className="checked" />)
                                                                                        :
                                                                                        (<input id="rating-1" type="radio" name="rating" value="1" />)
                                                                                }
                                                                                <label htmlFor="rating-1">
                                                                                    <i className="fas fa-star"></i>
                                                                                </label>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        (item.meetingType === 0)
                                                            ?
                                                            (
                                                                <div className="row p-0 m-0">
                                                                    <div className="col-6 pr-0 pl-1">
                                                                        <a className="btn btn-reserve-offline rounded" onClick={() => this.handleGotoChat(item.chat_MeetingId)}>
                                                                            آرشیو چت
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-6 pl-0 pr-1">
                                                                        {
                                                                            (item.starPoint === 0)
                                                                                ?
                                                                                (
                                                                                    <Link to={"/survey/" + item.supply_ProfileId + "/" + item.id}
                                                                                        className="btn btn-start-consult rounded">
                                                                                        نظرسنجی
                                                                                    </Link>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> شما قبلا در این نظرسنجی شرکت کرده اید </Tooltip>} >
                                                                                        <span className="d-inline-block w-100">
                                                                                            <button disabled style={{ pointerEvents: 'none' }} className="btn btn-offline rounded">نظرسنجی</button>
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <div className="col-12 p-0">
                                                                    {
                                                                        (item.starPoint === 0)
                                                                            ?
                                                                            (
                                                                                <Link to={"/survey/" + item.supply_ProfileId + "/" + item.id}
                                                                                    className="btn btn-start-consult rounded">
                                                                                    نظرسنجی
                                                                                </Link>
                                                                            )
                                                                            :
                                                                            (
                                                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> شما قبلا در این نظرسنجی شرکت کرده اید </Tooltip>} >
                                                                                    <span className="d-inline-block w-100">
                                                                                        <button disabled style={{ pointerEvents: 'none' }} className="btn btn-offline rounded">نظرسنجی</button>
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                            )
                                                                    }
                                                                </div>
                                                            )
                                                    }

                                                </div>
                                            </div>


                                        ))}
                                        { /* ----------- key={item._id} must be for list in react ---------------- */}

                                    </>
                                )
                            }

                        </div>






































                    </div>
                </div>


            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(MeetingsReserveArchive));
