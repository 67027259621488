import isNullOrWhiteSpaceOrEmpty from "../validation/isNullOrWhiteSpaceOrEmpty";

export function getTokenFromLocalStorage() {
    var _token = localStorage.getItem("refreshToken");
    if (!isNullOrWhiteSpaceOrEmpty(_token)) {
        return _token;
    }
    return null;
}

export function setTokenToLocalStorage(refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
}

export function removeTokenFromLocalStorage() {
    localStorage.removeItem("refreshToken");
}
