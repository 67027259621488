const isNullOrWhiteSpaceOrEmpty = value =>
        value === undefined ||
        value === null ||
        value === "" ||
        (typeof value === "undefined") ||
        (typeof value === "string" && value.trim() == "") ||
        (typeof value === "string" && value.trim().length === 0) ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (Array.isArray(value) && value.length < 1);

export default isNullOrWhiteSpaceOrEmpty;

// how to use:
//import isNullOrWhiteSpaceOrEmpty from "./helper/validation/isNullOrWhiteSpaceOrEmpty";
//let _isNotEmpty = !isNullOrWhiteSpaceOrEmpty(_var);
//if(!isNullOrWhiteSpaceOrEmpty(_var)) { }