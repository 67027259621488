import React from "react";
import { Route, Redirect } from "react-router-dom";
import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

const PrivateRoute = ({ component: Component, render, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !isNullOrWhiteSpaceOrEmpty(currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings())
                ?
                (
                    Component ? <Component {...props} /> : render(props)
                )
                :
                (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
        }
    />
);

export default PrivateRoute;


