import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from '../reducers/index';

//const initialState = {};
const middleware = [thunk];

const enhancers = [];
const windowIfDefined = typeof window === 'undefined' ? null : window;
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
    rootReducer,
    //initialState,
    compose(
        applyMiddleware(...middleware),
        ...enhancers
    )
);

export default store;