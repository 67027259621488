import * as menuActionTypes from "../actionTypes/menuActionTypes";

const initialState = {
    pageUrl: "---",
    pageTitle: "---"
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case menuActionTypes.Dashboard:
            return {
                ...state,
                pageUrl: "Dashboard",
                pageTitle: "خانه"
            };
        case menuActionTypes.AboutUs:
            return {
                ...state,
                pageUrl: "AboutUs",
                pageTitle: "درباره یاری‌وی"
            };
        case menuActionTypes.Blog:
            return {
                ...state,
                pageUrl: "Blog",
                pageTitle: "مجله روانشناسی"
            };
        case menuActionTypes.Categories:
            return {
                ...state,
                pageUrl: "Categories",
                pageTitle: "انتخاب موضوع جلسه"
            };
        case menuActionTypes.Consultant:
            return {
                ...state,
                pageUrl: "Consultant",
                pageTitle: "انتخاب روانشناس"
            };
        case menuActionTypes.ConsultantDetail:
            return {
                ...state,
                pageUrl: "ConsultantDetail",
                pageTitle: "مشخصات روانشناس"
            };
        case menuActionTypes.ConsultantDetailEvaluator:
            return {
                ...state,
                pageUrl: "ConsultantDetailEvaluator",
                pageTitle: "مشخصات مشاور ارزیابی"
            };
        case menuActionTypes.ConsultantResarve:
            return {
                ...state,
                pageUrl: "ConsultantResarve",
                pageTitle: "رزرو جلسه"
            };
            
        case menuActionTypes.ContactUs:
            return {
                ...state,
                pageUrl: "ContactUs",
                pageTitle: "ارتباط با پشتیبانی"
            };
        case menuActionTypes.Faq:
            return {
                ...state,
                pageUrl: "Faq",
                pageTitle: "سوالات متداول"
            };
        case menuActionTypes.MeetingsArchive:
            return {
                ...state,
                pageUrl: "MeetingsArchive",
                pageTitle: "آرشیو جلسات"
            };
        case menuActionTypes.MeetingsReserveArchive:
            return {
                ...state,
                pageUrl: "MeetingsReserveArchive",
                pageTitle: "لیست جلسات"
            };
        case menuActionTypes.InitialEvaluation:
            return {
                ...state,
                pageUrl: "InitialEvaluation",
                pageTitle: "ارزیابی اولیه"
            };
        case menuActionTypes.Profile:
            return {
                ...state,
                pageUrl: "Profile",
                pageTitle: "ویرایش اطلاعات من"
            };
        case menuActionTypes.Setting:
            return {
                ...state,
                pageUrl: "Setting",
                pageTitle: "تنظیمات"
            };
        case menuActionTypes.Transaction:
            return {
                ...state,
                pageUrl: "Transaction",
                pageTitle: "امور مالی"
            };
        case menuActionTypes.Notification:
            return {
                ...state,
                pageUrl: "Notification",
                pageTitle: "اعلانات"
            };
        case menuActionTypes.FileRecord:
            return {
                ...state,
                pageUrl: "FileRecord",
                pageTitle: "پرونده کاربر"
            };
        case menuActionTypes.Survey:
            return {
                ...state,
                pageUrl: "Survey",
                pageTitle: "نظرسنجی"
            };
        case menuActionTypes.NotFound:
            return {
                ...state,
                pageUrl: "NotFound",
                pageTitle: "صفحه ای پیدا نشد"
            };
        case menuActionTypes.MessageChat:
            return {
                ...state,
                pageUrl: "MessageChat",
                pageTitle: "جلسه چت"
            };
        case menuActionTypes.FavoriteBlog:
            return {
                ...state,
                pageUrl: "FavoriteBlog",
                pageTitle: "مقالات منتخب"
            };
        case menuActionTypes.FavoriteConsultant:
            return {
                ...state,
                pageUrl: "FavoriteConsultant",
                pageTitle: "مشاوران منتخب"
            };
        case menuActionTypes.IntroducingCode:
            return {
                ...state,
                pageUrl: "IntroducingCode",
                pageTitle: "معرفی به دوستان"
            };
            
        default:
            return state;
    }
}
