import React, { Component } from "react";

class Footer extends Component {
    constructor(props) {
        super(props);
        //props.xxxx
    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

export default Footer;
