import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import * as profileService from "../../services/profile/profileService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

class UserSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MeetingCount: 0,
            MeetingDuration: 0,
            CostDuration: 0
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        const _obj = await profileService.getProfileSummary();
        if (_obj.success === true)
            if (_obj.data !== null)
                this.setState({
                    MeetingCount: _obj.data.meetingCount,
                    MeetingDuration: _obj.data.meetingDuration,
                    CostDuration: _obj.data.costDuration,
                });
    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // م0ثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="row p-0 m-0 mt-3">
                    <div className="col-12 px-2">
                        <div className="arch-detail">
                            <h2 className="arch-heading-side">گزارش کلی جلسات مشاوره شما</h2>
                            <div className="arch-single-detail">
                                <div className="row p-0 m-0">
                                    <div className="col-4 p-0">
                                        <div className="arch-detail-item">
                                            <i className="fas fa-chalkboard"></i>
                                            <h6>{this.state.MeetingCount}</h6>
                                            <p>تعداد جلسات مشاوره</p>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0">
                                        <div className="arch-detail-item">
                                            <i className="fas fa-users"></i>
                                            <h6>{this.state.MeetingDuration}</h6>
                                            <p>مجموع ساعت مشاوره</p>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0">
                                        <div className="arch-detail-item border-left-0">
                                            <i className="fas fa-sms"></i>
                                            <h6 className="miladi-date">{this.state.CostDuration} $</h6>
                                            <p>مجموع هزینه مشاوره</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default UserSummary;
