export const Dashboard = "Dashboard";
export const Categories = "Categories";
export const MeetingsReserveArchive = "MeetingsReserveArchive";
export const MeetingsArchive = "MeetingsArchive";
export const Blog = "Blog";
export const Transaction = "Transaction";
export const Faq = "Faq";
export const AboutUs = "AboutUs";
export const ContactUs = "ContactUs";
export const Profile = "Profile";
export const Setting = "Setting";
export const Notification = "Notification";
export const NotFound = "NotFound";
export const Survey = "Survey";
export const FileRecord = "FileRecord";
//message-chat
export const MessageChat = "MessageChat";
export const FavoriteBlog = "FavoriteBlog";
export const FavoriteConsultant = "FavoriteConsultant";
export const IntroducingCode = "IntroducingCode";
export const Consultant = "Consultant";
export const ConsultantDetail = "ConsultantDetail";
export const ConsultantResarve = "ConsultantResarve";
export const InitialEvaluation = "InitialEvaluation";
export const ConsultantDetailEvaluator = "ConsultantDetailEvaluator";

