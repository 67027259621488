//مدیریت مرکزی تمام reducerهای سفارشی تعریف شده‌ی در برنامه‌است
import { combineReducers } from "redux";

import accountReducer from "./accountReducer";
import alertReducer from "./alertReducer";
import loadingReducer from "./loadingReducer";
import menuReducer from "./menuReducer";
import currentUser from "./cc-message-Deprecated/currentUser";
import dialogs from "./cc-message-Deprecated/dialogs";
import selectedDialog from "./cc-message-Deprecated/selectedDialog";
import messages from "./cc-message-Deprecated/messages";
import users from "./cc-message-Deprecated/users";
import userLogOut from "./cc-message-Deprecated/userLogOut";

const rootReducer = combineReducers({
    accountReducer,
    alertReducer,
    loadingReducer,
    menuReducer,
    currentUser,      // cc-message
    dialogs,          // cc-message
    selectedDialog,   // cc-message
    messages,         // cc-message
    users,            // cc-message
    userLogOut        // cc-message
});

export default rootReducer;