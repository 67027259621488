import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import "../../theme/assets/css/filepond.css";
import * as profileService from "../../services/profile/profileService";
import * as accountService from "../../services/account/accountService";

import { refreshTokenAction } from "../../redux/actions/accountActions";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";



import 'react-phone-number-input/style.css';
// 1 : all complete component :
//import PhoneInput_All from 'react-phone-number-input';
// 2 : only numberPhone input :
//import PhoneInput from 'react-phone-number-input/input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
//import labelsEN from 'react-phone-number-input/locale/en.json';

// better : https://www.npmjs.com/package/react-phone-input-2



class EditProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            mobileNo: "",
            emailAddress: "",
            mobileNo_Verified: "false",
            emailAddress_Verified: "false",
            userLogo: "",
            avatarAddr: "",
            changedLogoSrc: "",
            selectedFileImageUpload: "",
            isUploading: false,
            DemandCountryNumber: 0,

            showMobileVerifyBox: "false",
            mobileVerifyCodeText: "",
            showEmailVerifyBox: "false",
            emailVerifyCodeText: "",

            countryNumber: 0,
            countryName: "",
            country: "",
            isAcceptedRules: false,
            timeZoneName: "",
            isShowTimezone: false,
            Fa_TimeZones: [],
            Fa_labels: [],
            All_labels: [],
        };

        currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        if (this.props.accountState.currentUser) {
            this.state = {
                firstName: this.props.accountState.currentUser.FirstName,
                lastName: this.props.accountState.currentUser.LastName,
                mobileNo: "",
                emailAddress: "",
                mobileNo_Verified: this.props.accountState.currentUser.Mobile_Has_Verified + "",
                emailAddress_Verified: this.props.accountState.currentUser.Email_Has_Verified + "",
                userLogo: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar,
                changedLogoSrc: "",
                avatarAddr: "",
                selectedFileImageUpload: "",
                isUploading: false,
                DemandCountryNumber: 0,

                showMobileVerifyBox: "false",
                mobileVerifyCodeText: "",
                showEmailVerifyBox: "false",
                emailVerifyCodeText: "",

                countryNumber: 0,
                countryName: "",
                country: "",
                isAcceptedRules: false,
                timeZoneName: "",
                isShowTimezone: false,
                Fa_TimeZones: [],
                Fa_labels: [],
                All_labels: [],
            };
        }
    }


    async componentDidMount() {
        store.dispatch({
            type: menuActionTypes.Profile
        });

        this.props.startLoadingAction();

        const _result = await profileService.getProfile();
        if (_result.success) {
            this.setState({
                mobileNo: _result.data.mobile,
                emailAddress: _result.data.email,
                mobileNo_Verified: _result.data.mobile_Has_Verified + "",
                emailAddress_Verified: _result.data.email_Has_Verified + "",
                DemandCountryNumber: _result.data.demandCountryNumber,
                timeZoneName: _result.data.timeZoneDisplayName
            });
            //toast.success("اطلاعات کاربر بارگزاری شد");
        }
        else {
            toast.error(_result.message + " - " + " خطایی پیش آمده لطفا این صفحه را Refresh کنید ");
        }

        // --------------------------------------------------------------------------

        const _List = await accountService.userCountries();
        if (_List.success === true)
            if (_List.data !== null) {
                this.setState({ Fa_labels: _List.data });
                this.setState({ All_labels: getCountries() });
            }

        //console.warn("====================================");
        //console.warn(getCountries());
        //console.warn("====================================");
        //console.warn(_List.data);
        //console.warn("====================================");


        this.props.finishLoadingAction();

    }











    setCountryName = value => {
        let _elm = document.getElementsByName("countryNumberCountry");
        let _elmTxt = _elm[0].options[_elm[0].selectedIndex].text;
        this.setState({ countryName: _elmTxt + " (" + value + ")" });
    }


    setCountryNumber = value => {
        try {
            if (value.indexOf('+') > -1) {
                this.setState({ countryNumber: value.replace('+', '') });
            }
            else {
                this.setState({ countryNumber: value });
            }
            //console.log(value.replace('+', ''));
        }
        catch (e) {
            // Nothing ...
        }
    }


    setCountry = async (val) => {


        if (val === "00000") {
            toast.error("کشور خود را انتخاب کنید");
            return;
        }


        this.setState({ country: val });
        this.setCountryNumber(getCountryCallingCode(val));
        this.setCountryName(val);


        this.props.startLoadingAction();

        let data = {
            code: val,
        };
        const _List = await accountService.farsiCountries(data);
        if (_List.success === true)
            if (_List.data !== null) {
                this.setState({ Fa_TimeZones: _List.data });

                if (_List.data.length > 1) {
                    this.setState({ isShowTimezone: true });
                    this.setState({ timeZoneName: "" });
                }
                else {
                    this.setState({ isShowTimezone: false });
                    this.setState({ timeZoneName: _List.data[0].timeZone_Id__Value });
                }
            }
        this.props.finishLoadingAction();

    }


    onChangeAcceptedRules = () => {
        this.setState({
            isAcceptedRules: !this.state.isAcceptedRules,
        });
    }




    onChangeTimeZoneHandle = async e => {
        if (e.target.value !== "") {
            this.setState({ [e.target.name]: e.target.value });
        }
        else {
            toast.error("منطقه زمانی خود را انتخاب کنید");
        }
    };


    //onChangeTimeZoneHandle2 = async e => {

    //    if (e.target.value !== "") {

    //        this.setState({ [e.target.name]: e.target.value });

    //        const userData = {
    //            UserName: this.state.userName,
    //            TimeZoneDisplayName: e.target.value
    //        };
    //        const _result = await profileService.setUserTimeZone(userData);
    //        if (_result.success) {
    //            toast.success("منطقه زمانی تغییر کرد");
    //        }
    //        else
    //            toast.error(_result.message);

    //    }
    //    else {
    //        toast.error("منطقه زمانی خود را انتخاب کنید");
    //    }


    //};



    changeEmailOrMobile = e => {
        this.setState({ [e.target.name]: e.target.value });
    };











    setIsUploading = (check) => {
        this.setState({ isUploading: check });
    }

    setselectedFileImageUpload = (file) => {
        this.setState({ selectedFileImageUpload: file });

        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                changedLogoSrc: [reader.result],
                avatarAddr: "",
            })
        }.bind(this);
    }

    //componentDidUpdate(prevProps, prevState, snapshot) {

    //    if (this.state.firstName == undefined)
    //        if (this.props.currentUser.firstName != undefined)
    //            this.setState({ firstName: this.props.currentUser.firstName });

    //    if (this.state.lastName == undefined)
    //        if (this.props.currentUser.lastName != undefined)
    //            this.setState({ lastName: this.props.currentUser.lastName });
    //}


    //componentDidMount() {
    //currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
    //if (this.props.accountState.currentUser) {
    //    // True :
    //    this.setState({});
    //    // Wrong :
    //////////////////////////    this.state = {
    //////////////////////////        firstName: this.props.accountState.currentUser.FirstName,
    //////////////////////////        lastName: this.props.accountState.currentUser.LastName,
    //////////////////////////        userLogo: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar,
    //////////////////////////        changedLogoSrc: "",
    //////////////////////////        selectedFileImageUpload: "",
    //////////////////////////        isUploading: false
    //////////////////////////    };
    //    // True :
    //    this.setState({});
    //}
    //}

    onChangeHandle = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    isFileValid = (selectedFile) => {
        if (!selectedFile) {
            // toast.error("Please select a file.");
            return false;
        }

        const allowedMimeTypes = [
            "image/png",
            "image/jpeg",
            "image/gif",
            "image/svg+xml"
        ];
        if (!allowedMimeTypes.includes(selectedFile.type)) {
            toast.error(`فرمت اشتباه فایل : ${selectedFile.type}`);
            return false;
        }

        const maxFileSize = 1024 * 500;
        const fileSize = selectedFile.size;
        if (fileSize > maxFileSize) {
            toast.error(
                `حجم فایل ${(fileSize / 1024).toFixed(
                    2
                )} کیلوبایت است که باید کمتر از ${maxFileSize / 1024} کیلوبایت باشد`
            );
            return false;
        }

        return true;
    };


    changeAvatar = (avatar) => {
        this.setState({
            avatarAddr: avatar,
            userLogo: baseUrlAddress.getFilesBaseUrl() + avatar,
            changedLogoSrc: ""
        });
    }


    onVerifyHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        let userData = {
            EmailVerifyCodeText: this.state.emailVerifyCodeText,
            MobileVerifyCodeText: this.state.mobileVerifyCodeText,
        };


        let isRefreshDone = false;
        const _newUserChanged = await profileService.setVerify(userData);
        if (_newUserChanged.success) {
            if (_newUserChanged.data !== null) {


                isRefreshDone = await this.props.refreshTokenAction();
                toast.success("تغییرات با موفقیت ثبت شد");


                this.setState({
                    mobileNo: _newUserChanged.data.mobile,
                    emailAddress: _newUserChanged.data.email,
                    mobileNo_Verified: _newUserChanged.data.mobile_Has_Verified + "",
                    emailAddress_Verified: _newUserChanged.data.email_Has_Verified + "",
                    DemandCountryNumber: _newUserChanged.data.demandCountryNumber,
                    DemandCountryName: _newUserChanged.data.demandCountryName,
                    timeZoneName: _newUserChanged.data.timeZoneDisplayName
                });

                this.setState({ showEmailVerifyBox: "false" });
                this.setState({ showMobileVerifyBox: "false" });

                this.props.finishLoadingAction();

            }
        }
        else
            toast.error(_newUserChanged.message);

        this.props.finishLoadingAction();


        if (isRefreshDone)
            currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();

    }




    onSubmitHandle = async (e) => {
        e.preventDefault();



        if (this.state.mobileNo.length < 6) {
            alert("تعداد کاراکتر های شماره موبایل مجاز نمی باشد");
            return;
        }

        const _regx = /^[0-9\b]+$/; // only number
        if (!_regx.test(this.state.mobileNo)) {
            alert("فیلد شماره موبایل فقط میتواند کاراکتر عددی باشد");
            return;
        }





        this.props.startLoadingAction();


        let _avatar = null;

        if (this.state.avatarAddr == "") {

            if (this.isFileValid(this.state.selectedFileImageUpload)) {

                const formData = new FormData();
                formData.append("Image", this.state.selectedFileImageUpload);

                try {
                    this.setIsUploading(true);

                    const _result = await profileService.setProfileImage(formData);
                    if (_result.success) {
                        _avatar = _result.data;
                        //toast.success("Profile Image changed successfully!");
                    }
                    else {
                        toast.error(_result.message);
                        //toast.error("Profile Image Not Changed");
                        this.setState({
                            userLogo: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar,
                            changedLogoSrc: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar
                        });
                    }
                    this.setIsUploading(false);
                }
                catch (error) {
                    this.setIsUploading(false);
                    toast.error(error);
                    //toast.error("Profile Image Not Changed");
                    this.setState({
                        userLogo: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar,
                        changedLogoSrc: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar
                    });
                }
            }
            else {
                //toast.success("Profile Image Not Changed");
                this.setState({
                    userLogo: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar,
                    changedLogoSrc: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar
                });
            }

        }
        else {
            _avatar = this.state.avatarAddr;
        }




        let userData = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.emailAddress,
            Mobile: this.state.mobileNo,
            Avatar: _avatar,
            TimeZoneDisplayName: this.state.timeZoneName,
            DemandCountryNumber: this.state.countryNumber,
            DemandCountryName: this.state.countryName,
            //Mobile_Has_Verified: this.state.mobileNo_Verified,
            //Email_Has_Verified: this.state.emailAddress_Verified,
        };


        let isRefreshDone = false;
        const _newUserChanged = await profileService.setProfile(userData);
        if (_newUserChanged.success) {
            if (_newUserChanged.data !== null) {
                isRefreshDone = await this.props.refreshTokenAction();
                toast.success("تغییرات با موفقیت ثبت شد");


                if (!_newUserChanged.data.email_Has_Verified) {
                    this.setState({ showEmailVerifyBox: "true" });
                }

                if (!_newUserChanged.data.mobile_Has_Verified) {
                    this.setState({ showMobileVerifyBox: "true" });
                }

            }
        }
        else
            toast.error(_newUserChanged.message);

        this.props.finishLoadingAction();


        if (isRefreshDone)
            currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();

    };


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div className="doctors-page">

                    <LoadingPage />


                    <div className="col-md-6 mx-auto">
                        <div className="profile-edit">



                            {
                                (this.state.showEmailVerifyBox === "true") || (this.state.showMobileVerifyBox === "true")
                                    ?
                                    <>

                                        <form className="verify-edit-form" onSubmit={this.onVerifyHandle}>


                                            {(this.state.showEmailVerifyBox === "true") && <>


                                                <div className="form-group mb-2">
                                                    <label>
                                                        کد تایید، ارسال شده به (Email) آدرس ایمیل :
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        id="emailVerifyCodeText"
                                                        name="emailVerifyCodeText"
                                                        value={this.state.emailVerifyCodeText}
                                                        onChange={this.onChangeHandle}
                                                    />
                                                </div>

                                            </>}



                                            {(this.state.showMobileVerifyBox === "true") && <>


                                                <div className="form-group mb-2">
                                                    <label>
                                                        کد تایید، ارسال شده به (Mobile) شماره موبایل :
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        id="mobileVerifyCodeText"
                                                        name="mobileVerifyCodeText"
                                                        value={this.state.mobileVerifyCodeText}
                                                        onChange={this.onChangeHandle}
                                                    />
                                                </div>


                                            </>}


                                            <input
                                                type="submit"
                                                className="btn btn-start-consult rounded mt-2"
                                                value="ثبت کد تایید"
                                            />
                                        </form>



                                    </>
                                    :
                                    <>

                                        <form encType="multipart/form-data">
                                            <div className="form-group row">

                                                <label htmlFor="fileImageUpload" style={{ width: "100%", display: "block", cursor: "pointer" }}>
                                                    <div className="pro-pic-wrap">
                                                        <div className="pro-pic-cont">
                                                            <i className="fas fa-camera"></i>

                                                            {
                                                                (this.state.changedLogoSrc !== "")
                                                                    ?
                                                                    (
                                                                        <img src={this.state.changedLogoSrc} style={{ width: "100px", height: "100px", objectFit: "cover", display: "block", margin: "0 auto", borderRadius: "80px" }} />
                                                                    )
                                                                    :
                                                                    (
                                                                        <img src={this.state.userLogo} style={{ width: "100px", height: "100px", objectFit: "cover", display: "block", margin: "0 auto", borderRadius: "80px" }} />
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </label>
                                                <input
                                                    id="fileImageUpload"
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    className="form-control"
                                                    name="fileImageUpload"
                                                    onChange={event => this.setselectedFileImageUpload(event.target.files[0])}
                                                />
                                            </div>
                                        </form>

                                        <form className="profile-edit-form" onSubmit={this.onSubmitHandle}>

                                            <div className="form-group" style={{ textAlign: "center" }}>
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/1.png"} onClick={() => this.changeAvatar("/portal/avatars/1.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/2.png"} onClick={() => this.changeAvatar("/portal/avatars/2.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/3.png"} onClick={() => this.changeAvatar("/portal/avatars/3.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/4.png"} onClick={() => this.changeAvatar("/portal/avatars/4.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/5.png"} onClick={() => this.changeAvatar("/portal/avatars/5.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/6.png"} onClick={() => this.changeAvatar("/portal/avatars/6.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/7.png"} onClick={() => this.changeAvatar("/portal/avatars/7.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/8.png"} onClick={() => this.changeAvatar("/portal/avatars/8.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/9.png"} onClick={() => this.changeAvatar("/portal/avatars/9.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/10.png"} onClick={() => this.changeAvatar("/portal/avatars/10.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/11.png"} onClick={() => this.changeAvatar("/portal/avatars/11.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/12.png"} onClick={() => this.changeAvatar("/portal/avatars/12.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/13.png"} onClick={() => this.changeAvatar("/portal/avatars/13.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/14.png"} onClick={() => this.changeAvatar("/portal/avatars/14.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/15.png"} onClick={() => this.changeAvatar("/portal/avatars/15.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                                <img src={baseUrlAddress.getFilesBaseUrl() + "/portal/avatars/16.png"} onClick={() => this.changeAvatar("/portal/avatars/16.png")} style={{ width: "40px", height: "40px", objectFit: "cover", display: "inline-block", margin: "0 auto", borderRadius: "80px", cursor: "pointer" }} />
                                            </div>

                                            <div className="form-group mb-2">
                                                <label>
                                                    <span className="mr-1" style={{ color: "#e91e63", fontSize: "16px" }}>*</span>
                                                    &nbsp;
                                                    نام
                                                </label>
                                                <input className="form-control" type="text" placeholder="نام" id="firstName" name="firstName" value={this.state.firstName} onChange={this.onChangeHandle} />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label>
                                                    <span className="mr-1" style={{ color: "#e91e63", fontSize: "16px" }}>*</span>
                                                    &nbsp;
                                                    نام خانوادگی
                                                </label>
                                                <input className="form-control" type="text" placeholder="نام خانوادگی" id="lastName" name="lastName" value={this.state.lastName} onChange={this.onChangeHandle} />
                                            </div>
                                            <div className="form-group mb-2" style={{ display: "none" }}>
                                                <label>
                                                    <span className="mr-1" style={{ color: "#e91e63", fontSize: "16px" }}>*</span>
                                                    &nbsp;
                                                    جنسیت
                                                </label>
                                                <select className="form-control">
                                                    <option value="00000">
                                                        جنسیت خود را مشخص کنید ...
                                                    </option>
                                                    <option>
                                                        زن
                                                    </option>
                                                    <option>
                                                        مرد
                                                    </option>
                                                    <option>
                                                        سایر
                                                    </option>
                                                </select>
                                            </div>


                                            <div className="form-group mb-2">
                                                <label>
                                                    <span className="mr-1" style={{ color: "#e91e63", fontSize: "16px" }}>*</span>
                                                    &nbsp;
                                                    آدرس ایمیل {this.state.emailAddress_Verified === "true" ? (<span style={{ display: "inline-block", background: "lawngreen", padding: "3px", borderRadius: "6px", color: "black" }}> تایید موفق </span>) : (<span style={{ display: "inline-block", background: "indianred", padding: "3px", borderRadius: "6px", color: "black" }}> تایید نشده </span>)}
                                                </label>
                                                {this.state.emailAddress_Verified === "true"
                                                    ?
                                                    (<>
                                                        <input
                                                            className="form-control"
                                                            id="emailAddress"
                                                            name="emailAddress"
                                                            value={this.state.emailAddress}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </>)
                                                    :
                                                    (<>
                                                        <input
                                                            className="form-control"
                                                            id="emailAddress"
                                                            name="emailAddress"
                                                            value={this.state.emailAddress}
                                                            placeholder=""
                                                            onChange={this.onChangeHandle}
                                                        />
                                                    </>)}
                                            </div>



                                            {/*<div className="form-group mb-2">*/}
                                            {/*    <label>*/}
                                            {/*        <span className="mr-1" style={{ color: "#e91e63", fontSize: "16px" }}>*</span>*/}
                                            {/*        &nbsp;*/}
                                            {/*        شماره موبایل {this.state.mobileNo_Verified === "true" ? (<span style={{ display: "inline-block", background: "lawngreen", padding: "3px", borderRadius: "6px", color: "black" }}> تایید موفق </span>) : (<span style={{ display: "inline-block", background: "indianred", padding: "3px", borderRadius: "6px", color: "black" }}> تایید نشده </span>)}*/}
                                            {/*    </label>*/}
                                            {/*    <input className="form-control" id="mobileNo" name="mobileNo" value={this.state.mobileNo} placeholder="" onChange={this.onChangeHandle} />*/}
                                            {/*</div>*/}




                                            <>
                                                <div className="form-group phone-input-cont">


                                                    <label>
                                                        <span className="mr-1" style={{ color: "#e91e63", fontSize: "16px" }}>*</span>
                                                        &nbsp;
                                                        شماره موبایل {this.state.mobileNo_Verified === "true" ? (<span style={{ display: "inline-block", background: "lawngreen", padding: "3px", borderRadius: "6px", color: "black" }}> تایید موفق </span>) : (<span style={{ display: "inline-block", background: "indianred", padding: "3px", borderRadius: "6px", color: "black" }}> تایید نشده </span>)}
                                                    </label>



                                                    {this.state.mobileNo_Verified === "true"

                                                        ?

                                                        (<>

                                                            <div className="cap-focus d-flex">

                                                                <input
                                                                    type="tel"
                                                                    autoComplete="tel"
                                                                    id="mobileNo"
                                                                    name="mobileNo"
                                                                    value={this.state.mobileNo}
                                                                    className="form-control login-input have-cont-code"
                                                                    placeholder="شماره موبایل بدون کد کشور و بدون کاراکتر 0 ابتدای موبایل"
                                                                    disabled
                                                                />


                                                                <div
                                                                    class="PhoneInput"
                                                                    style={{ color: "rgb(71, 71, 71)", fontWeight: "300", fontSize: "13px", justifyContent: "center" }}
                                                                >
                                                                    {this.state.DemandCountryNumber}+
                                                                </div>


                                                            </div>




                                                            <div className="form-group mb-2">
                                                                {/*<div className="alert alert-success text-center mb-2" role="alert">*/}
                                                                {/*    کاربرگرامی موقعیت زمانی خود را برای هماهنگ کردن جلسات مشاوره مشخص کنید.*/}
                                                                {/*</div>*/}
                                                                {/*<label className=" control-label mb-2">مشخص کردن موقعیت زمانی</label>*/}


                                                                <label>
                                                                    <span className="mr-1" style={{ color: "#e91e63", fontSize: "16px" }}>*</span>
                                                                    &nbsp;
                                                                    مشخص کردن موقعیت زمانی
                                                                </label>


                                                                <select className="form-control timezone-selector w-100" id="timeZoneName" name="timeZoneName" value={this.state.timeZoneName} onChange={this.onChangeTimeZoneHandle}>
                                                                    <option value="">انتخاب کنید ...</option>
                                                                    <option value="(GMT) Casablanca">(GMT) Casablanca (Morocco)</option>
                                                                    <option value="(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London">(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (GMT)</option>
                                                                    <option value="(GMT) Monrovia, Reykjavik">(GMT) Monrovia, Reykjavik (Greenwich)</option>
                                                                    <option value="(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (W. Europe)</option>
                                                                    <option value="(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Central Europe)</option>
                                                                    <option value="(GMT+01:00) Brussels, Copenhagen, Madrid, Paris">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris (Romance)</option>
                                                                    <option value="(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Central European)</option>
                                                                    <option value="(GMT+01:00) West Central Africa">(GMT+01:00) West Central Africa (W. Central Africa)</option>
                                                                    <option value="(GMT+02:00) Amman">(GMT+02:00) Amman (Jordan)</option>
                                                                    <option value="(GMT+02:00) Athens, Bucharest, Istanbul">(GMT+02:00) Athens, Bucharest, Istanbul (GTB)</option>
                                                                    <option value="(GMT+02:00) Beirut">(GMT+02:00) Beirut (Middle East)</option>
                                                                    <option value="(GMT+02:00) Cairo">(GMT+02:00) Cairo (Egypt)</option>
                                                                    <option value="(GMT+02:00) Harare, Pretoria">(GMT+02:00) Harare, Pretoria (South Africa)</option>
                                                                    <option value="(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (FLE)</option>
                                                                    <option value="(GMT+02:00) Jerusalem">(GMT+02:00) Jerusalem (Israel)</option>
                                                                    <option value="(GMT+02:00) Minsk">(GMT+02:00) Minsk (E. Europe)</option>
                                                                    <option value="(GMT+02:00) Windhoek">(GMT+02:00) Windhoek (Namibia)</option>
                                                                    <option value="(GMT+03:00) Baghdad">(GMT+03:00) Baghdad (Arabic)</option>
                                                                    <option value="(GMT+03:00) Kuwait, Riyadh">(GMT+03:00) Kuwait, Riyadh (Arab)</option>
                                                                    <option value="(GMT+03:00) Moscow, St. Petersburg, Volgograd">(GMT+03:00) Moscow, St. Petersburg, Volgograd (Russian)</option>
                                                                    <option value="(GMT+03:00) Nairobi">(GMT+03:00) Nairobi (E. Africa)</option>
                                                                    <option value="(GMT+03:00) Tbilisi">(GMT+03:00) Tbilisi (Georgian)</option>
                                                                    <option value="(GMT+03:30) Tehran">(GMT+03:30) Tehran (Iran)</option>
                                                                    <option value="(GMT+04:00) Abu Dhabi, Muscat">(GMT+04:00) Abu Dhabi, Muscat (Arabian)</option>
                                                                    <option value="(GMT+04:00) Baku">(GMT+04:00) Baku (Azerbaijan)</option>
                                                                    <option value="(GMT+04:00) Port Louis">(GMT+04:00) Port Louis (Mauritius)</option>
                                                                    <option value="(GMT+04:00) Yerevan">(GMT+04:00) Yerevan (Caucasus)</option>
                                                                    <option value="(GMT+04:30) Kabul">(GMT+04:30) Kabul (Afghanistan)</option>
                                                                    <option value="(GMT+05:00) Ekaterinburg">(GMT+05:00) Ekaterinburg (Ekaterinburg)</option>
                                                                    <option value="(GMT+05:00) Islamabad, Karachi">(GMT+05:00) Islamabad, Karachi (Pakistan)</option>
                                                                    <option value="(GMT+05:00) Tashkent">(GMT+05:00) Tashkent (West Asia)</option>
                                                                    <option value="(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi (India)</option>
                                                                    <option value="(GMT+05:30) Sri Jayawardenepura">(GMT+05:30) Sri Jayawardenepura (Sri Lanka)</option>
                                                                    <option value="(GMT+05:45) Kathmandu">(GMT+05:45) Kathmandu (Nepal)</option>
                                                                    <option value="(GMT+06:00) Almaty, Novosibirsk">(GMT+06:00) Almaty, Novosibirsk (N. Central Asia)</option>
                                                                    <option value="(GMT+06:00) Astana, Dhaka">(GMT+06:00) Astana, Dhaka (Central Asia)</option>
                                                                    <option value="(GMT+06:30) Yangon (Rangoon)">(GMT+06:30) Yangon (Rangoon) (Myanmar)</option>
                                                                    <option value="(GMT+07:00) Bangkok, Hanoi, Jakarta">(GMT+07:00) Bangkok, Hanoi, Jakarta (SE Asia)</option>
                                                                    <option value="(GMT+07:00) Krasnoyarsk">(GMT+07:00) Krasnoyarsk (North Asia)</option>
                                                                    <option value="(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi (China)</option>
                                                                    <option value="(GMT+08:00) Irkutsk, Ulaan Bataar">(GMT+08:00) Irkutsk, Ulaan Bataar (North Asia East)</option>
                                                                    <option value="(GMT+08:00) Kuala Lumpur, Singapore">(GMT+08:00) Kuala Lumpur, Singapore (Singapore)</option>
                                                                    <option value="(GMT+08:00) Perth">(GMT+08:00) Perth (W. Australia)</option>
                                                                    <option value="(GMT+08:00) Taipei">(GMT+08:00) Taipei (Taipei)</option>
                                                                    <option value="(GMT+09:00) Osaka, Sapporo, Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo (Tokyo)</option>
                                                                    <option value="(GMT+09:00) Seoul">(GMT+09:00) Seoul (Korea)</option>
                                                                    <option value="(GMT+09:00) Yakutsk">(GMT+09:00) Yakutsk (Yakutsk)</option>
                                                                    <option value="(GMT+09:30) Adelaide">(GMT+09:30) Adelaide (Cen. Australia)</option>
                                                                    <option value="(GMT+09:30) Darwin">(GMT+09:30) Darwin (AUS Central)</option>
                                                                    <option value="(GMT+10:00) Brisbane">(GMT+10:00) Brisbane (E. Australia)</option>
                                                                    <option value="(GMT+10:00) Canberra, Melbourne, Sydney">(GMT+10:00) Canberra, Melbourne, Sydney (AUS Eastern)</option>
                                                                    <option value="(GMT+10:00) Guam, Port Moresby">(GMT+10:00) Guam, Port Moresby (West Pacific)</option>
                                                                    <option value="(GMT+10:00) Hobart">(GMT+10:00) Hobart (Tasmania)</option>
                                                                    <option value="(GMT+10:00) Vladivostok">(GMT+10:00) Vladivostok (Vladivostok)</option>
                                                                    <option value="(GMT+11:00) Magadan, Solomon Is., New Caledonia">(GMT+11:00) Magadan, Solomon Is., New Caledonia (Central Pacific)</option>
                                                                    <option value="(GMT+12:00) Auckland, Wellington">(GMT+12:00) Auckland, Wellington (New Zealand)</option>
                                                                    <option value="(GMT+12:00) Fiji, Kamchatka, Marshall Is.">(GMT+12:00) Fiji, Kamchatka, Marshall Is. (Fiji)</option>
                                                                    <option value="(GMT+13:00) Nuku'alofa">(GMT+13:00) Nuku'alofa (Tonga)</option>
                                                                    <option value="(GMT-01:00) Azores">(GMT-01:00) Azores (Azores)</option>
                                                                    <option value="(GMT-01:00) Cape Verde Is.">(GMT-01:00) Cape Verde Is. (Cape Verde)</option>
                                                                    <option value="(GMT-02:00) Mid-Atlantic">(GMT-02:00) Mid-Atlantic (Mid-Atlantic)</option>
                                                                    <option value="(GMT-03:00) Brasilia">(GMT-03:00) Brasilia (E. South America)</option>
                                                                    <option value="(GMT-03:00) Buenos Aires">(GMT-03:00) Buenos Aires (Argentina)</option>
                                                                    <option value="(GMT-03:00) Georgetown">(GMT-03:00) Georgetown (SA Eastern)</option>
                                                                    <option value="(GMT-03:00) Greenland">(GMT-03:00) Greenland (Greenland)</option>
                                                                    <option value="(GMT-03:00) Montevideo">(GMT-03:00) Montevideo (Montevideo)</option>
                                                                    <option value="(GMT-03:30) Newfoundland">(GMT-03:30) Newfoundland (Newfoundland)</option>
                                                                    <option value="(GMT-04:00) Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada) (Atlantic)</option>
                                                                    <option value="(GMT-04:00) La Paz">(GMT-04:00) La Paz (SA Western)</option>
                                                                    <option value="(GMT-04:00) Manaus">(GMT-04:00) Manaus (Central Brazilian)</option>
                                                                    <option value="(GMT-04:00) Santiago">(GMT-04:00) Santiago (Pacific SA)</option>
                                                                    <option value="(GMT-04:30) Caracas">(GMT-04:30) Caracas (Venezuela)</option>
                                                                    <option value="(GMT-05:00) Bogota, Lima, Quito, Rio Branco">(GMT-05:00) Bogota, Lima, Quito, Rio Branco (SA Pacific)</option>
                                                                    <option value="(GMT-05:00) Eastern Time (US & Canada)">(GMT-05:00) Eastern Time (US & Canada) (Eastern)</option>
                                                                    <option value="(GMT-05:00) Indiana (East)">(GMT-05:00) Indiana (East) (US Eastern)</option>
                                                                    <option value="(GMT-06:00) Central America">(GMT-06:00) Central America (Central America)</option>
                                                                    <option value="(GMT-06:00) Central Time (US & Canada)">(GMT-06:00) Central Time (US & Canada) (Central)</option>
                                                                    <option value="(GMT-06:00) Guadalajara, Mexico City, Monterrey">(GMT-06:00) Guadalajara, Mexico City, Monterrey (Central Mexico)</option>
                                                                    <option value="(GMT-06:00) Saskatchewan">(GMT-06:00) Saskatchewan (Canada Central)</option>
                                                                    <option value="(GMT-07:00) Arizona">(GMT-07:00) Arizona (US Mountain)</option>
                                                                    <option value="(GMT-07:00) Chihuahua, La Paz, Mazatlan">(GMT-07:00) Chihuahua, La Paz, Mazatlan (Mountain Mexico)</option>
                                                                    <option value="(GMT-07:00) Mountain Time (US & Canada)">(GMT-07:00) Mountain Time (US & Canada) (Mountain)</option>
                                                                    <option value="(GMT-08:00) Pacific Time (US & Canada)">(GMT-08:00) Pacific Time (US & Canada) (Pacific)</option>
                                                                    <option value="(GMT-08:00) Tijuana, Baja California">(GMT-08:00) Tijuana, Baja California (Pacific Mexico)</option>
                                                                    <option value="(GMT-09:00) Alaska">(GMT-09:00) Alaska (Alaskan)</option>
                                                                    <option value="(GMT-10:00) Hawaii">(GMT-10:00) Hawaii (Hawaiian)</option>
                                                                    <option value="(GMT-11:00) Midway Island, Samoa">(GMT-11:00) Midway Island, Samoa (Samoa)</option>
                                                                    <option value="(GMT-12:00) International Date Line West">(GMT-12:00) International Date Line West (Dateline)</option>
                                                                </select>

                                                            </div>




                                                        </>)

                                                        :

                                                        (<>



                                                            <div className="cap-focus d-flex">


                                                                {/*<input*/}
                                                                {/*    ref={this.username}*/}
                                                                {/*    type="tel"*/}
                                                                {/*    autoComplete="tel"*/}
                                                                {/*    id="username"*/}
                                                                {/*    name="username"*/}
                                                                {/*    defaultValue={this.state.username}*/}
                                                                {/*    onChange={this.onChange}*/}
                                                                {/*    className="form-control login-input have-cont-code"*/}
                                                                {/*    placeholder="شماره موبایل بدون کد کشور و بدون کاراکتر 0 ابتدای موبایل"*/}
                                                                {/*/>*/}


                                                                <input
                                                                    type="tel"
                                                                    autoComplete="tel"
                                                                    id="mobileNo"
                                                                    name="mobileNo"
                                                                    value={this.state.mobileNo}
                                                                    onChange={this.onChangeHandle}
                                                                    className="form-control login-input have-cont-code"
                                                                    placeholder="شماره موبایل بدون کد کشور و بدون کاراکتر 0 ابتدای موبایل"
                                                                />



                                                                {/*<PhoneInput_All*/}
                                                                {/*    id="countryNumber"*/}
                                                                {/*    type="text"*/}
                                                                {/*    name="countryNumber"*/}
                                                                {/*    international*/}
                                                                {/*    countryCallingCodeEditable={false}*/}
                                                                {/*    countryCodeEditable={false}*/}
                                                                {/*    value=""*/}
                                                                {/*    smartCaret={false}*/}
                                                                {/*    onChange={this.setCountryNumber}*/}
                                                                {/*    onCountryChange={this.setCountryName}*/}
                                                                {/*    placeholder="کد کشور"*/}
                                                                {/*    style={{ color: "#474747", fontWeight: "400", fontSize: "13px" }}*/}
                                                                {/*/>*/}


                                                                <div
                                                                    class="PhoneInput"
                                                                    style={{ color: "rgb(71, 71, 71)", fontWeight: "400", fontSize: "13px" }}
                                                                >
                                                                    <div class="PhoneInputCountry">
                                                                        <select
                                                                            name="countryNumberCountry"
                                                                            aria-label="Phone number country"
                                                                            class="PhoneInputCountrySelect"
                                                                            value={this.state.country}
                                                                            onChange={event => this.setCountry(event.target.value || undefined)}
                                                                        >
                                                                            <option value="00000">
                                                                                کشور خود را انتخاب کنید ...
                                                                            </option>
                                                                            {this.state.Fa_labels.map((countryItem) => (
                                                                                <option key={countryItem.code} value={countryItem.code}>
                                                                                    {countryItem.name_FA}: {getCountryCallingCode(countryItem.code)}+
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <div class="PhoneInputCountryIcon PhoneInputCountryIcon--border">
                                                                            {this.state.country === ""
                                                                                ?
                                                                                (
                                                                                    <svg class="PhoneInputCountryIconImg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 50"><title></title><g class="PhoneInputInternationalIconGlobe" stroke="currentColor" fill="none" stroke-width="2" stroke-miterlimit="10"><path stroke-linecap="round" d="M47.2,36.1C48.1,36,49,36,50,36c7.4,0,14,1.7,18.5,4.3"></path><path d="M68.6,9.6C64.2,12.3,57.5,14,50,14c-7.4,0-14-1.7-18.5-4.3"></path><line x1="26" y1="25" x2="74" y2="25"></line><line x1="50" y1="1" x2="50" y2="49"></line><path stroke-linecap="round" d="M46.3,48.7c1.2,0.2,2.5,0.3,3.7,0.3c13.3,0,24-10.7,24-24S63.3,1,50,1S26,11.7,26,25c0,2,0.3,3.9,0.7,5.8"></path><path stroke-linecap="round" d="M46.8,48.2c1,0.6,2.1,0.8,3.2,0.8c6.6,0,12-10.7,12-24S56.6,1,50,1S38,11.7,38,25c0,1.4,0.1,2.7,0.2,4c0,0.1,0,0.2,0,0.2"></path></g><path class="PhoneInputInternationalIconPhone" stroke="none" fill="currentColor" d="M12.4,17.9c2.9-2.9,5.4-4.8,0.3-11.2S4.1,5.2,1.3,8.1C-2,11.4,1.1,23.5,13.1,35.6s24.3,15.2,27.5,11.9c2.8-2.8,7.8-6.3,1.4-11.5s-8.3-2.6-11.2,0.3c-2,2-7.2-2.2-11.7-6.7S10.4,19.9,12.4,17.9z"></path></svg>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <img
                                                                                        class="PhoneInputCountryIconImg"
                                                                                        alt="CountryIcon"
                                                                                        src={"https://purecatamphetamine.github.io/country-flag-icons/3x2/" + this.state.country + ".svg"}
                                                                                    />
                                                                                )
                                                                            }
                                                                            <div className="PhoneInputCountrySelectArrow"></div>
                                                                            <span className="CountryNumberPlusClass">+</span>
                                                                            <input
                                                                                type="text"
                                                                                autoComplete="tel"
                                                                                id="countryNumber"
                                                                                placeholder="کد کشور"
                                                                                name="countryNumber"
                                                                                className="PhoneInputInput"
                                                                                value={this.state.countryNumber}
                                                                                onChange={this.setCountryNumber}
                                                                                readOnly />
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>




                                                        </>)}





                                                </div>


                                                {this.state.isShowTimezone &&
                                                    <div className="form-group">
                                                        <div className="cap-focus">
                                                            <select className="form-control timezone-selector w-100" id="timeZoneName" name="timeZoneName" value={this.state.timeZoneName} onChange={this.onChangeTimeZoneHandle}>
                                                                <option value="">
                                                                    موقعیت زمانی محل زندگی خود را انتخاب کنید ...
                                                                </option>
                                                                {this.state.Fa_TimeZones.map((itm) => (
                                                                    <option key={itm.id} value={itm.timeZone_Id__Value}>
                                                                        {itm.timeZone_DisplayName_Show_Farsi}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                }


                                            </>




                                            <input
                                                disabled={this.state.isUploading}
                                                type="submit"
                                                className="btn btn-start-consult rounded mt-2"
                                                value="ذخیره"
                                            />
                                        </form>

                                    </>
                            }

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer,
    accountState: state.accountReducer

});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction,
    refreshTokenAction

};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(EditProfile));
