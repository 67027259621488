import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";

class CommentsListBox extends Component {
    constructor(props) {
        super(props);
        //props.comments
    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {
        return (
            <React.Fragment>
                <div>
                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                    {this.props.comments.map(item => (

                        <div className="comment" key={item.id}>
                            <div className="row p-0 m-0">
                                <div className="col-3 col-lg-2 p-0 d-flex align-items-center justify-content-md-center">
                                    <div className="img-wrapper">
                                    </div>
                                </div>
                                <div className="col-9 col-lg-10 p-0">
                                    <h4>
                                        {!isNullOrWhiteSpaceOrEmpty(item.create_ByUserName)
                                            ? <span> {item.create_ByUserName} </span>
                                            : <span> کاربر بدون نام </span>
                                        }
                                    </h4>
                                    <p>
                                        {item.commentText}
                                    </p>
                                    <span className="date-published">
                                        {item.createDateTimeString}
                                    </span>
                                </div>
                            </div>
                        </div>

                    ))}
                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                </div>
            </React.Fragment>
        );
    }
}

export default CommentsListBox;
